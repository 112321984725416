/// <reference types="matrixrequirements-type-declarations" />

// -----------------------------------------------------------
// Functions to show history in dialog or in item details
// -----------------------------------------------------------

import { XCPostCompareHtml } from "../../../RestCalls";
import {
    XRTag,
    XRTrimAudit,
    XRMergeHistory,
    XRTrimNeedleItem,
    XRGetProject_ProjectAudit_TrimAuditList,
    XRGetProject_Tags_TagList,
} from "../../../RestResult";
import { IVersionDetails, IReferenceUpdate, BranchingHelper } from "../../businesslogic/index";
import { MR1 } from "../../businesslogic/index";
import { ml } from "../../matrixlib";
import { ItemControl } from "../Components/ItemForm";
import { NavigationPanel } from "../MainTree/MainTree";
import { refLinkStyle, refLinkTooltip } from "../Parts/RefLinkDefines";
import { UIToolsConstants } from "../../matrixlib/MatrixLibInterfaces";
import {
    IItem,
    app,
    IRestParam,
    globalMatrix,
    matrixSession,
    ControlState,
    restConnection,
    matrixApplicationUI,
    IItemHistory,
} from "../../../globals";

export type {
    IHistory,
    IHistoryMap,
    IPanelOptions,
    IMergeLine,
    IAccordionLine,
    IReportLine,
    IExecuteLine,
    ISignLine,
    IAccordionToggleOptions,
    IShowHistoryDialogOptions,
    IShowHistoryDialogButtonOptions,
};
export { HistoryTools };

interface IHistory {
    history: IVersionDetails[];
    id: string;
    panel?: JQuery;
    panelBorder?: JQuery;
    header?: JQuery;
    wasEdit?: boolean;
}

interface IHistoryMap {
    [key: string]: IHistory;
}

interface IPanelOptions {
    deletedItems: boolean;
    deletedate?: string;
    ctrl: JQuery;
    id: string;
    title: string;
    isFolder: boolean;
    version: number;
    user: string;
    action: string;
    dateServer: string;
    date: string;
    comment: string;
    allowRestore: boolean;
    fullVersion: string;
    auditId: number;
    tags: XRTag[];
}

interface IMergeLine {
    user: string;
    date: string;
    dateServer: string;
    tags: XRTag[];
    comment: string;
    ctrl: JQuery;
    auditId: number;
    action: string;
}
interface IAccordionLine {
    id: string;
    title: string;
    version: number;
    user: string;
    date: string;
    dateServer: string;
}
interface IReportLine extends IAccordionLine {
    job: number;
}
interface IExecuteLine extends IAccordionLine {}
interface ISignLine extends IAccordionLine {
    reason: string;
}
interface IAccordionToggleOptions {
    ctrl: JQuery;
    id: string;
    version: number;
    panelId: number;
    auditId: number;
    action: string;
}
interface IShowHistoryDialogOptions {
    item: IItem;
    preselect?: number[];
    id?: string;
    isFolder?: boolean;
    readOnly?: boolean;
}
interface IShowHistoryDialogButtonOptions {
    item: IItem;
    control: JQuery;
    id?: string;
    isFolder?: boolean;
    readOnly?: boolean;
}

class HistoryTools {
    onNewResult(cb: () => void) {
        this.onNewResultCallback = cb;
    }

    private onNewResultCallback: () => void;

    private panelIdCounter: number;
    private controls: ItemControl[];
    private lastHistory: string;
    private readHistory: IHistoryMap;
    private lastWasTimewarp = false;

    constructor() {
        this.panelIdCounter = 0;
        this.controls = [];
    }

    compareLatest(itemId: string): void {
        let that = this;

        app.getItemAsync(itemId).done(function (item) {
            that.showHistoryDialog({
                item: item,
                preselect: [0, item.history.length],
                readOnly: !app.canEditItem(item),
            });
        });
    }
    compare(fullVersion: string): void {
        let that = this;

        var i0 = ml.Item.parseRef(fullVersion);
        app.getItemAsync(i0.id).done(function (item) {
            that.showHistoryDialog({
                item: item,
                preselect: [i0.version - 1, i0.version],
                readOnly: !app.canEditItem(item),
            });
        });
    }
    compareVersions(itemId: string, oldVersion: number, newVersion: number): void {
        let that = this;

        app.getItemAsync(itemId).done(function (item) {
            that.showHistoryDialog({
                item: item,
                preselect: [oldVersion, newVersion],
                readOnly: !app.canEditItem(item),
            });
        });
    }
    // compare what is in current editor against what is in database now
    diffAgainstLatest(localChanges: IRestParam): void {
        let that = this;
        app.getItemAsync(app.getCurrentItemId()).done(function (item) {
            // make a copy of item and copy in all the changes from the UI
            let copyOfItem = ml.JSON.clone(item);
            $.each(copyOfItem, function (key, _val) {
                if (localChanges[key] != null) copyOfItem[key] = localChanges[key];
                if (localChanges["fx" + key] != null) copyOfItem[key] = localChanges["fx" + key];
            });
            copyOfItem.title = localChanges.title;
            // compare the item against the UI
            that.showDiffDialog(item, copyOfItem);
        });
    }
    showDeletedItems(ctrl: JQuery): void {
        let that = this;

        app.itemForm.prepend(ml.UI.getPageTitle("Deleted Items"));

        if (ml.UI.DateTime.requiresTimeZoneWarning()) {
            $(".toolbarButtons .buttonCTA").remove();
            $(".toolbarButtons").append(ml.UI.DateTime.getTimeZoneCTA());
        }

        var control = $('<div class="panel-body-v-scroll fillHeight"></div>');
        ctrl.append(control);
        var accordion = $('<div class="panel-group history-panel-group" id="accordion"></div>');
        control.append(accordion);
        var progressBar = $(
            '<div style="height:4px;"></div><div class="progress progress-striped active">' +
                '  <div name="progress-bar" class="progress-bar"  role="progressbar" aria-valuenow="1" aria-valuemin="0" aria-valuemax="100" style="width:1%">' +
                '    <span class="sr-only"></span>' +
                "  </div>" +
                "</div>",
        );
        control.append(progressBar);
        progressBar.hide();
        var rendered = 0;
        var showDelayed = window.setTimeout(function () {
            progressBar.show();
        }, 1000); // wait for 1 second to show a progress bar
        app.getDeletedItemsAsync(
            function (item: IVersionDetails) {
                // update UI callback
                rendered++;
                let panel = that.createPanel({
                    deletedItems: true,
                    ctrl: control,
                    id: item.id,
                    title: item.title,
                    isFolder: false,
                    version: item.version,
                    user: item.user,
                    action: "",
                    dateServer: item.date,
                    date: item.dateUserFormat,
                    comment: item.comment,
                    allowRestore: true,
                    fullVersion: item.fullVersion,
                    auditId: item.auditId,
                    tags: item.tags,
                });
                accordion.append(panel);

                // add timewarp info
                if (globalMatrix.ItemConfig.isAfterTimeWarp(item.date)) {
                    that.lastWasTimewarp = true;
                    panel.addClass("timewarp");
                } else if (that.lastWasTimewarp) {
                    that.lastWasTimewarp = false;
                    panel.addClass("justAfterTimewarp");
                }
            },
            function (progress: number) {
                // update progress
                $("div[name=progress-bar]", progressBar)
                    .data("valuenow", progress)
                    .width(progress + "%");
            },
        ).done(function (_total) {
            clearTimeout(showDelayed);
            progressBar.hide();
            if (rendered === 0) {
                control.append("<p style='padding:8px;margin-top:8px;'>There are no deleted items in this project</p>");
            }
        });
    }

    showActivity(ctrl: JQuery, auditIdMin?: number, auditIdMax?: number): void {
        let that = this;
        if (ml.UI.DateTime.requiresTimeZoneWarning()) {
            $(".toolbarButtons .buttonCTA").remove();
            $(".toolbarButtons").append(ml.UI.DateTime.getTimeZoneCTA());
        }
        var control = $('<div class="panel-body-no-scroll">');
        ctrl.append(control);

        var accordion = $('<div class="panel-group history-panel-group" id="accordion">');
        control.append(accordion);
        control.append(ml.UI.getSpinningWait());
        let thisIstheEnd = $(
            "<div id='endOfIt' class='hideCopy' style='text-align:center; margin:10px; display:none;'> the end! </div>",
        );
        control.append(thisIstheEnd);

        let loadMoreDiv = $(
            "<div style='text-align:right;'  ><a id='loadMore'  class='hideCopy'  style='display:block; text-align:right; width:100%;margin:10px; cursor:pointer;text-decoration:underline; '> Load more... </a></div>",
        );
        control.append(loadMoreDiv);

        let loading = false;

        let loadMore = (_t: any) => {
            console.log("Loading more into calendar");
            if (!loading) {
                loadMoreDiv.show();
                if (that.pFrom + that.pCount < that.pTotal) {
                    if (
                        $("#accordion").height() - $(".change-result").scrollTop() <
                        $(".change-result").height() + 30
                    ) {
                        loading = true;
                        thisIstheEnd.hide();
                        $(".spinningWait", control).replaceWith(ml.UI.getSpinningWait());
                        that.showNextActivity(accordion, control, that.pFrom + that.pCount, that.pCount).always(() => {
                            loading = false;
                            if (that.onNewResultCallback != undefined) {
                                that.onNewResultCallback();
                            }
                            loadMoreDiv.show();
                        });
                    }
                } else {
                    thisIstheEnd.show();
                    loadMoreDiv.hide();
                }
            }
        };

        this.showNextActivity(accordion, control, 0, 200, auditIdMin, auditIdMax).then(() => {
            if (that.pTotal == 0) {
                $(".calendar-wrapper .spinningWait").hide();
                $("<div>No changes </div>").insertAfter($(".change-result h1"));
                thisIstheEnd.hide();
                loadMoreDiv.hide();
            }
            if (that.onNewResultCallback != undefined) {
                that.onNewResultCallback();
            }
            if (!that.scrollInstalled) {
                $("#loadMore").click(loadMore);
                $(".change-result").on("scroll", loadMore);

                that.scrollInstalled = true;
            }
            if (!loading) {
                $(".spinningWait", control).hide();
                $("#loadMore").show();
            }
        });
    }

    showReadersDigest(ctrl: JQuery): void {
        app.itemForm.prepend(ml.UI.getPageTitle("Document Changes and Downloads"));
        if (ml.UI.DateTime.requiresTimeZoneWarning()) {
            $(".toolbarButtons .buttonCTA").remove();
            $(".toolbarButtons").append(ml.UI.DateTime.getTimeZoneCTA());
        }
        var control = $('<div class="panel-body-v-scroll  fillHeight" style="margin:0 12px">');
        ctrl.append(control);

        var doclist = $("<div>");
        control.append(doclist);
        control.append(ml.UI.getSpinningWait());
        this.readHistory = {};
        this.showNextReaders(doclist, control, 0, 100);
    }

    renderButtons(options: IShowHistoryDialogButtonOptions) {
        var button = $(
            "<div  class='btn-group'><button id='historyDlgBtn' tabindex='-1' title data-original-title='History' class='btn btn-item'> <span class='fal fa-history'></span></button></div>",
        );

        var data: IShowHistoryDialogOptions = {
            id: options.id,
            item: options.item,
            isFolder: options.isFolder,
            readOnly: options.readOnly,
        };

        button.click(() => this.showHistoryDialog(data));

        options.control.append(button);
    }

    private createPanel(options: IPanelOptions): JQuery {
        let that = this;

        this.panelIdCounter++;
        var icon = $('<i class="hideCopy fal fa-chevron-right"></i>');
        var ir = ml.Item.parseRef(options.id);

        var itemDisp = $("<span ></span>");
        if (options.isFolder) {
            app.getItemAsync(options.id, options.version).done(function (data) {
                itemDisp.refLink({
                    id: options.id,
                    title: data.title,
                    style: refLinkStyle.show,
                    tooltip: refLinkTooltip.none,
                });
            });
            itemDisp.addClass("history-header");

            itemDisp.addClass("key-" + options.id);
        }

        if (options.title) {
            itemDisp = $(
                '<span class="history-header key-' +
                    options.id +
                    '"><b>' +
                    options.id +
                    " " +
                    options.title +
                    "</span>",
            );
        }
        var versionInfo = $("<span ></span>");
        if (options.deletedItems || options.action === "delete") {
            versionInfo = $(
                '<span class="history-header "><span class="historyPanelDeletedInfo historyPanelActionInfo">deleted</span> by <span class="historyPanelUserInfo">' +
                    options.user +
                    "</span></span>",
            );
        } else {
            let action = options.action == "undelete" ? "restore" : options.action;
            versionInfo = $(
                '<span class="history-header">Version <span class="historyPanelVersionInfo">' +
                    options.version +
                    '</span> - <span class="historyPanelActionInfo">' +
                    action +
                    '</span> by <span class="historyPanelUserInfo">' +
                    options.user +
                    "</span></span>",
            );
        }
        var tags = $("<span class='taglist'>");
        var tag_labels: string[] = [];
        if (options.tags) {
            $.each(options.tags, function (_idx, et) {
                tag_labels.push("[" + et.label + "]");
            });
            tags.html(tag_labels.join(", "));
        }
        var rollback = $('<a class="history-header history-restore">' + "restore" + "</a>").click(function (
            event: JQueryEventObject,
        ) {
            event.stopPropagation();
            var version: number;
            if (options.deletedItems) {
                $(event.delegateTarget).parent().parent().parent().parent().parent().hide();
            } else {
                version = options.version;
            }
            app.restoreItemAsync(options.id, options.title, version).done(function (result) {
                if (result) {
                    if (options.deletedItems) {
                        var parent = result.response.newParent;
                        NavigationPanel.openFolder(parent, true);
                    } else {
                        app.dlgForm.dialog("close");
                    }
                    MR1.triggerAfterRestore(options.id);
                }
            });
            return false;
        });
        var compare = $(
            '<a class="hideCopy history-header history-restore activityViewChanges">' + "view changes" + "</a>",
        )
            .click(function (event: JQueryEventObject) {
                event.stopPropagation();

                var ht = new HistoryTools();
                ht.compare($(event.delegateTarget).data("fullVersion"));
                return false;
            })
            .data("fullVersion", options.fullVersion);
        var tag = $('<a class="hideCopy history-header history-restore activityTag">' + "tag version" + "</a>")
            .click(function (event: JQueryEventObject) {
                event.stopPropagation();
                var auditId = $(event.delegateTarget).data("auditId");
                that.createTag(auditId, (label: string) => {
                    tag_labels.push("[" + label + "]");
                    tags.html(tag_labels.join(", "));
                    ml.UI.showSuccess("Tag has been created");
                });
                return false;
            })
            .data("auditId", options.auditId);

        var deletedAtDate = "";
        if (options.deletedate) {
            deletedAtDate =
                '<div style="color: darkgray;"><div class="fal fa-trash-alt" /> ' + options.deletedate + "</div>";
        }
        var date = that.createDateInfo(options.date, deletedAtDate);
        var header = $('<span name="collapseOne' + this.panelIdCounter + '"></span>');

        var comment = that.createCommentInfo(options.comment);

        header.append(icon);
        header.append(itemDisp);
        header.append("<span class='hideScreen'>&nbsp;</span>");
        header.append(versionInfo);

        var tools = $("<div class='hideCopy pull-right'>");
        date.append(tools);

        if (options.action === "edit" || options.action === "reviewed") {
            if (!options.id || !ml.Item.parseRef(options.id).isFolder) {
                tools.append(compare);
            }
        }

        if (options.allowRestore && matrixSession.isEditor()) {
            tools.append(rollback);
        }

        if (options.auditId) {
            tools.append(tag);
            tools.append(tags);
        }

        header.append(date);
        var panel = $(
            '<div class="panel panel-default">' +
                '    <div class="panel-heading history-panel">' +
                "    </div>" +
                '    <div name="collapseOne' +
                this.panelIdCounter +
                '" class="hideCopy panel-collapse collapse">' +
                '      <div class="panel-body"></div>' +
                "    </div>" +
                "  </div>",
        );

        if (ir.isFolder && options.action !== "touch") {
            icon.css("color", "transparent");
        } else {
            panel.css("cursor", "pointer");
        }

        let toggleOptions: IAccordionToggleOptions = {
            ctrl: options.ctrl,
            id: options.id,
            version: options.version,
            panelId: this.panelIdCounter,
            auditId: options.auditId,
            action: options.action,
        };

        $(".panel-heading", panel)
            .append(header)
            .append(comment)
            .click(() => this.toggle(toggleOptions));
        if (tags.html()) {
            $(".panel-heading", panel).append($("<span class='hideScreen'><i>Tags: </i></span>").append(tags.html()));
        }
        panel.append("<div class='hideScreen'>&nbsp;</div>");
        return panel;
    }

    private createDateInfo(date: string, deletedAtDate: string) {
        return $(
            '<span class="pull-right" style="text-align:right;"><div><span class="hideScreen"><i>Change Date: </i></span>' +
                date +
                "</div>" +
                (deletedAtDate ? deletedAtDate : "") +
                "</span>",
        );
    }
    private createCommentInfo(comment: string) {
        return $("<div class='historyComment'>")
            .html("<span class='hideScreen'><i>Comment: </i></span>" + comment)
            .highlightReferences();
    }

    private createTag(auditId: string, update: (label: string) => void) {
        const okButtonSelector =
            "div.ui-dialog[aria-describedby=newTagDialog] > .ui-dialog-buttonpane > .ui-dialog-buttonset > .btnDoIt";
        async function tagStatusChange() {
            var okButton = $(okButtonSelector);
            if (
                (await tagLabel.getController().getValueAsync()) !== "" &&
                (await tagLabel.getController().getValueAsync()) !== ""
            ) {
                okButton.prop("disabled", false);
                okButton.removeClass("ui-state-disabled");
            } else {
                okButton.prop("disabled", true);
                okButton.addClass("ui-state-disabled");
            }
        }

        let newTagDlg = $("<div id='newTagDialog'>").appendTo("body");
        let newTagContent = $("<div>");

        var tagLabel = $("<div id='tagLabel'>").plainText({
            controlState: ControlState.FormEdit,
            canEdit: true,
            help: "Tag Name",
            fieldValue: "",
            valueChanged: function () {
                tagStatusChange();
            },
            parameter: {
                rows: 1,
            },
        });
        newTagContent.append(tagLabel);
        var tagComment = $("<div id='tagComment'>").plainText({
            controlState: ControlState.FormEdit,
            canEdit: true,
            help: "Tag Comment",
            fieldValue: matrixSession.getComment(),
            valueChanged: function () {
                tagStatusChange();
            },
            parameter: {
                allowResize: false,
                rows: 9,
            },
        });
        newTagContent.append(tagComment);

        ml.UI.showDialog(
            newTagDlg,
            "Create Tag",
            newTagContent,
            -730,
            -400,
            [
                {
                    text: "Ok",
                    class: "btnDoIt",
                    click: async function () {
                        restConnection
                            .postProject("tag", {
                                label: await tagLabel.getController().getValueAsync(),
                                auditId: auditId,
                                comments: await tagComment.getController().getValueAsync(),
                                type: "tag",
                            })
                            .done(async function () {
                                update(await tagLabel.getController().getValueAsync());
                            })
                            .fail(function (jqxhr, textStatus, error) {
                                if (
                                    jqxhr &&
                                    jqxhr.responseText &&
                                    jqxhr.responseText.indexOf("This tag already exists for this project") !== -1
                                ) {
                                    ml.UI.showError("Could not create tag", "A tag with this label already exist");
                                } else {
                                    ml.UI.showError(
                                        "Could not create tag",
                                        "Status:" + textStatus + ". Error was:" + error,
                                    );
                                }
                            });
                        newTagDlg.dialog("close");
                    },
                },
                {
                    text: "Cancel",
                    class: "btnCancelIt",
                    click: function () {
                        newTagDlg.dialog("close");
                    },
                },
            ],
            UIToolsConstants.Scroll.None,
            false,
            false,
            () => {
                //close
                newTagDlg.remove();
            },
            () => {
                // open
                tagStatusChange();
            },
        );
    }
    private createReportLine(options: IReportLine): JQuery {
        let that = this;

        //  id: item.id,
        //        title: item.title,
        //        version: item.version,
        //         user: item.user,
        //        date: item.date
        //        job:number or empty in case the report has been removed

        var icon = $('<span class="fal fa-list-alt downloadFile"></span>');
        var downloadText = $("");
        // if job is 1 week or less show download button / otherwise the file will be gone
        if (
            options.job &&
            options.dateServer &&
            new Date().getTime() - new Date(options.dateServer).getTime() < 7 * 24 * 60 * 60 * 1000
        ) {
            icon.css("cursor", "pointer")
                .click(function () {})
                .tooltip({
                    title: "document creation",
                    placement: "bottom",
                });
            downloadText = $(
                '<span class="hideCopy history-header history-restore pull-right downloadFile">&nbsp;<a href="javascript:void(0)">download</a></span>',
            ).click(function () {
                that.downloadReport(options.job);
            });
        } else {
            icon.css("cursor", "pointer").tooltip({
                title: "document creation",
                placement: "bottom",
            });
        }

        var itemDisp = $(
            '<span class="history-header key-' +
                options.id +
                '"><b>' +
                options.id +
                "</b> " +
                options.title +
                "</span>",
        );
        var versionInfo = $(
            '<span class="history-header"><span class="hideScreen">&nbsp;</span>(Version ' +
                options.version +
                ') - was <span class="historyPanelActionInfo">produced</span> by ' +
                options.user +
                "</span>",
        );
        var date = that.createDateInfo(options.date, null);
        var extraline = $("<div class='historyComment'>").html("&nbsp;");

        var tools = $("<div class='hideCopy' >");
        date.append(tools);
        tools.append(downloadText);

        var panel = $(
            '<div class="panel panel-default">' +
                '    <div class="panel-heading-report panel-heading history-panel">' +
                "    </div>" +
                "  </div>",
        );
        $(".panel-heading", panel).append(icon).append(itemDisp).append(versionInfo).append(date).append(extraline);
        return panel;
    }

    private createExecuteLine(options: IExecuteLine): JQuery {
        //  id: item.id,
        //        title: item.title,
        //        version: item.version,
        //         user: item.user,
        //        date: item.date
        //        job:number or empty in case the report has been removed

        var icon = $('<span class="fal fa-exchange"></span>');

        var itemDisp = $(
            '<span class="history-header key-' +
                options.id +
                '"><b>' +
                options.id +
                "</b> " +
                options.title +
                "</span>",
        );
        var versionInfo = $(
            '<span class="history-header"><span class="historyPanelUserInfo">' +
                options.user +
                '</span> <span class="historyPanelActionInfo">created test forms</span></span>',
        );
        var date = this.createDateInfo(options.date, null);
        var extraline = $("<div class='historyComment'>").html("&nbsp;");

        var panel = $(
            '<div class="panel panel-default">' +
                '    <div class="panel-heading-report panel-heading history-panel">' +
                "    </div>" +
                "  </div>",
        );
        $(".panel-heading", panel).append(icon).append(itemDisp).append(versionInfo).append(date).append(extraline);
        return panel;
    }

    private createReferenceLine(options: IReferenceUpdate): JQuery {
        var icon = options.added ? $('<span class="fal fa-link">') : $('<span class="fal fa-unlink">');
        var action = options.added ? "Added" : "Deleted";
        var itemDisp = $(
            '<span class="history-header key-' +
                options.fromId +
                '"><b>' +
                options.fromId +
                "</b> " +
                action +
                " link to <b>" +
                options.toId +
                "</b></span>",
        );
        var versionInfo = $(
            '<span class="history-header"><span class="hideScreen">&nbsp;</span>- by ' +
                options.user +
                "</span></span>",
        );
        var date = this.createDateInfo(options.dateUserFormat, null);
        var extraline = this.createCommentInfo(options.comment);

        var panel = $(
            '<div class="panel panel-default">' +
                '    <div class="panel-heading-report panel-heading history-panel">' +
                "    </div>" +
                "  </div>",
        );
        $(".panel-heading", panel).append(icon).append(itemDisp).append(versionInfo).append(date).append(extraline);
        panel.append("<div class='hideScreen'>&nbsp;</div>");
        return panel;
    }

    private createSignLine(options: ISignLine): JQuery {
        //  id: item.id,
        //        title: item.title,
        //        version: item.version,
        //         user: item.user,
        //        date: item.date
        //        job:number or empty in case the report has been removed

        var icon = $('<span class="fal fa-pencil"></span>');

        var itemDisp = $(
            '<span class="history-header key-' +
                options.id +
                '"><b>' +
                options.id +
                "</b> " +
                options.title +
                "</span>",
        );
        var versionInfo = $(
            '<span class="history-header"><span class="hideScreen">&nbsp;</span>- was <span class="historyPanelActionInfo">' +
                (typeof options.reason != "undefined" ? options.reason : "signed") +
                "</span></span>",
        );

        var date = this.createDateInfo(options.date, null);
        var extraline = $("<div class='historyComment'>").html("&nbsp;");

        var panel = $(
            '<div class="panel panel-default">' +
                '    <div class="panel-heading-report panel-heading history-panel">' +
                "    </div>" +
                "  </div>",
        );
        $(".panel-heading", panel).append(icon).append(itemDisp).append(versionInfo).append(date).append(extraline);
        return panel;
    }

    private createMergeLine(options: IMergeLine): JQuery {
        //        user: item.user,
        //        date: item.date
        //        job:number or empty in case the report has been removed

        this.panelIdCounter++;
        let icon = $('<span style="cursor:pointer" class="fal fa-code-merge"></span>');

        let itemDisp = $('<span class="history-header"><b>' + "MERGE" + "</b></span>");
        let versionInfo = $(
            '<span class="history-header"><span class="hideScreen">&nbsp;</span>- by ' +
                options.user +
                "</span></span>",
        );

        let date = this.createDateInfo(options.date, null);
        let header = $('<span name="collapseOne' + this.panelIdCounter + '"></span>');
        let comment = this.createCommentInfo(options.comment);

        header.append(icon);
        header.append(itemDisp);
        header.append(versionInfo);

        let tools = $("<div class='hideCopy pull-right'>");
        let tags = $("<span class='taglist'>");
        let tag_labels: string[] = [];
        if (options.tags) {
            $.each(options.tags, function (_idx, et) {
                tag_labels.push("[" + et.label + "]");
            });
            tags.html(tag_labels.join(", "));
        }
        date.append(tools.append(tags));
        header.append(date);

        var panel = $(
            '<div class="panel panel-default">' +
                '    <div class="panel-heading history-panel">' +
                "    </div>" +
                '    <div name="collapseOne' +
                this.panelIdCounter +
                '" class="hideCopy panel-collapse collapse">' +
                '      <div class="panel-body"></div>' +
                "    </div>" +
                "  </div>",
        );

        panel.css("cursor", "pointer");

        let toggleOptions: IAccordionToggleOptions = {
            ctrl: options.ctrl,
            id: null,
            version: null,
            panelId: this.panelIdCounter,
            auditId: options.auditId,
            action: options.action,
        };
        $(".panel-heading", panel)
            .append(header)
            .append(comment)
            .click(() => this.toggle(toggleOptions));
        return panel;
    }
    private downloadReport(jobNumber: number): void {
        app.getReportDetails(jobNumber).done(function (progress) {
            app.download(jobNumber, progress.jobFile[progress.jobFile.length - 1].jobFileId);
        });
    }

    private async toggle(options: IAccordionToggleOptions) {
        let that = this;

        if (options.action !== "merge") {
            let ir = ml.Item.parseRef(options.id);
            if (ir.isFolder && options.action !== "touch") {
                // there is no history of folders...
                return;
            }
        }
        var tag = "collapseOne" + options.panelId;
        var panelHeader = $("span[name='" + tag + "']", options.ctrl);
        $(".fa-chevron-right", panelHeader).removeClass("fa-chevron-right").addClass("fa-chevron-up");
        $(".fa-chevron-down", panelHeader).removeClass("fa-chevron-down").addClass("fa-chevron-right");
        $(".fa-chevron-up", panelHeader).removeClass("fa-chevron-up").addClass("fa-chevron-down");
        var panel = $("div[name='" + tag + "']", options.ctrl);
        var panelBody = $(".panel-body", panel);
        if (panelBody.html() === "") {
            switch (options.action) {
                case "touch":
                    app.getAuditDetailsAsync(options.auditId).done(function (audit: XRTrimAudit) {
                        panelBody.append("<p>The following items have been touched:</p>");
                        let actionDetails = $("<ul>");
                        $.each(audit.techAudit, function (_idx, ta) {
                            if (ta.operation === "add" && ta.table === "item_instance" && ta.ref) {
                                actionDetails.prepend($("<li>").append(ml.Item.renderLink(ta.ref)));
                            }
                        });
                        panelBody.append(actionDetails);
                    });
                    break;
                case "merge":
                    app.getAuditDetailsAsync(options.auditId).done(function (audit: XRTrimAudit) {
                        let mergeMain = audit.techAudit.filter((ta) => ta.table == "merge_main");
                        if (mergeMain.length) {
                            let mergeId = mergeMain[0].index;
                            restConnection.getProject("mergehistory").done((history) => {
                                let details = (history as XRMergeHistory).entries.filter((his) => his.id == mergeId);
                                if (details.length) {
                                    let isMerge =
                                        matrixSession
                                            .getBranches(matrixSession.getProject(), "")
                                            .map((info) => info.branch)
                                            .lastIndexOf(details[0].branchProject) != -1;
                                    if (isMerge) {
                                        panelBody.append(`<p>Merge back from branch: ${details[0].branchProject}</p>`);
                                    } else {
                                        panelBody.append(`<p>Updated from mainline: ${details[0].branchProject}</p>`);
                                    }
                                    let ul = $("<ul>").appendTo(panelBody);
                                    for (let detail of details[0].details) {
                                        BranchingHelper.addMergeDetail(ul, detail, null, isMerge);
                                    }
                                } else {
                                    // ignore operation: "add" , table "item_link"
                                    panelBody.append(
                                        `<p>Merge back from a deleted or not accessible branch. The following has been modified:</p>`,
                                    );
                                    let ul = $("<ul>").appendTo(panelBody);
                                    let links = false;
                                    let items: string[] = [];
                                    for (let change of audit.techAudit) {
                                        if (change.table == "item_link") {
                                            links = true;
                                        } else if (!change.ref || items.indexOf(change.ref) != -1) {
                                            // already registered
                                        } else {
                                            if (change.table == "item_instance") {
                                                if (change.operation == "add") {
                                                    items.push(change.ref);
                                                } else if (change.operation == "invalidate") {
                                                    items.push(change.ref);
                                                }
                                                if (change.operation == "moved") {
                                                    items.push(change.ref);
                                                }
                                            }
                                        }
                                    }
                                    for (let item of items) {
                                        ul.append($(`<li>Item created/update/moved/deleted: ${item}</li>`));
                                    }
                                    if (links) {
                                        ul.append($(`<li>Links have been modified</li>`));
                                    }
                                }
                                panelBody.highlightReferences();
                            });
                        }
                    });
                    break;
                default:
                    app.getItemAsync(options.id, options.version).done(async function (data) {
                        let render = new ItemControl({
                            control: panelBody,
                            controlState: ControlState.HistoryView,
                            isHistory: options.version,
                            item: data,
                            isItem: !data.children,
                        });
                        await render.load();
                        that.controls.push(render);
                    });

                    break;
            }
        }

        panel.collapse("toggle");
        app.dlgForm.resizeDlgContent(this.controls);
    }

    updateVersionPanes(): void {
        let that = this;

        // get all visible version panes
        var visiblePanes = $(".versionpane:visible .panel-body-v-scroll");

        // nothing is displayed
        if (visiblePanes.length < 1) {
            return;
        }

        // only one version is displayed: show without change markup
        if (visiblePanes.length == 1) {
            const shadows = JSON.parse($(visiblePanes[0]).parent().data("shadowRoots"));
            $(visiblePanes[0]).html($(visiblePanes[0]).parent().data("originalHTML"));
            visiblePanes[0].querySelectorAll(".shadow-root").forEach((element) => {
                const shadowRoot = element.attachShadow({ mode: "open" });
                shadowRoot.innerHTML = shadows[element.id];
            });
            matrixApplicationUI.lastMainItemForm.resizeIt(true);
            that.updateRowHeights($(".slickTable", $(visiblePanes[0])));
            return;
        }

        // build data to send to compare function
        let versions: string[] = [];
        $.each(visiblePanes, function (_idx, visiblePane) {
            let rendered = $("<div>").html($(visiblePane).parent().data("originalHTML"));
            $("textarea,pre", rendered).each(function (idx, select) {
                $(select).replaceWith(
                    "<div>" +
                        $(select)
                            .html()
                            .replace(/(?:\r\n|\r|\n)/g, "<br>") +
                        "</div>",
                );
            });
            $(".fal.fa-square", rendered).each((idx, elem) => {
                $(elem).parent().addClass("history-unchecked");
            });
            $(".fal.fa-square-square", rendered).each((idx, elem) => {
                $(elem).parent().addClass("history-checked");
            });
            const shadows = JSON.parse($(visiblePane).parent().data("shadowRoots"));
            rendered[0].querySelectorAll(".shadow-root").forEach((element) => {
                element.innerHTML = HistoryTools.compare_css + shadows[element.id];
            });
            versions.push(rendered.html());
        });

        let split = this.splitVersions(versions);
        let compareParams: XCPostCompareHtml = {
            arg: JSON.stringify(split ? { versionsMultiple: split } : { versions: versions }),
        };
        // call compare
        app.compareHTML(compareParams).done(function (compareResults) {
            $.each(visiblePanes, function (idx, visibleVersionPane) {
                if (split) {
                    let compare = `<div class='dialog-body'>`;
                    for (let assemble = 0; assemble < compareResults.htmlMultiple.length; assemble++) {
                        compare += compareResults.htmlMultiple[assemble][idx].replace(/pull-left/g, "");
                    }
                    compare += `</div>`;
                    $(visibleVersionPane).parent().data("compareHTML", compare);
                } else {
                    $(visibleVersionPane).parent().data("compareHTML", compareResults.html[idx]);
                }
            });
            that.renderVersionPanes();
            // make sure hyperlinks open in new view
            $(".itemTitle>a", visiblePanes).attr("target", "_blank");
        });
    }

    public splitVersions(versions: string[]): string[][] {
        let split: string[][] = [];

        let parts = 0;
        // split each of html blobs
        for (let version of versions) {
            let oneSplit: string[] = [];
            split.push(oneSplit);
            let all = $(version);
            for (let child of all.children().toArray()) {
                oneSplit.push(child.outerHTML);
            }
            if (parts && oneSplit.length != parts) {
                // items not well aligned -> don't compare section by section
                return null;
            }
            parts = oneSplit.length;
        }
        // transpose the array
        let splitTranspose: string[][] = [];

        for (let part = 0; part < parts; part++) {
            splitTranspose.push([]);
            for (let doc = 0; doc < versions.length; doc++) {
                splitTranspose[part].push(split[doc][part]);
            }
        }

        return splitTranspose;
    }
    private updateRowHeights(table: JQuery) {
        // calculate real rendered heights of cells and adjust heights accordingly
        let rows = $(".slick-row", table);
        if (rows.length < 1) return; // nothing to update

        var cellSizer = $("<div style='padding:0px 4px 0px 4px'>");
        $("body").append(cellSizer.css("left", "-20000px"));
        let top = 0;
        $.each(rows, function (_ridx, row) {
            let columns = $(".slick-cell", $(row));
            let height = Math.max(20, $(row).height());
            $.each(columns, function (_cidx, col) {
                //if ( $(".multiLineFormatter"),$(col) ||  $(".itemRefFormatter"),$(col) ) {
                cellSizer.width(Math.max(10, $(col).width() - 10));
                cellSizer.html($(col).html());
                height = Math.max(height, cellSizer.height());
                $(col).css("padding-top", "0").css("padding-bottom", "0");
                //}
                $(col).css("height", "100%");
            });
            $(row).height(height);
            $(row).css("top", top + "px");
            top += height;
        });
        cellSizer.remove();
        table.height(top + 27); // where does that number come from?
        $(".slick-viewport", table).css("height", "100%");
    }

    private renderVersionPanes(): void {
        let that = this;

        let visiblePanes = $(".versionpane:visible  .panel-body-v-scroll");
        let titles = $(".versionpane:visible .itemTitleBarNoToolsNoEdit");

        // several versions are displayed show each with change markup
        $.each(visiblePanes, function (_idx, visiblePane) {
            $(visiblePane).html($(visiblePane).parent().data("compareHTML"));
            visiblePane.querySelectorAll(".shadow-root").forEach((element) => {
                const content = element.innerHTML;
                element.innerHTML = "";
                const shadow = element.attachShadow({ mode: "open" });
                shadow.innerHTML = content;
            });
            $.each($(".slickTable", $(visiblePane)), function (_tableIdx, table) {
                that.updateRowHeights($(table));
            });
        });

        let visibleBodies = $(".versionpane:visible  .dialog-body");
        var count = $(visibleBodies[0]).children().length;
        // just a safety check if number of fields changes the code after would be fairly random
        for (var idx = 1; idx < visibleBodies.length; idx++) {
            if (count !== $(visibleBodies[idx]).children().length) {
                return;
            }
        }
        // assume.... all 1+ panes have same structure: adjust heights
        for (var c = 0; c < count; c++) {
            var mh = 0;
            for (var idx = 0; idx < visibleBodies.length; idx++) {
                var p = $($(visibleBodies[idx]).children()[c]);
                p.css("height", "auto");
                mh = Math.max(mh, p.height());
            }
            // run from right to left
            for (var idx = visibleBodies.length - 1; idx >= 0; idx--) {
                var p = $($(visibleBodies[idx]).children()[c]);
                //Add 1px of room
                p.height(mh + 1);
            }
        }
        // same for version info
        visibleBodies = $(".versionpane:visible .versionInfo");
        var mh = 0;
        for (var idx = 0; idx < visibleBodies.length; idx++) {
            var p = $(visibleBodies[idx]);
            p.css("height", "auto");
            mh = Math.max(mh, p.height());
        }
        for (var idx = 0; idx < visibleBodies.length; idx++) {
            var p = $(visibleBodies[idx]);
            p.height(mh);
        }
    }

    async renderItemMeat(hi: JQuery, item: IItem) {
        let itemForm = new ItemControl({
            control: hi,
            controlState: ControlState.HistoryView,
            isHistory: 1,
            item: item,
            isItem: true,
            parameter: {
                manualTableHeights: true,
                reviewMode: true, // don't show outdated icons
            },
        });
        await itemForm.load();

        let itemDetails = $(".panel-body-v-scroll", hi);
        itemDetails.css("display", "flex");
        itemDetails.css("flex-direction", "column");

        ml.SmartText.prepareForReadReadRender(itemDetails);
    }

    private async showSaveConflict(dlg: JQuery, latest: IItem, localChanges: IItem) {
        let that = this;

        async function showVersion(hi: JQuery, item: IItem, version: string, userLogin: string) {
            $("#tellToSelect").hide();

            const userLoginDisplay = that.formatUserLogin(userLogin);
            var vi = $("<div class='versionInfo'>");
            var header = $("<div class='baseControlHelp'>Version Info</div>");
            hi.append(header);
            hi.append(vi);
            vi.append("<div class=''>Version: <span class='historyDlgVersionDetail'>" + version + "</span></div>");
            vi.append(
                "<div class=''>User: <span class='historyDlgVersionDetail'>" + userLoginDisplay + "</span></div>",
            );

            hi.append("<hr>");

            await that.renderItemMeat(hi, item);

            hi.data("status", "show");
            hi.show();
        }

        let changeView = $("<div>").appendTo(dlg);

        $("<div class='warningMerge'>")
            .html(
                `Someone changed and saved the same item (${app.getCurrentItemId()}) while you were editing it. In order not to overwrite these changes:<br>
        <ul style="text-align: left;font-size: smaller;margin-left: 100px;">
        <li>open the changed item in another <a href='${
            ml.Item.parseRef(app.getCurrentItemId()).url
        }' target='_blank'>tab</a></li>
        <li>copy the changes you did in this tab</li>
        <li>close this tab (to be able to edit in the other tab)</li>
        <li>manually update the item with your changes</li>`,
            )
            .appendTo(changeView);

        if (latest.history.length && latest.history.length > 0) {
            let flex = $("<div style='display:flex;justify-content: center;'>").appendTo(changeView);
            var changed = $("<div class='versionpane'>").appendTo(flex);
            var current = $("<div class='versionpane'>").appendTo(flex);

            // latest.history is organized newest to oldest.
            await showVersion(changed, latest, "New Version on Server", latest.history[0].user);
            await showVersion(current, localChanges, "Your Changes", matrixSession.getUser());

            this.updateVersionPanes();
        } else {
            // it's a folder
        }
    }

    private compareVersionDialog(
        item: IItem,
        history: IItemHistory[],
        selectBar: boolean,
        show: number[],
        allowRestore: boolean,
    ) {
        let that = this;

        var trmy = $("<tr id='historyDlgYear' style='background-color: #eee;'>");
        var last_my = "";
        var span_my = 0;
        var trday = $("<tr id='historyDlgMonth' style='background-color: #eee;'>");
        var last_day = "";
        var span_day = 0;
        var trtime = $("<tr id='historyDlgTime'>");
        var trdetails = $("<tr>");
        var tddetails = $("<td>").attr("colspan", history.length);
        trdetails.append(tddetails);
        var flex = $("<div style='display:flex;justify-content: center;'>");
        tddetails.append(flex);
        var format_my = new SimpleDateFormat("yyyy MMMM");
        var format_day = new SimpleDateFormat("d");
        var format_time = new SimpleDateFormat("HH:mm");
        let itemIsDeleted = item.isDeleted;

        let timewarpVersion = -1;
        if (globalMatrix.ItemConfig.getTimeWarp()) {
            let treeInfo = app.getItemFromTree(item.id);
            if (treeInfo.version) {
                let vp = treeInfo.version.split("/");
                timewarpVersion = Number(vp[0]);
                itemIsDeleted = treeInfo.version.indexOf("*") != -1;
            }
        }
        for (var idx = history.length - 1; idx >= 0; idx--) {
            var date = new Date(history[idx].date);
            var my = format_my.format(date);
            var day = format_day.format(date);
            var time = format_time.format(date);
            let newMonth = false;
            if (my !== last_my) {
                newMonth = true;
                span_my = 1;
                trmy.append($("<td>").html(my).css("font-weight", "bold").css("text-align", "center"));
                last_my = my;
            } else {
                span_my++;
                $("td", trmy).last().attr("colspan", span_my);
            }
            if (day !== last_day || newMonth) {
                span_day = 1;
                trday.append($("<td>").html(day).css("font-weight", "bold").css("text-align", "center"));
                last_day = day;
            } else {
                span_day++;
                $("td", trday).last().attr("colspan", span_day);
            }
            let timewarp = history[idx].version == timewarpVersion;
            let timewarpClass = "";
            if (timewarpVersion != -1) {
                if (history[idx].version > timewarpVersion) timewarpClass = " timepwarp_after";
                if (history[idx].version == timewarpVersion) timewarpClass = " timepwarp_day";
            }
            // always show very first and last one and always the last of a day
            let other_day =
                idx == history.length - 1 ||
                idx == 0 ||
                new Date(history[idx].date).getDate() != new Date(history[idx - 1].date).getDate() ||
                timewarp;

            var time_toggle = $(
                "<td class='historyDlgVersionPaneSelector " +
                    (other_day ? "last_day" : "any_day") +
                    timewarpClass +
                    "'>",
            ).html("<div>" + time + "</div>" + "<div>rev&nbsp;" + history[idx].version + "</div>");
            time_toggle.data("rev", history[idx].version);
            time_toggle.click(function (event: JQueryEventObject) {
                let revision = $(event.delegateTarget).data("rev");
                // update top bar
                var hi = $("#ver" + revision);
                $(event.delegateTarget).toggleClass("historyDlgVersionPaneSelected");
                // update side bar
                let selected = $(event.delegateTarget).hasClass("historyDlgVersionPaneSelected");
                $.each($(".historyDetailLine"), function (_hrlIdx, hrl) {
                    if ($(hrl).data("rev") == revision) {
                        selected
                            ? $(hrl).parent().addClass("historyVersionContainerSelected")
                            : $(hrl).parent().removeClass("historyVersionContainerSelected");
                    }
                });
                // update panes
                if (hi.data("status") === "show") {
                    hi.hide();
                    hi.data("status", "hide");
                    that.updateVersionPanes();
                } else if (hi.data("status") === "hide") {
                    hi.show();
                    hi.data("status", "show");
                    that.updateVersionPanes();
                } else {
                    showVersion(hi, revision);
                }

                if ($(".versionpane:visible").length === 0) {
                    $("#tellToSelect").show();
                } else {
                    $("#tellToSelect").hide();
                }
            });
            trtime.append(time_toggle);
            var vp = $("<div class='versionpane' id='ver" + history[idx].version + "'>");
            vp.hide();
            flex.append(vp);
            if (show.indexOf(history[idx].version) !== -1) {
                showVersion(vp, history[idx].version);
                time_toggle.addClass("historyDlgVersionPaneSelected");
            }
        }

        if (itemIsDeleted) {
            trmy.append(
                $("<td rowspan='4' style='vertical-align: middle;border: 1px solid #ddd;'>").html(
                    "<span style='color:red'>item deleted</span>",
                ),
            );
        }
        if (!show || show.length === 0) {
            flex.append("<div id='tellToSelect'>select revisions to be shown / compared</div>");
        }

        function showVersion(hi: JQuery, r: number) {
            $("#tellToSelect").hide();
            var h = history[history.length - r];
            var vi = $("<div class='versionInfo'>");
            var header = $("<div class='baseControlHelp'>Version Info</div>");
            hi.append(header);
            hi.append(vi);
            vi.append("<div class=''>Version: <span class='historyDlgVersionDetail'>" + h.version + "</span></div>");
            if (h.action == "signature") {
                vi.append(
                    "<div class=''>Action: <span class='historyDlgVersionDetail'>" + h.comment + "</span>" + "</div>",
                );
            } else {
                let restoreInfo = "";
                if (h.deletedate) {
                    restoreInfo =
                        h.deletedate == h.dateUserFormat
                            ? " (rolled back to previous version)"
                            : ` (was deleted on ${h.deletedate}) `;
                }
                vi.append(
                    "<div class=''>Action: <span class='historyDlgVersionDetail'>" +
                        h.action +
                        "</span>" +
                        restoreInfo +
                        "</div>",
                );
                var comment = $(
                    "<div class='' style='white-space: pre-wrap'>Comment: <span class='historyDlgVersionDetail'>" +
                        h.comment +
                        "</span></div>",
                );
                comment.highlightReferences();
                vi.append(comment);
                const userLoginDisplay = that.formatUserLogin(h.user);
                vi.append(
                    "<div class=''>User: <span class='historyDlgVersionDetail'>" + userLoginDisplay + "</span></div>",
                );
            }
            vi.append(
                "<div class='' style='margin-bottom:4px'>Date: <span class='historyDlgVersionDetail'>" +
                    h.dateUserFormat +
                    "</span></div>",
            );
            hi.append("<hr>");
            app.getItemAsync(
                h.id,
                r === history.length && !itemIsDeleted && !globalMatrix.ItemConfig.getTimeWarp() ? undefined : r,
            ).done(async function (data: IItem) {
                let itemForm = new ItemControl({
                    control: hi,
                    controlState: ControlState.HistoryView,
                    isHistory: r,
                    item: data,
                    isItem: !data.children,
                    parameter: {
                        manualTableHeights: true,
                        reviewMode: true, // don't show outdated icons
                    },
                });

                await itemForm.load();
                let itemDetails = $(".panel-body-v-scroll", hi);

                ml.SmartText.prepareForReadReadRender(itemDetails);

                that.updateVersionPanes();
            });

            var rollback = $('<a class="history-header history-restore">' + "restore" + "</a>")
                .click(function (event: JQueryEventObject) {
                    if (event.preventDefault) event.preventDefault();
                    if (event.stopPropagation) event.stopPropagation();

                    var r = $(event.delegateTarget).data("ver");
                    var h = history[history.length - r];
                    app.restoreItemAsync(h.id, h.title, r).done(function (result) {
                        if (result) {
                            MR1.triggerAfterRestore(h.id);
                            app.dlgForm.dialog("close");
                        }
                    });
                    return false;
                })
                .data("ver", r);
            if (r !== history.length && matrixSession.isEditor() && allowRestore) {
                header.append(rollback);
            }

            hi.data("status", "show");
            hi.show();
        }

        var selectTable = $("<table class='table table-bordered' style='width:100%;border:none'>");
        var tbody = $("<tbody>");
        selectTable.append(tbody);

        tbody.append(trmy);
        tbody.append(trday);
        tbody.append(trtime);
        tbody.append(trdetails);

        if (selectBar) {
            return selectTable;
        } else {
            return flex;
        }
    }

    // check if item is either not specified or as expected
    private checkItemId(xr: XRTrimNeedleItem, itemId: string) {
        if (!xr) return true;
        if (!xr.itemOrFolderRef) return true;
        return itemId == ml.Item.parseRef(xr.itemOrFolderRef).id;
    }

    private formatUserLogin(userLogin: string): string {
        return globalMatrix.ItemConfig.hasUserInfo(userLogin) ? userLogin : `<s>${userLogin}</s>`;
    }

    private formatUserName(userLogin: string, userName: string): string {
        return globalMatrix.ItemConfig.hasUserInfo(userLogin) ? userName : `<s>${userName}</s>`;
    }

    private showHistoryDetails(
        itemId: string,
        simpleHistory: IItemHistory[],
        historyUnfiltered: XRGetProject_ProjectAudit_TrimAuditList,
        tags: XRGetProject_Tags_TagList,
    ) {
        let that = this;

        let inner = $("<div class='detailsHistoryContainer'>").appendTo($("#detailsHistory"));

        const printAction: { [key: string]: [string, string] } = {
            "edit item": ["modified", "fa-pencil"],
            "reviewed item": ["reviewed", "fa-check"],
            "touch items": ["touched", "fa-hand-point-up"],
            "undelete item": ["restored", "fa-trash-restore"],
            "delete item": ["deleted", "fa-trash"],
            "add item": ["created", "fa-plus"],
            "execute tests": [" created test form", "fa-cogs"],
            "add link": ["add link", "fa-link"],
            "merge merge": ["merged", "fa-code-merge"],
            "clone project": ["project copy", "fa-copy"],
            "import_module item": ["import", "fa-external-link"],
            "delete import": ["delete import", "fa-trash"],
        };

        // MATRIX-4704 filter history: there's some entries which belong to other items, get rid of those
        let audit = historyUnfiltered.audit.filter(
            (historyEntry) =>
                that.checkItemId(historyEntry.itemBefore, itemId) && that.checkItemId(historyEntry.itemAfter, itemId),
        );

        let version = 1;
        let preVersion = 0;
        let line = null;
        let auditIdx = audit.length - 1;
        let tagIdx = 0;
        if (tags.length && tags[0].label == "base") tags[0].label = "Project creation";

        tags.sort(function (a, b) {
            return a.auditId - b.auditId;
        });
        let begin: JQuery;

        let lastSelectionBucket: JQuery = null;
        while (auditIdx >= 0 || tagIdx < tags.length) {
            if (auditIdx < 0 || (tagIdx < tags.length && tags[tagIdx].auditId < audit[auditIdx].auditId)) {
                let tag = $(`
                    <div class='historyTagLine' title='Tagged time: ${tags[tagIdx].auditTime}'>
                        <div class="tagLabel"><span>${tags[tagIdx].label}</span></div>
                        <div class="historyIcon historyTagIcon"><i class="fal fa-tag"></i></div>
                    </div>
                `).prependTo(inner);
                if (tags[tagIdx].label.match(/^MERGE_.*_begin$/)) {
                    begin = tag;
                } else if (begin && tags[tagIdx].label.match(/^MERGE_.*_end$/)) {
                    $(".tagLabel", begin).html(`<span>${tags[tagIdx].label.replace("_end", "_begin_end")}</span>`);
                    tag.remove();
                }
                tagIdx++;
            } else {
                begin = null;
                let historyEntry = audit[auditIdx];
                let icon = "";
                let negateIcon = false;
                let createdSign = "";
                let actionName = "";
                let detail = null;
                if (ml.Item.parseRef(itemId).type != "XTC" && historyEntry.action == "execute") {
                    // special case (XTC execution does not create a new version but it has the XTC as item after...)
                } else if (historyEntry.itemAfter && historyEntry.itemAfter.itemOrFolderRef) {
                    version = ml.Item.parseRef(historyEntry.itemAfter.itemOrFolderRef).version;
                    createdSign =
                        ml.Item.parseRef(historyEntry.itemAfter.itemOrFolderRef).type == "SIGN" &&
                        ml.Item.parseRef(itemId).type == "DOC"
                            ? ml.Item.parseRef(historyEntry.itemAfter.itemOrFolderRef).id
                            : "";
                } else {
                    let explicitVersion = simpleHistory.filter((sh) => sh.date == historyEntry.dateTime);
                    if (explicitVersion.length == 1) {
                        version = explicitVersion[0].version;
                    }
                }

                let ad = historyEntry.action + " " + historyEntry.entity;
                const print = printAction[ad];
                let hideLine = false;
                if (createdSign) {
                    actionName = "created sign";
                    detail = "Created " + createdSign;
                    version = preVersion;
                } else if (historyEntry.action == "indexer") {
                    actionName = "reindex";
                    icon = "fa-pencil";
                    historyEntry.reason = "Item updated after config change";
                } else if (historyEntry.reason == "SignReject") {
                    actionName = "rejected signature";
                    icon = "fa-pencil";
                    detail = "rejected document";
                    negateIcon = true;
                    historyEntry.reason = "";
                } else if (print) {
                    actionName = print[0];
                    icon = print[1];
                } else if (ad == "add item_link") {
                    let itemUp = historyEntry.itemUp.itemOrFolderRef;
                    let itemDown = historyEntry.itemDown.itemOrFolderRef;
                    icon = "fa-link";
                    if (itemUp == itemId) {
                        actionName = "Link down";
                        detail = "link:" + itemDown;
                    } else {
                        actionName = "Link up";
                        detail = "uplink:" + itemUp;
                    }
                } else if (ad == "delete item_link") {
                    let itemUp = historyEntry.itemUp.itemOrFolderRef;
                    let itemDown = historyEntry.itemDown.itemOrFolderRef;
                    icon = "fa-unlink";
                    if (itemUp == itemId) {
                        detail = "remove link:" + itemDown;
                    } else {
                        detail = "remove uplink:" + itemUp;
                    }
                } else if (ad == "signature item") {
                    icon = "fa-pencil";
                    detail = historyEntry.reason;
                    historyEntry.reason = "";
                } else if (ad == "move item") {
                    icon = "fa-arrow-right";
                    actionName = "move";
                } else if (ad == "store signed item") {
                    hideLine = true;
                } else if (ad == "document reject item") {
                    detail = "Rejected Document";
                    actionName = ad;
                    icon = "fa-pencil";
                    negateIcon = true;
                    // hack remove if server's fixed
                    if (version == preVersion) version += 1;
                } else {
                    actionName = ad;
                    icon = "fa-question-circle";
                }

                if (actionName == "restored") {
                    detail = "restored";
                }

                if (!hideLine) {
                    const detailLine = detail !== null ? `<b>${detail}${historyEntry.reason ? ":" : ""}</b> ` : "";
                    const userNameDisplay = this.formatUserName(
                        historyEntry.userLogin,
                        globalMatrix.ItemConfig.getFullName(historyEntry.userLogin),
                    );

                    if (version !== preVersion) {
                        lastSelectionBucket = $(`<div class="historyVersionContainer"/>`).prependTo(inner);
                    } else if (actionName == "deleted") {
                        lastSelectionBucket = $(`<div class="historyVersionContainerDeleted"/>`).prependTo(inner);
                    }

                    let negate = "<i class='fal fa-slash' style='position: absolute;left: 10px;'></i>";

                    line = $(`
                        <div class='historyDetailLine'>
                            <div class="historyIcon" title="${actionName}"><i class="fal ${icon}"></i>${
                                negateIcon ? negate : ""
                            }</div>
                            <div class="historyDetail">
                                <span class='historyDetailAction'>${detailLine}${historyEntry.reason}</span>
                                <div class='historyDetailDate'>
                                    <span>${historyEntry.dateTimeUserFormat}</span>
                                    <span>${userNameDisplay}</span>
                                </div>
                            </div>
                        </div>
                    `)
                        .prependTo(lastSelectionBucket)
                        .data("rev", actionName == "deleted" ? -1 : version);

                    if (version !== preVersion) {
                        line.append(
                            $(`
                            <span class="historyTagContainer">
                                <a class="historyTag" title="Create Tag" data-auditid="${historyEntry.auditId}">
                                    <div class="addLabelImage"></div>
                                </a>
                            </span>
                        `),
                        );
                    } else {
                        line.append($(`<span class="historyTagContainer">`));
                    }
                }

                preVersion = version;
                auditIdx--;
            }
        }

        $(".historyTag").click(function (event: JQueryEventObject) {
            if (event.preventDefault) event.preventDefault();
            if (event.stopPropagation) event.stopPropagation();

            let auditId = $(event.delegateTarget).data("auditid");
            let clickedLine = $(event.delegateTarget).closest(".historyVersionContainer");
            //TODO:[TS] Discuss with Wolfgang, this is messy, should recreate UI from state instead
            that.createTag(auditId, function (newTag) {
                let line = $(`
                    <div class='historyTagLine'>
                        <div class="tagLabel"><span>${newTag}</span></div>
                        <div class="historyIcon historyTagIcon" title="Tagged"><i class="fal fa-tag"></i></div>
                    </div>
                `).insertBefore(clickedLine);

                ml.UI.showSuccess("Tag has been created");
            });
            return false;
        });

        $(".historyDetailLine", inner).click(function (event: JQueryMouseEventObject) {
            let clicked = $(event.delegateTarget);
            let revision = clicked.data("rev");
            let tab = $("td.historyDlgVersionPaneSelector").filter(function (_idx, node) {
                return $(node).data("rev") == revision;
            });

            tab.trigger("click");
            if (event.preventDefault) event.preventDefault();
            if (event.stopPropagation) event.stopPropagation();
        });
    }

    private showHistoryDialog(options: IShowHistoryDialogOptions) {
        let that = this;

        app.dlgForm.html("");
        app.dlgForm.addClass("dlg-no-scroll");
        app.dlgForm.removeClass("dlg-scroll");

        let versions = this.compareVersionDialog(
            options.item,
            options.item.history,
            true,
            options.preselect ? options.preselect : [],
            !options.readOnly,
        );
        let detailsVersion = $("<div class='detailsVersions'>").append(versions);
        let detailsHistory = $("<div id='detailsHistory'>");

        app.dlgForm.append(detailsHistory).append(detailsVersion);

        if (ml.UI.DateTime.requiresTimeZoneWarning()) {
            app.dlgForm.append(ml.UI.DateTime.getTimeZoneCTA());
        }

        // prevent auto scrolling when opening dialog
        let scrollTop = $("#itemDetails > .panel-body-v-scroll").length
            ? $("#itemDetails > .panel-body-v-scroll")[0].scrollTop
            : 0;

        app.dlgForm
            .dialog({
                autoOpen: true,
                title: "History of '" + (options.id ? options.id : options.item.id) + "'",
                width: $(document).width() * 0.85,
                height: app.itemForm.height() * 0.85,
                modal: true,
                resizeStop: function () {
                    app.dlgForm.resizeDlgContent(that.controls);
                },
                open: function () {
                    ml.UI.pushDialog(app.dlgForm);
                    if (app.dlgForm[0].scrollWidth > app.dlgForm.innerWidth()) {
                        $(".any_day > div").hide();

                        // still bigger? -> scroll right
                        if (app.dlgForm[0].scrollWidth > app.dlgForm.innerWidth()) {
                            app.dlgForm[0].scrollLeft = app.dlgForm[0].scrollWidth - app.dlgForm.innerWidth() + 10;
                        }
                    }
                    that.initHistoryOptionSelect(
                        options.item.history,
                        $(".cbHide"),
                        options.id ? options.id : options.item.id,
                    );
                },
                close: function () {
                    if (scrollTop) $("#itemDetails > .panel-body-v-scroll")[0].scrollTop = scrollTop;
                    ml.UI.popDialog(app.dlgForm);
                },
                buttons: [
                    {
                        text: "Hide ",
                        class: "cbHide",
                        click: function () {},
                    },
                    {
                        text: "Ok",
                        class: "btnOk",
                        click: function () {
                            app.dlgForm.removeClass("dlg-scroll");
                            app.dlgForm.dialog("close");
                        },
                    },
                ],
            })
            .resizeDlgContent(this.controls);
    }

    private initHistoryOptionSelect(history: IItemHistory[], container: JQuery, itemId: string) {
        let that = this;

        const optionsHistoryViewSelect = ["Time Bar", "Time Bar Condensed", "Full History"];
        let currentStr = globalMatrix.serverStorage.getItem("HistoryViewSelect");
        let current = currentStr ? Number(currentStr) : 0;

        let dropup = ml.UI.createDropDownButton(
            "",
            [
                {
                    name: optionsHistoryViewSelect[0],
                    click: () => {
                        that.updateHistoryOptionSelect(history, dropup, itemId, 0, optionsHistoryViewSelect[0]);
                    },
                },
                {
                    name: optionsHistoryViewSelect[1],
                    click: () => {
                        that.updateHistoryOptionSelect(history, dropup, itemId, 1, optionsHistoryViewSelect[1]);
                    },
                },
                {
                    name: optionsHistoryViewSelect[2],
                    click: () => {
                        that.updateHistoryOptionSelect(history, dropup, itemId, 2, optionsHistoryViewSelect[2]);
                    },
                },
            ],
            true,
            "HistoryViewSelect",
            true,
        );

        container.replaceWith(dropup);

        that.updateHistoryOptionSelect(history, dropup, itemId, current, optionsHistoryViewSelect[current]);
    }

    private updateHistoryOptionSelect(
        simpleHistory: IItemHistory[],
        dropup: JQuery,
        itemId: string,
        option: number,
        text: string,
    ) {
        let that = this;

        dropup.removeClass("open");

        $("#HistoryViewSelect").html(text);
        globalMatrix.serverStorage.setItem("HistoryViewSelect", option + "");

        switch (option) {
            case 0:
                $("#historyDlgYear").show();
                $("#historyDlgMonth").show();
                $("#historyDlgTime").show();
                $(".any_day > div").show();
                $("#detailsHistory").hide();
                $("#detailsHistory").removeClass("detailsHistoryEx");
                $(".detailsVersions").removeClass("detailsVersionsEx");
                break;
            case 1:
                $("#historyDlgYear").show();
                $("#historyDlgMonth").show();
                $("#historyDlgTime").show();
                $("#detailsHistory").hide();
                $(".any_day > div").hide();
                $("#detailsHistory").removeClass("detailsHistoryEx");
                $(".detailsVersions").removeClass("detailsVersionsEx");
                break;
            default:
                $("#historyDlgYear").hide();
                $("#historyDlgMonth").hide();
                $("#historyDlgTime").hide();
                $("#detailsHistory").show();
                $("#detailsHistory").addClass("detailsHistoryEx");
                $(".detailsVersions").addClass("detailsVersionsEx");
                if ($("div", $("#detailsHistory")).length == 0) {
                    restConnection.getProject("tag").done(function (tags) {
                        restConnection
                            .getProject("audit?startAt=0&maxResults=1000&itemRef=" + itemId)
                            .done(function (history) {
                                that.showHistoryDetails(
                                    itemId,
                                    simpleHistory,
                                    history as XRGetProject_ProjectAudit_TrimAuditList,
                                    tags as XRGetProject_Tags_TagList,
                                );

                                // get the currently shown tabs
                                let shown: number[] = [];
                                $.each($(".historyDlgVersionPaneSelected"), (topIdx, top) => {
                                    shown.push($(top).data("rev"));
                                });
                                // highlight them in the bar on left
                                $.each($(".historyDetailLine"), function (_hrlIdx, hrl) {
                                    if (shown.indexOf($(hrl).data("rev")) != -1) {
                                        $(hrl).parent().addClass("historyVersionContainerSelected");
                                    }
                                });
                            });
                    });
                }
                break;
        }
    }
    private showDiffDialog(latest: IItem, localChanges: IItem) {
        let that = this;

        app.dlgForm.html("");
        app.dlgForm.removeClass("dlg-no-scroll");
        app.dlgForm.addClass("dlg-scroll");

        app.dlgForm
            .dialog({
                autoOpen: true,
                title: "Item changed",
                height: $(window).height() - 40,
                width: $(window).width() - 40,
                modal: true,
                resizeStop: function () {
                    app.dlgForm.resizeDlgContent(that.controls);
                },
                open: function () {
                    ml.UI.pushDialog(app.dlgForm);
                    that.showSaveConflict(app.dlgForm, latest, localChanges);
                },
                close: function () {
                    ml.UI.popDialog(app.dlgForm);
                },
                buttons: [
                    {
                        text: "Ok",
                        class: "btnOk",
                        click: function () {
                            app.dlgForm.removeClass("dlg-scroll");
                            app.dlgForm.dialog("close");
                        },
                    },
                ],
            })
            .resizeDlgContent(this.controls);
    }

    private showNextActivity(
        accordion: JQuery,
        control: JQuery,
        from: number,
        count: number,
        auditIdMin?: number,
        auditIdMax?: number,
    ): JQueryDeferred<string> {
        let that = this;
        let d = $.Deferred<string>();
        app.getActivityAsync(
            function (item, _first, _last, updatedLink) {
                // update UI callback
                $(".spinningWait", control).hide();
                let versionPanel: JQuery;
                if (item) {
                    if (item.action === "report") {
                        versionPanel = that.createReportLine({
                            id: item.id,
                            title: item.title,
                            version: item.version,
                            user: item.user,
                            date: item.dateUserFormat,
                            dateServer: item.date,
                            job: item.job,
                        });
                        accordion.append(versionPanel);
                    } else if (item.action === "execute") {
                        versionPanel = that.createExecuteLine({
                            id: item.id,
                            title: item.title,
                            version: item.version,
                            user: item.user,
                            dateServer: item.date,
                            date: item.dateUserFormat,
                        });
                        accordion.append(versionPanel);
                    } else if (item.action === "signature") {
                        versionPanel = that.createSignLine({
                            id: item.id,
                            title: item.title,
                            version: item.version,
                            user: item.user,
                            dateServer: item.date,
                            date: item.dateUserFormat,
                            reason: item.reason,
                        });
                        accordion.append(versionPanel);
                    } else if (item.action === "merge") {
                        versionPanel = that.createMergeLine({
                            user: item.user,
                            dateServer: item.date,
                            date: item.dateUserFormat,
                            tags: item.tags,
                            comment: item.comment,
                            ctrl: control,
                            auditId: item.auditId,
                            action: item.action,
                        });
                        accordion.append(versionPanel);
                    } else {
                        versionPanel = that.createPanel({
                            deletedItems: item.action === "deleted",
                            ctrl: control,
                            id: item.id,
                            title: item.title,
                            isFolder: false,
                            version: item.version,
                            user: item.user,
                            action: item.action,
                            dateServer: item.date,
                            date: item.dateUserFormat,
                            comment: item.comment,
                            allowRestore: false,
                            fullVersion: item.fullVersion,
                            auditId: item.auditId,
                            tags: item.tags,
                        });
                        accordion.append(versionPanel);

                        that.lastHistory = item.dateUserFormat;
                    }
                } else if (updatedLink) {
                    versionPanel = that.createReferenceLine(updatedLink);
                    accordion.append(versionPanel);
                    that.lastHistory = updatedLink.dateUserFormat;
                }
                // add timewarp info
                if (versionPanel && item && globalMatrix.ItemConfig.isAfterTimeWarp(item.date)) {
                    that.lastWasTimewarp = true;
                    versionPanel.addClass("timewarp");
                } else if (that.lastWasTimewarp) {
                    that.lastWasTimewarp = false;
                    versionPanel.addClass("justAfterTimewarp");
                }
            },
            from,
            count,
            auditIdMin,
            auditIdMax,
        ).done(function (total) {
            that.pFrom = from;
            that.pCount = count;
            that.pTotal = total;
            d.resolve();
        });

        return d;
    }
    pFrom: number;
    pCount: number;
    pTotal: number;

    scrollInstalled = false;

    private showNextReaders(doclist: JQuery, control: JQuery, from: number, count: number) {
        let that = this;

        app.getActivityAsync(
            function (item: IVersionDetails) {
                // update UI callback
                $(".spinningWait", control).hide();
                if (item) {
                    // item can be null for added links
                    if (item.id.indexOf("DOC-") === 0 || item.id.indexOf("SIGN-") === 0) {
                        if (!that.readHistory[item.id]) {
                            that.readHistory[item.id] = { history: [], id: item.id };
                        }
                        that.readHistory[item.id].history.push(item);
                        that.showReadHistory(doclist, that.readHistory[item.id]);
                    }
                    that.lastHistory = item.dateUserFormat;
                }
            },
            from,
            count,
        ).done(function (total) {
            if (from + count < total) {
                $(".spinningWait", control)
                    .html("Showing activity after " + that.lastHistory + '. <span class="showMore">show more...</span>')
                    .show()
                    .click(function () {
                        $(".spinningWait", control).replaceWith(ml.UI.getSpinningWait());
                        that.showNextReaders(doclist, control, from + count, count);
                    });
            }
        });
    }

    private formatReadHistoryAction(action: string, actionDetails: IVersionDetails): JQuery {
        var result = $("<span>");
        if (action === "signed") {
            var reason = actionDetails.reason.split(" by ");
            if (reason.length > 1) {
                result.append("<b>" + action + "</b> by " + reason[1] + " at " + actionDetails.dateUserFormat);
            } else {
                result.append("<b>" + action + "</b> at " + actionDetails.dateUserFormat);
            }
        } else {
            result.append("<b>" + action + "</b> by " + actionDetails.user + " at " + actionDetails.dateUserFormat);
        }
        return result;
    }

    private showReadHistory(doclist: JQuery, doc: IHistory): void {
        let that = this;

        if (!doc.panel) {
            var plus = $('<span class="fal fa-minus-square plusctrl">');
            plus.data("doc", doc.id);
            plus.data("open", true);
            plus.click(function (event: JQueryEventObject) {
                var info = that.readHistory[$(event.delegateTarget).data("doc")];
                if ($(event.delegateTarget).data("open")) {
                    $(event.delegateTarget).removeClass("fa-minus-square");
                    $(event.delegateTarget).addClass("fa-plus-square-o");
                    $(event.delegateTarget).data("open", false);
                    info.panelBorder.hide();
                } else {
                    $(event.delegateTarget).addClass("fa-minus-square");
                    $(event.delegateTarget).removeClass("fa-plus-square-o");
                    $(event.delegateTarget).data("open", true);
                    info.panelBorder.show();
                }
            });

            doc.header = $('<div class="">').refLink({
                folder: false, // show id if it exists
                id: doc.id,
                title: doc.history[0].title,
                style: refLinkStyle.selectTree,
                tooltip: refLinkTooltip.none,
            });
            doc.panel = $('<div class="detailsRead">');
            doc.panelBorder = $('<div class="detailsReadOuter">');
            doclist.append(plus).append(doc.header).append(doc.panelBorder.append(doc.panel));
        }
        var current = doc.history[doc.history.length - 1];
        var version = $('<div class="">');
        doc.panel.append(version);
        if (current.action === "edit") {
            if (!doc.wasEdit) {
                version.append(this.formatReadHistoryAction("modified", current));
            } else {
                // don't show this is an edit after an edit or create
            }
            doc.wasEdit = true;
        } else if (current.action === "add") {
            version.append(this.formatReadHistoryAction("created", current));
        } else if (current.action === "report") {
            version.append(this.formatReadHistoryAction("downloaded", current));
            doc.wasEdit = false;
        } else if (current.action === "signature") {
            version.append(this.formatReadHistoryAction("signed", current));
            doc.wasEdit = false;
        } else {
            version.append(this.formatReadHistoryAction(current.action, current));
            doc.wasEdit = false;
        }
    }

    // This is used in the shadowed compare display
    private static compare_css: string = `
    <style>
    .compareTools {
        position: absolute;
        right: 3em;
        top: 8px;
        color: #2920dc
    }

    .compareToolsHeader {
        padding-right: 10px;
        font-weight: 700
    }

    .compareToolsGroup {
        display: table;
        float: left
    }

    .compareTool {
        padding-right: 12px;
        display: table-cell
    }

    .compareRow {
        display: table-row
    }

    .compareLeft {
        display: table-cell;
        width: 720px;
        vertical-align: top;
        padding-right: 10px
    }

    .compareItem {
        margin-bottom: 10px!important
    }

    .compareRight {
        width: 700px;
        display: table-cell;
        vertical-align: top;
        padding-left: 10px
    }

    @media print {
        .forcePrint {
            height: initial!important;
            max-height: initial!important;
            min-height: initial!important
        }
    }

    .compare_add {
        background-color: #ff0!important;
        display: inline-block;
        white-space:normal;
    }

    .compare_remove {
        display: inline-block;
        color: red!important;
        text-decoration: line-through!important;
        white-space:normal;
    }

    .compare_style {
        background-color: #f3f391!important;
        white-space:normal;
    }

    .compareHeaders {
        display: table-row
    }

    .compareHeader {
        display: table-cell;
        width: 700px;
        text-align: center;
        font-size: x-large;
        padding-top: 20px
    }
    </style>
    `;
}
