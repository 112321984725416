/*
Notes about the implementation.

1) Pay close attention to the 'this' variable:
   Within the editors defined in this file, event handlers often have a 'this' context set
   by the caller that is required for certain functions. It's important to use another variable
   (like 'that') to reference member variables. For example:

                click: function () {
                    var rt = rte.getController().getValueAsync().replace(/(\r\n|\n|\r)/gm, "");
                    that.$input.val(rt);
                    that.richTextEditActive = false;
                    $(this).dialog("close");          <--- note 'this', not 'that'
                    that.args.grid.navigateNext();
                }

   $(this) is the way to access dialog functionality. It is not "your" 'this' context, it is
   the one that belongs to this function.

2) Anonymous class definitions are helpful:
   All the editors are implemented as anonymous classes. The class is passed to the grid editor
   where it appears as a constructor. The grid editor calls the function with 'new', passing
   arguments. In our case, we first need to bind the 'this' pointer of the parent class
   (OldTableControlImpl), so the anonymous class has all the member variables it needs. So each
   of the editors has a factory function that does that and returns the anonymous class.
   It can be helpful to look at the JavaScript compiled by TypeScript to make this more concrete.
   The key is that the class needs to install it's member methods on the object provided by
   the 'new' operator in the grid code. And anonymous classes are the only way I found to do that.

3) use of Javascript 'bind':
   If you have a class method that you want to expose as a callback, you need to manually bind
   the 'this' pointer to it, like so:

                this.$input.bind("keydown", this.handleKeyDown.bind(this));
                this.$input.bind("paste", this.handlePaste.bind(this));

   Now, when the keydown event occurs, this.handleKeyDown is called with the right 'this' pointer.
   The one you intended when the code was written. Otherwise it will be undefined or something
   weird.
*/
/// <reference types="matrixrequirements-type-declarations" />
import { app, ControlState, globalMatrix, matrixSession } from "../../../globals";
import { autoColumnSetting, autoColumnDefault, IAutoColumn, ITraceConfig } from "../../../ProjectSettings";
import {
    tableMath,
    mDHF,
    ColumnEditor,
    SteplistFieldHandler,
    BaseTableFieldHandler,
    ITableControlBaseParams,
} from "../../businesslogic";
import { ml } from "../../matrixlib";
import { HTMLCleaner } from "../../matrixlib/index";
import { BaseControl, IBaseControlOptions, ITableParams } from "./BaseControl";

interface IOldTableControlOptions extends IBaseControlOptions {
    fieldHandler: BaseTableFieldHandler;
}

$.fn.tableCtrl = function (this: JQuery, options: IOldTableControlOptions) {
    if (!options.fieldHandler) {
        // It's not a table from a field, so let's create an BaseTableFieldHandler that is not validating anything
        options.fieldHandler = new BaseTableFieldHandler(<ITableControlBaseParams>options);
    }

    let baseControl = new OldTableControlImpl(this, options.fieldHandler);
    this.getController = () => {
        return baseControl;
    };
    options.fieldHandler.initData(options.fieldValue);
    baseControl.init(options);
    baseControl.initControl();
    baseControl.saveData();
    this.insertLine = (line) => {
        baseControl.insertLine(line);
    };
    return baseControl;
};

interface ISelectColOption {
    id: string;
    label: string;
    class: string;
    sId: number;
    disabled: boolean;
}
interface ISelectColGroup {
    value: string;
    label: string;
}
interface ISelectColOptions {
    options: ISelectColOption[];
    groups: ISelectColGroup[];
}

class OldTableControlImpl extends BaseControl<BaseTableFieldHandler> {
    private settings: IBaseControlOptions;

    private editorActive = false;

    // member variables
    private data; // contains always most current data of grid
    private ctrlContainer; // container for table and hidden formatting sections
    private _list; // the table control
    private grid; // the grid created for the table control
    private columns; // columns of the grid
    private rowToolsColumn; // the column which contains tools (actually the last or none)
    private focsuable;
    private lastSelectedRows; // contains the last selected row (used in rowToolsFormatter, not to show tools in last row)
    private vp; // to find / restore scroll position
    private vpp; // last scroll position storage used by functions manipulating grid
    private passFailOptions;
    private lastSize; // to handle resizing more efficiently
    private extras;
    private canImport;
    private formattersRequiringSizers: Function[];
    private ignoreResize;
    private ignoreResizeReset;

    constructor(control: JQuery, fieldHandler: BaseTableFieldHandler) {
        super(control, fieldHandler);
    }

    init(options: IBaseControlOptions) {
        var defaultOptions = {
            controlState: ControlState.FormView, // read only rendering
            dummyData: false, // fill control with a dumy text (for form design...)
            canEdit: false, // whether data can be edited
            valueChanged: function () {}, // callback to call if value changes
            parameter: {
                readonly: false, // can be set to overwrite the default readonly status
                columns: [], // columns to be added [{name, field, editor, values}], list of column definitions:
                // name: the name of the column shown in the table header
                // field: the id in the column / of the data
                // editor: (optional) 'text','result' (if and how to edit the cell). , ...
                onDblClick: function () {}, // optional function to be called if cell is double clicked
                canBeModified: true, // if true, rows can be added and moved,
                create: true, // if canBeModified also can add lines (with little icon)
                showLineNumbers: true, // whether to show line numbers in front of each row (Step)
                maxRows: -1, // if set to a value other than -1, the maximum number of rows which can be created
                fixRows: 0, // if set to a value >0, the table has the exact number of rows, no rows can be added or removed (maxRows is ignored)
                readonly_allowfocus: false, // whether not editable cells can get focus (e.g. with tab)
                cellAskEdit: "", // indicate that cell can be edited by showing string
                autoUpdate: "", // by default don't run a script
            },
        };
        var settings = ml.JSON.mergeOptions(defaultOptions, options);

        if (
            settings.parameter.maxRows &&
            typeof settings.parameter.maxRows != "number" &&
            !isNaN(settings.parameter.maxRows)
        ) {
            settings.parameter.maxRows = Number(settings.parameter.maxRows);
        }
        if (
            settings.parameter.fixRows &&
            typeof settings.parameter.fixRows != "number" &&
            !isNaN(settings.parameter.fixRows)
        ) {
            settings.parameter.fixRows = Number(settings.parameter.fixRows);
        }
        this.settings = settings;

        this.data = []; // contains always most current data of grid
        this.ctrlContainer = $('<div style="font-size:8pt"></div>').addClass("baseControl"); // container for table and hidden formatting sections
        this._list = $('<div class="slickTable">'); // the table control
        // this.grid; // the grid created for the table control
        this.columns = []; // columns of the grid
        // this.rowToolsColumn; // the column which contains tools (actually the last or none)
        this.focsuable = { 0: { focusable: settings.parameter.readonly_allowfocus } };
        // this.lastSelectedRows; // contains the last selected row (used in rowToolsFormatter, not to show tools in last row)
        // this.vp; // to find / restore scroll position
        // this.vpp; // last scroll position storage used by functions manipulating grid
        this.passFailOptions = settings.parameter.passFailEditorConfig;
        // this.lastSize; // to handle resizing more efficiently
        this.extras = globalMatrix.ItemConfig.getExtrasConfig();
        this.canImport =
            settings.parameter.create &&
            this.extras &&
            settings.canEdit &&
            (ml.JSON.isTrue(this.extras.tableCanImport) ||
                (this.extras.tableCanImport === "admin" && matrixSession.isAdmin()));

        if (settings.parameter.limitToA4) {
            this._list.css("width", "680px");
            this._list.addClass("a4fullwidth");
        } else if (settings.parameter.reviewMode) {
            this._list.width(settings.control.width() - 10);
        }
    }

    // public interface
    async hasChangedAsync() {
        return JSON.stringify(this._root.data("original")) !== JSON.stringify(this._root.data("new"));
    }

    async getValueAsync() {
        if (this.grid.getEditorLock().isActive()) {
            if (this.grid.getEditorLock().commitCurrentEdit()) {
                // commit succeeded; proceed with submit
            }
        }
        this.setUIDs();

        (<SteplistFieldHandler>this.settings.fieldHandler).setDataAsArray(this._root.data("new"), true);
        return this.settings.fieldHandler.getData();
    }

    getController() {
        return this;
    }

    highlightReferences() {
        // Only in cell, not in header
        $(this).find(".grid-canvas").highlightReferences();
    }

    setValue(val: string) {
        this.settings.fieldHandler.setData(val);

        var oldNew = JSON.stringify(this.grid.getData());
        this.data = JSON.parse(this.settings.fieldHandler.getData());
        this._root.data("new", this.data);

        this.grid.setData(this.data);
        this.grid.render();
        var newNew = JSON.stringify(this.grid.getData());

        if (oldNew != newNew) {
            // only trigger change if grid really changed MATRIX-3849
            this.gridChanged();
        }
    }

    setHiddenCell(rowIdx, columnName, value) {
        var cdata = this.grid.getData();

        if (cdata.length > rowIdx) {
            cdata[rowIdx][columnName] = value;
        }
        this.grid.data = cdata;
        this.gridChanged();
    }

    getHiddenCell(rowIdx, columnName) {
        var cdata = this.grid.getData();

        if (cdata.length > rowIdx) {
            return cdata[rowIdx][columnName];
        }

        return null;
    }

    linksToCreate() {
        var links = { down: [], up: [] };
        var ct = this._root.data("new");
        let that = this;
        for (var column in this.settings.parameter.columns) {
            if (
                this.settings.parameter.columns[column].editor === ColumnEditor.uprules &&
                this.settings.parameter.columns[column].options &&
                this.settings.parameter.columns[column].options.autolink
            ) {
                ct.forEach(function (row) {
                    var cell = row[that.settings.parameter.columns[column].field];
                    if (cell) {
                        links.up = links.up.concat(cell.split(","));
                    }
                });
            }
            if (
                this.settings.parameter.columns[column].editor === ColumnEditor.downrules &&
                this.settings.parameter.columns[column].options &&
                this.settings.parameter.columns[column].options.autolink
            ) {
                ct.forEach(function (row) {
                    var cell = row[that.settings.parameter.columns[column].field];
                    if (cell) {
                        links.down = links.down.concat(cell.split(","));
                    }
                });
            }
        }
        return links;
    }

    destroy() {
        this.grid.destroy();
    }

    resizeItem(newWidth, force) {
        if (this.ignoreResize || newWidth === 0 || this.lastSize === newWidth) {
            return;
        }
        this.lastSize = newWidth;
        this.redraw(force);
    }

    refresh() {
        this.updateRowHeights();
    }

    redraw(force) {
        let that = this;
        if (force) this.grid.resizeCanvas();
        this.grid.invalidate();
        if (force) this.grid.render();

        if (!this.settings.isPrint) {
            // no need to change rows before the current edit
            this.updateRowHeights();
            ml.SmartText.showTooltips($(this.getController()), false);
        } else {
            // this is for the print view... It's ok to wait 3 seconds before to
            // paint it nicely
            window.setTimeout(function () {
                that.updateRowHeights();
            }, 3000);
        }
    }

    insertLine(newLine) {
        var gdata = this.grid.getData();
        gdata.push(newLine);
        this.grid.setData(gdata);
        this.grid.setSelectedRows([]);
        this.updateRowHeights();
        this.grid.render();
        this.gridChanged();
    }

    private setUIDs() {
        var uids = "";
        for (var column of this.settings.parameter.columns) {
            if (column.editor === ColumnEditor.uid) {
                uids = column.field;
            }
        }
        if (!uids) return;
        // there' an uid column: find the max id for this tc
        let maxuid = 0;
        for (var row of this._root.data("new")) {
            if (row[uids]) {
                var idp = Number(row[uids].split("-")[0]);
                maxuid = Math.max(idp, maxuid);
            }
        }
        maxuid++;

        for (var row of this._root.data("new")) {
            if (!row[uids]) {
                row[uids] = this.createUid(maxuid);
                maxuid++;
            } else {
                // maybe need to update the uid if row changed
                var update = false;
                for (var old of this._root.data("original")) {
                    if (old[uids] == row[uids] && JSON.stringify(old) != JSON.stringify(row)) {
                        update = true;
                    }
                }
                if (update) {
                    // need to fix uid (line changed)
                    var idp = Number(row[uids].split("-")[0]);
                    row[uids] = this.createUid(idp);
                }
            }
        }
    }

    private createUid(base) {
        var tzeros = base < 10 ? "000" : base < 100 ? "00" : base < 1000 ? "0" : "";
        return (
            tzeros +
            base +
            "-" +
            (1 + (this.settings.item && this.settings.item.history ? this.settings.item.history.length : 0))
        );
    }

    // editors
    // This editors are created with a call to "new". They are classes.
    PassFailEditor() {
        let parent = this;
        return class {
            public args: any;
            public result = "";
            public icon: JQuery;
            public editor: JQuery;

            constructor(args: any) {
                this.args = args;
                this.init();
            }
            init() {
                this.icon = $("<div>");
                this.editor = $("<div>");

                parent.editorActive = true;
                let that = this;

                $(this.args.container).append(
                    $("<table style='width:100%'>").append(
                        $("<tr>")
                            .append($("<td  style='width:30px'>").append(this.icon))
                            .append($("<td>").append(this.editor)),
                    ),
                );

                that.icon.html(parent.passFailFormatterIcon(this.args.item.result));
                var r = this.args.item.result ? this.args.item.result : "";
                var option_str = "";
                for (var idx = 0; idx < parent.passFailOptions.length; idx++) {
                    var resultCode = parent.passFailOptions[idx].code;
                    var resultKey = parent.passFailOptions[idx].key ? " (" + parent.passFailOptions[idx].key + ")" : "";
                    var resultText = parent.passFailOptions[idx].command + resultKey;
                    var selected = r === resultCode ? "selected" : "";
                    option_str += "<OPTION value='" + resultCode + "' " + selected + ">" + resultText + "</OPTION>";
                }

                let $select = $("<SELECT tabIndex='0' class='slick_table_dropdown'>" + option_str + "</SELECT>")
                    .change(function () {
                        // @ts-ignore TODO: investigate what "this" should refer to
                        that.result = $(this).val();
                        // @ts-ignore TODO: investigate what "this" should refer to
                        that.icon.html(parent.passFailFormatterIcon(this.result));
                    })
                    .keypress(function (event) {
                        for (var idx = 0; idx < parent.passFailOptions.length; idx++) {
                            if (String.fromCharCode(event.which) === parent.passFailOptions[idx].key) {
                                that.result = parent.passFailOptions[idx].code;
                                that.save();
                            }
                        }
                        return false;
                    })
                    .blur(function () {
                        that.args.commitChanges(true);
                        that.args.grid.resetActiveCell();
                    });
                that.editor.append($select);

                $select.focus();
            }
            save() {
                this.args.commitChanges();
            }
            destroy() {
                parent.editorActive = false;
                $(this.args.container).empty();
            }

            focus() {}

            serializeValue() {
                return this.result;
            }

            applyValue(item, state) {
                item.result = state;
            }

            loadValue(item) {
                this.result = item.result;
            }

            isValueChanged() {
                return this.args.item.result != this.result;
            }

            validate() {
                return { valid: true, msg: null };
            }
        };
    }

    InplaceLongText() {
        let parent = this;
        return class {
            public args: any;
            public $input: any;
            public $wrapper: any;
            public $help: any;
            public $scroller: any;
            public richTextEditActive;
            public richTextEditWasActive = false;
            public defaultValue;
            public wasPositioned = false;

            constructor(args: any) {
                this.args = args;
                this.init();
            }

            init() {
                var that = this;
                parent.editorActive = true;
                var dlg = $(this.args.container).closest("#appPopup");

                var $container = dlg && dlg.length > 0 ? dlg : $("body");
                this.$scroller = $(this.args.container).closest(".panel-body-v-scroll");
                this.$wrapper = $("<div class='multiLineEditorContainer baseControl hidden-print'/>").appendTo(
                    $container,
                );
                this.$help = $("<div class='multiLineEditorHelp'/>")
                    .html(
                        "<b>tab:</b>save&amp;next, <b>shift-tab</b>:save&amp;back, <b>ctrl-enter</b>: save&amp;down, <b>esc</b>: cancel&amp;close, <b>shift return</b>: open full editor",
                    )
                    .appendTo(this.$wrapper);
                this.$input = $("<textarea hidefocus rows=5 class='multiLineEditor'>")
                    .appendTo(this.$wrapper)
                    .width(Math.max(600, $(this.args.container).width()))
                    .blur(function () {
                        if (!that.richTextEditActive) {
                            that.args.commitChanges(true);

                            if (that.isValueChanged && parent.settings.controlState != ControlState.HistoryView) {
                                $(that.args.container).highlightReferences();
                            }
                            that.args.grid.resetActiveCell();
                        }
                    });

                this.$input.bind("keydown", this.handleKeyDown.bind(this));
                this.$input.bind("paste", this.handlePaste.bind(this));
                this.position(this.args.position);
                this.$input.focus().select();
            }
            editRichText() {
                this.richTextEditActive = true;
                this.richTextEditWasActive = true;
                let that = this;
                var text = this.$input.val();

                var dlg = $("#editFieldDlg");
                dlg.html("");
                dlg.addClass("dlg-no-scroll");
                dlg.removeClass("dlg-v-scroll");

                var rte = $("<div>");
                dlg.append($("<div>").append(rte));
                rte.richText({
                    controlState: ControlState.DialogEdit,
                    fieldValue: text,
                    canEdit: true,
                    help: " ",
                    parameter: { height: 285, tableMode: false, autoEdit: true, autoFocus: true },
                });

                var padding = 28;
                dlg.dialog({
                    autoOpen: true,
                    title: "Edit Cell",
                    height: 520,
                    width: 730,
                    modal: true,
                    resize: function () {
                        $(".note-editable", rte).height(dlg.height() - 65);
                        $("#editFieldDlg").width($("#editFieldDlg").parent().width() - padding);
                    },
                    resizeStop: function () {
                        $(".note-editable", rte).height(dlg.height() - 65);
                        $("#editFieldDlg").width($("#editFieldDlg").parent().width() - padding);
                    },
                    closeOnEscape: false, // escape is annoying because it cannot be undone and it can happen when entering tables
                    open: function () {
                        // MATRIX-6418, MATRIX-6683: resizes causing redraw of the table, which is breaking value update logic.
                        // ignoring the resize when dialog is opened, shouldn't cause any issues, because when value is updated
                        // the table is redrawn anyway and when update is cancelled, we're invoking redraw manually
                        parent.ignoreResize = true;

                        padding = $("#editFieldDlg").parent().width() - $("#editFieldDlg").width();
                        var el = $(".note-editable", rte);

                        el.on("keydown", async function (event) {
                            if (globalMatrix.globalShiftDown && event.keyCode === 13) {
                                if (event.preventDefault) event.preventDefault();
                                if (event.stopPropagation) event.stopPropagation();
                                that.$input.val(await rte.getController().getValueAsync());
                                that.richTextEditActive = false;
                                dlg.dialog("close");
                            }
                        });
                        ml.UI.pushDialog(dlg);
                    },
                    close: function () {
                        ml.UI.popDialog(dlg);
                        parent.ignoreResize = false;
                    },
                    buttons: [
                        {
                            text: "Ok",
                            class: "btnDoIt",
                            click: async function () {
                                var rt = (await rte.getController().getValueAsync()).replace(/(\r\n|\n|\r)/gm, "");
                                that.$input.val(rt);
                                that.richTextEditActive = false;
                                $(this).dialog("close");
                                that.args.grid.navigateNext();
                            },
                        },
                        {
                            text: "Cancel",
                            class: "btnCancelIt",
                            click: function () {
                                that.richTextEditActive = false;
                                $(this).dialog("close");
                                // MATRIX-6418, MATRIX-6683: we're ignoring resizing events when dialog is opened,
                                // meaning that after dialog is closed we need to potentially resize the table.
                                // in case of value update we have to do it all the time in order to render the updated value,
                                // but in case of a cancel, we have to invoke it manually just in case. it's not ideal,
                                // and it's possible to invoke the redraw only if resize actually happen when dialog was opened,
                                // but I'm not sure how much difference it would make in real world scenario.
                                // arguably, not a lot, hence leaving it as is.
                                parent.redraw(true);
                            },
                        },
                    ],
                });
            }
            handlePaste(e) {}
            handleKeyDown(e) {
                if (e.key == "<" /* 226 = < */) {
                    var textarea = e.currentTarget;
                    if (textarea.selectionStart || textarea.selectionStart == "0") {
                        var startPos = textarea.selectionStart;
                        var endPos = textarea.selectionEnd;
                        textarea.value =
                            textarea.value.substring(0, startPos) +
                            "&lt;" +
                            textarea.value.substring(endPos, textarea.value.length);

                        textarea.selectionStart = startPos + "&lt;".length;
                        textarea.selectionEnd = textarea.selectionStart;
                    } else {
                        textarea.value += "&lt;";
                    }
                    if (e.preventDefault) e.preventDefault();
                    if (e.stopPropagation) e.stopPropagation();
                }
                if (e.which == $.ui.keyCode.ENTER && e.shiftKey) {
                    if (e.preventDefault) e.preventDefault();
                    if (e.stopPropagation) e.stopPropagation();

                    this.editRichText();
                } else if (e.which == $.ui.keyCode.ENTER && e.ctrlKey) {
                    this.save();
                } else if (e.which == $.ui.keyCode.ESCAPE) {
                    if (e.preventDefault) e.preventDefault();
                    this.cancel();
                } else if (e.which == $.ui.keyCode.TAB && e.shiftKey) {
                    if (e.preventDefault) e.preventDefault();
                    this.args.grid.navigatePrev();
                } else if (e.which == $.ui.keyCode.TAB) {
                    if (e.preventDefault) e.preventDefault();
                    if (e.stopPropagation) e.stopPropagation();
                    this.args.grid.navigateNext();
                }
            }

            save() {
                this.args.commitChanges();
            }

            cancel() {
                this.$input.val(this.defaultValue);
                this.args.cancelChanges();
            }

            hide() {
                this.$wrapper.hide();
            }

            show() {
                this.$wrapper.show();
            }

            position(cellExtend) {
                if (this.wasPositioned) return;
                this.wasPositioned = true;
                var dlgPosFix = 0;
                var dlg = parent._root.closest("#appPopup");
                if (dlg && dlg.length > 0) {
                    cellExtend.left -= dlg.parent().position().left;
                    cellExtend.right -= dlg.parent().position().left;
                    dlgPosFix = dlg.parent().position().top;
                }

                var moveUp =
                    $(window).height() - ($(this.args.container).offset().top + 100 + $(this.args.container).height());
                var moveDown = $(this.args.container).offset().top - 120;

                if (moveUp < -5) {
                    this.$scroller.scrollTop(this.$scroller.scrollTop() - moveUp);
                } else if (moveDown < -5) {
                    this.$scroller.scrollTop(this.$scroller.scrollTop() + moveDown);
                }

                if (dlg && dlg.length > 0) {
                    this.$wrapper.css(
                        "top",
                        Math.max(
                            10,
                            Math.min(cellExtend.top - 5 - dlgPosFix, dlg.height() - this.$wrapper.outerHeight() - 20),
                        ),
                    );
                    this.$wrapper.css(
                        "left",
                        Math.max(10, Math.min(cellExtend.left - 5, dlg.width() - this.$wrapper.width() - 15)),
                    );
                } else {
                    this.$wrapper.css(
                        "top",
                        Math.max(
                            10,
                            Math.min(
                                cellExtend.top - 5 - dlgPosFix,
                                $(window).height() - this.$wrapper.outerHeight() - 20,
                            ),
                        ),
                    );
                    this.$wrapper.css(
                        "left",
                        Math.max(10, Math.min(cellExtend.left - 5, $(window).width() - this.$wrapper.width() - 15)),
                    );
                }
            }

            destroy() {
                parent.editorActive = false;
                this.$wrapper.remove();
            }

            focus() {
                this.$input.focus();
            }

            loadValue(item) {
                this.$input.val((this.defaultValue = item[this.args.column.field]));
                this.$input.select();
            }

            serializeValue() {
                return this.$input.val();
            }

            applyValue(item, state) {
                if (!this.richTextEditWasActive) {
                    state = state.replace(/\n/g, "<br>");
                }
                state = state.replace(/&/g, "escapeANDXAND");
                let clean = new HTMLCleaner(state);
                // be strict if the server is strict
                clean.applyServerCleaning();
                // do the minimum
                state = clean.getClean(0, true);
                state = state.replace(/escapeANDXAND/g, "&");
                item[this.args.column.field] = state;
            }

            isValueChanged() {
                return (
                    !(this.$input.val() == "" && this.defaultValue == null) && this.$input.val() != this.defaultValue
                );
            }
            validate() {
                return {
                    valid: true,
                    msg: null,
                };
            }
        };
    }

    CommentlogEditor() {
        // let parent = this;
        return class {
            public args: any;
            public currentValue;
            public previousValue;

            constructor(args: any) {
                this.args = args;
                this.previousValue = this.currentValue =
                    args && args.item && args.column && args.column.field ? args.item[args.column.field] : "";
                this.init();
            }

            getDelete() {
                var del = $("<span class='commentDelete commentDate'><i class='fal fa-trash-alt'/></span>");
                del.click(function (event) {
                    $(event.delegateTarget).closest(".commentLine").remove();
                });
                return del;
            }

            addLine(earlier, input) {
                var newLi = $("<div class='commentLine'>");
                if (this.args && this.args.column && this.args.column.options && this.args.column.options.append) {
                    newLi.appendTo(earlier);
                } else {
                    newLi.prependTo(earlier);
                }

                newLi.append(this.getDelete());
                var creationDate = new Date();
                newLi.append(
                    "<div class='commentDate' data-cd='" +
                        creationDate.toISOString() +
                        "'>" +
                        ml.UI.DateTime.renderCustomerHumanDate(creationDate) +
                        "</div>",
                );
                newLi.append("<div class='commentUser'>" + matrixSession.getUser() + "</div>");
                newLi.append(
                    "<div class='commentText' style='float: left;'>" +
                        ml.UI.lt.forDB(input.val(), undefined).replace(/\n/g, "<br />") +
                        "</div>",
                );
                newLi.append("<div style='clear:both;'></div>"); // for height calculation (in case of short text)
                input.val("");
            }

            init() {
                let that = this;
                var padding = 28;
                var dlg = $("#editFieldDlg");
                dlg.html("");
                dlg.addClass("dlg-no-scroll");
                dlg.removeClass("dlg-v-scroll");

                var adder = $("<div>").appendTo(dlg);
                if (that.currentValue && that.currentValue.length) {
                    dlg.append("<div style='padding: 12px 0 0 0;'>Previous Comments:</div>");
                }
                // add earlier comments
                var earlier = $("<div style='overflow-y:auto'>").appendTo(dlg);
                earlier.append(that.currentValue ? that.currentValue : "");
                $.each($(".commentLine", earlier), function (idx, li) {
                    if ($(".commentUser", $(li)).text() == matrixSession.getUser()) {
                        $(li).prepend(that.getDelete());
                    }
                });

                // add tools to add a new comment
                $("<div style='padding: 0 0 6px 0;'>New Comment:</div>").appendTo(adder);
                var input = $('<textarea class="form-control" rows="3" style="resize: vertical;">').appendTo(adder);

                var rte = $("<div>");
                dlg.append($("<div>").append(rte));

                var padding = 28;
                dlg.dialog({
                    autoOpen: true,
                    title: "Edit",
                    height: 450,
                    width: 730,
                    modal: true,
                    resize: function () {
                        earlier.height(dlg.height() - 155);
                        $("#editFieldDlg").width($("#editFieldDlg").parent().width() - padding);
                    },
                    resizeStop: function () {
                        earlier.height(dlg.height() - 155);
                        $("#editFieldDlg").width($("#editFieldDlg").parent().width() - padding);
                    },
                    closeOnEscape: true, // escape is annoying because it cannot be undone and it can happen when entering tables
                    open: function () {
                        padding = $("#editFieldDlg").parent().width() - $("#editFieldDlg").width();
                        earlier.height(dlg.height() - 155);

                        ml.UI.pushDialog(dlg);
                    },
                    close: function () {
                        ml.UI.popDialog(dlg);
                        that.args.commitChanges(true);
                        that.args.grid.resetActiveCell();
                    },
                    buttons: [
                        {
                            text: "Ok",
                            class: "btnDoIt",
                            click: function () {
                                if (input.val()) {
                                    that.addLine(earlier, input);
                                }
                                $(".commentDelete", earlier).remove();
                                that.currentValue = earlier.html();
                                $(this).dialog("close");
                            },
                        },
                        {
                            text: "Cancel",
                            class: "btnCancelIt",
                            click: function () {
                                $(this).dialog("close");
                            },
                        },
                    ],
                });
            }

            save() {
                this.args.commitChanges();
            }
            cancel() {
                this.currentValue = this.previousValue;
            }
            hide() {}
            show() {}
            destroy() {}
            focus() {}
            loadValue(item) {}
            serializeValue() {
                return this.currentValue;
            }
            applyValue(item, state) {
                state = state.replace(/&/g, "escapeANDXAND");
                state = new HTMLCleaner(state).getClean(0, true);
                state = state.replace(/escapeANDXAND/g, "&");
                item[this.args.column.field] = state;
            }
            isValueChanged() {
                return this.previousValue !== this.serializeValue();
            }
            validate() {
                return {
                    valid: true,
                    msg: null,
                };
            }
        };
    }

    /** copy of Slick.Editor.Text with some cleaning */
    TextEditorSafe() {
        // let parent = this;
        return class {
            public args: any;
            public $input;
            public defaultValue;

            constructor(args: any) {
                this.args = args;
                this.init();
            }

            init() {
                this.$input = $("<INPUT type=text class='editor-text' />")
                    .appendTo(this.args.container)
                    .bind("keydown.nav", function (e) {
                        if (e.keyCode === $.ui.keyCode.LEFT || e.keyCode === $.ui.keyCode.RIGHT) {
                            e.stopImmediatePropagation();
                        }
                    })
                    .focus()
                    .select();
            }

            destroy() {
                this.$input.remove();
            }

            focus() {
                this.$input.focus();
            }

            async getValue() {
                return this.$input.val();
            }

            setValue(val) {
                this.$input.val(val);
            }

            loadValue(item) {
                this.defaultValue = item[this.args.column.field] || "";
                this.defaultValue = ml.UI.lt.forUI(this.defaultValue, undefined);

                this.$input.val(this.defaultValue);
                this.$input[0].defaultValue = this.defaultValue;
                this.$input.select();
            }

            serializeValue() {
                return this.$input.val();
            }

            applyValue(item, state) {
                state = ml.UI.lt.forDB(state, undefined);
                state = new HTMLCleaner(state).getClean(0, true);

                item[this.args.column.field] = state;
            }

            isValueChanged() {
                return (
                    !(this.$input.val() == "" && this.defaultValue == null) && this.$input.val() != this.defaultValue
                );
            }

            validate() {
                if (this.args.column.validator) {
                    var validationResults = this.args.column.validator(this.$input.val());
                    if (!validationResults.valid) {
                        return validationResults;
                    }
                }

                return {
                    valid: true,
                    msg: null,
                };
            }
        };
    }

    ColorEditor() {
        // let parent = this;
        return class {
            public args: any;
            public $input;
            public defaultValue;

            constructor(args: any) {
                this.args = args;
                this.init();
            }

            init() {
                this.$input = $("<INPUT type='color' class='editor-text' style='padding:0;height:20px' />")
                    .appendTo(this.args.container)
                    .bind("keydown.nav", function (e) {
                        if (e.keyCode === $.ui.keyCode.LEFT || e.keyCode === $.ui.keyCode.RIGHT) {
                            e.stopImmediatePropagation();
                        }
                    });
            }

            destroy() {
                this.$input.remove();
            }

            focus() {
                this.$input.focus();
            }

            async getValue() {
                return this.$input.val();
            }

            setValue(val) {
                this.$input.val(val);
            }

            loadValue(item) {
                this.defaultValue = item[this.args.column.field] || "";
                var ctx = document.createElement("canvas").getContext("2d");
                ctx.fillStyle = this.defaultValue;
                this.defaultValue = ctx.fillStyle;

                this.$input.val(this.defaultValue);
                this.$input[0].defaultValue = this.defaultValue;
                this.$input.click();
            }

            serializeValue() {
                return this.$input.val();
            }

            applyValue(item, state) {
                item[this.args.column.field] = state;
            }

            isValueChanged() {
                return (
                    !(this.$input.val() == "" && this.defaultValue == null) && this.$input.val() != this.defaultValue
                );
            }

            validate() {
                if (this.args.column.validator) {
                    var validationResults = this.args.column.validator(this.$input.val());
                    if (!validationResults.valid) {
                        return validationResults;
                    }
                }

                return {
                    valid: true,
                    msg: null,
                };
            }
        };
    }

    SelectCellEditor() {
        let parent = this;
        return class {
            public args: any;
            public $select;
            public defaultValue;

            constructor(args: any) {
                this.args = args;
                this.init();
            }

            init() {
                parent.editorActive = true;
                let that = this;
                var option_str = "";
                var updateCells;

                if (typeof this.args.column.options == "string") {
                    option_str = this.args.column.options;
                } else if ($.isArray(this.args.column.options)) {
                    for (var idx = 0; idx < this.args.column.options.length; idx++) {
                        option_str +=
                            "<OPTION value='" +
                            this.args.column.options[idx].id +
                            "'" +
                            (this.args.column.options[idx].disabled ? "disabled" : "") +
                            ">" +
                            this.args.column.options[idx].label +
                            "</OPTION>";
                    }
                } else {
                    for (let opt in this.args.column.options) {
                        option_str += "<OPTION value='" + opt + "'>" + this.args.column.options[opt] + "</OPTION>";
                    }
                }
                this.$select = $("<SELECT tabIndex='0' class='slick_table_dropdown'>" + option_str + "</SELECT>").blur(
                    function () {
                        that.args.commitChanges(true);
                        that.args.grid.resetActiveCell();
                    },
                );

                this.$select.appendTo(this.args.container);
                this.$select.change(function (a, b) {
                    var rowCount = that.args.grid.getData().length;
                    var currentRow = that.args.grid.getActiveCell().row;
                    that.args.commitChanges(currentRow + 1 >= rowCount);
                });
                this.$select.focus();
            }

            destroy() {
                parent.editorActive = false;
                this.$select.remove();
            }

            focus() {
                this.$select.focus();
            }

            loadValue(item) {
                this.defaultValue = item[this.args.column.field];
                this.$select.val(this.defaultValue);
            }

            serializeValue() {
                return this.$select.val();
            }

            applyValue(item, state) {
                item[this.args.column.field] = state;

                let thisCol = parent.settings.parameter.columns.filter((col) => col.field == this.args.column.field);
                if (thisCol.length == 0) {
                    return;
                }
                let otherColumnsNames = parent.settings.parameter.columns
                    .filter((col) => col.field != this.args.column.field)
                    .map((col) => col.name);

                // check if there is a customer / project setting mapping this column to another existing column
                var customerAuto = matrixSession
                    .getCustomerSettingJSON(autoColumnSetting, autoColumnDefault)
                    .maps.filter(
                        (col) =>
                            col.dropdownColumnName == thisCol[0].name &&
                            otherColumnsNames.indexOf(col.textColumnName) != -1,
                    );
                var projectAuto = (<IAutoColumn>(
                    globalMatrix.ItemConfig.getSettingJSON(autoColumnSetting, autoColumnDefault)
                )).maps.filter(
                    (col) =>
                        col.dropdownColumnName == thisCol[0].name &&
                        otherColumnsNames.indexOf(col.textColumnName) != -1,
                );

                var maps = customerAuto.concat(projectAuto);
                for (var map of maps) {
                    var mapping = map.mapping.filter((m) => m.dropdownValue == state);
                    if (mapping.length) {
                        var otherVal = mapping[mapping.length - 1].textValue;
                        var otherColumnName = map.textColumnName;
                        var otherColumnFields = parent.settings.parameter.columns
                            .filter((col) => col.name == otherColumnName)
                            .map((col) => col.field);
                        item[otherColumnFields[0]] = otherVal;
                    }
                }
            }

            isValueChanged() {
                return this.$select.val() != this.defaultValue;
            }

            validate() {
                return {
                    valid: true,
                    msg: null,
                };
            }
        };
    }

    SelectCellPopupSelectEditor() {
        let parent = this;
        return class {
            public args: any;
            public $select;
            public defaultValue;

            constructor(args: any) {
                this.args = args;
                this.init();
            }

            init() {
                parent.editorActive = true;
                let that = this;

                var val = this.args.item[this.args.column.field] ? this.args.item[this.args.column.field] : "";
                var isUserGroup = this.args.column.options.select == "groupuser";
                var title = isUserGroup ? "Select user" : "Select user group / role";

                this.$select = $("<div style='width:100%;height:100%'>").appendTo(this.args.container);
                this.$select.click(function () {
                    // note changed this to  (to not break IE)
                    ml.UI.SelectUserOrGroup.showSingleSelectDialog(
                        val,
                        title,
                        "",
                        isUserGroup,
                        !isUserGroup,
                        function (selected) {
                            that.$select.html(ml.UI.SelectUserOrGroup.getGroupDisplayNameFromId(selected));
                            that.$select.data("selected", selected);
                            that.args.commitChanges(true);
                        },
                    );
                });
                this.$select.focus();
            }

            destroy() {
                parent.editorActive = false;
                this.$select.remove();
            }

            focus() {
                this.$select.focus();
            }

            loadValue(item) {
                this.defaultValue = item[this.args.column.field];
                this.$select.html(ml.UI.SelectUserOrGroup.getGroupDisplayNameFromId(this.defaultValue));
                this.$select.data("selected", this.defaultValue);
            }

            serializeValue() {
                return this.$select.data("selected");
            }

            applyValue(item, state) {
                item[this.args.column.field] = state;
            }

            isValueChanged() {
                return this.$select.data("selected") != this.defaultValue;
            }

            validate() {
                return {
                    valid: true,
                    msg: null,
                };
            }
        };
    }

    ItemRefEditor(typeList?: any, refOptions?: any) {
        let parent = this;
        return class {
            public args: any;
            public typeList: any;
            public refOptions: any;
            public tree;

            public selectDialog;
            public types: any; // same as typeList.
            public current = [];
            public previous = "";

            constructor(args: any) {
                this.args = args;

                this.typeList = typeList;
                if (!this.typeList) {
                    if (args.column.editorParam) {
                        this.typeList = args.column.editorParam;
                    }
                }
                this.types = this.typeList;

                this.refOptions = refOptions;
                if (!this.refOptions) {
                    // Default value.
                    this.refOptions = args.column.options;
                }
                this.init();
            }

            async saveSelection() {
                let that = this;
                this.current = [];
                (await this.tree.getController().getValueAsync()).forEach(function (val) {
                    that.current.push(val.to);
                });
            }

            showSelectDialog() {
                let that = this;

                // remove global highlight and show only matches in dlg after
                ml.Search.searchInDialog();

                var niceSize = ml.UI.getNiceDialogSize(500, 400);

                $("#selectItemDlg").html("");
                $("#selectItemDlg").removeClass("dlg-v-scroll");
                $("#selectItemDlg").addClass("dlg-no-scroll");

                this.tree = $("#selectItemDlg").projectView({
                    tree: app.getTree(this.types),
                    controlState: ControlState.DialogCreate,
                    canSelectItems: true,
                    selectedItems: [],
                    selectMode: this.refOptions && this.refOptions.singleSelect ? 3 : 1, // SelectMode.singleItem |  SelectMode.items
                    singleSelect: this.refOptions && this.refOptions.singleSelect,
                    expand: this.typeList.length > 2 ? 0 : 1,
                });

                $("#selectItemDlg")
                    .dialog({
                        autoOpen: true,
                        title: "Select Items",
                        height: niceSize.height,
                        width: niceSize.width,
                        modal: true,
                        close: function () {
                            // dlg is gone, remove highlights and back to global highlighting
                            ml.Search.endSearchInDialog();
                            that.args.commitChanges(true);
                            $(that.args.grid.getActiveCellNode()).highlightReferences(); // useful if user cancels dialog to show smart links
                            that.args.grid.resetActiveCell();
                        },
                        open: function () {},
                        resizeStop: function (event, ui) {
                            // @ts-ignore TODO: investigate what "this" should refer to
                            $("#selectItemDlg").resizeDlgContent([this.tree]);
                        },
                        buttons: [
                            {
                                text: "Select",
                                class: "btnDoIt",
                                click: async function () {
                                    await that.saveSelection();
                                    $(this).dialog("close");
                                },
                            },
                            {
                                text: "Cancel",
                                class: "btnCancelIt",
                                click: function () {
                                    $(this).dialog("close");
                                },
                            },
                        ],
                    })
                    .resizeDlgContent([this.tree], false);
            }

            init() {
                parent.editorActive = true;
                this.current = [];
                this.previous = "";
                this.showSelectDialog();
            }

            destroy() {
                parent.editorActive = false;
                this.selectDialog = null;
            }

            focus() {
                // $select.focus();
            }

            loadValue(item) {
                this.current = [];
                this.previous = "";
                if (item[this.args.column.field] && this.tree) {
                    this.previous = item[this.args.column.field];
                    this.current = this.previous.split(",");
                    this.tree.getController().setValue(this.current);
                }
            }

            serializeValue() {
                return this.current.join(",");
            }

            applyValue(item, state) {
                item[this.args.column.field] = state;
            }

            isValueChanged() {
                return this.previous !== this.serializeValue();
            }

            validate() {
                return {
                    valid: true,
                    msg: null,
                };
            }
        };
    }

    ItemECOEditor() {
        return this.ItemRefEditor(["ECO"]);
    }

    ItemDesignEditor() {
        var types = [];
        var tc = <ITraceConfig>globalMatrix.ItemConfig.getTraceConfig();
        if (tc) {
            tc.rules.forEach(function (r) {
                if (r.reporting.indexOf("Design") !== -1) {
                    types.push(r.category);
                }
            });
        }
        return this.ItemRefEditor(types);
    }
    ItemDownLinkEditor() {
        var req = globalMatrix.ItemConfig.getLinkTypes(this.settings.type, true, false);
        var opt = globalMatrix.ItemConfig.getLinkTypes(this.settings.type, true, true);
        return this.ItemRefEditor(req.concat(opt));
    }
    ItemUpLinkEditor() {
        var req = globalMatrix.ItemConfig.getLinkTypes(this.settings.type, false, false);
        var opt = globalMatrix.ItemConfig.getLinkTypes(this.settings.type, false, true);
        return this.ItemRefEditor(req.concat(opt));
    }
    ItemAnyLinkEditor() {
        return this.ItemRefEditor();
    }
    ItemECOCAPAEditor() {
        return this.ItemRefEditor(["ECO", "CAPA"]);
    }

    // formatters
    rowToolsFormatter(row, cell, value, columnDef, dataContext) {
        if (!this.lastSelectedRows || this.lastSelectedRows.indexOf(row) === -1) {
            return "";
        }
        var icons = "<span class='fal fa-times'></span> ";
        if (this.settings.parameter.create) {
            icons += "<span class='fal fa-arrow-up'></span>";
        }

        if (this.canImport) {
            icons += "<span class='fal fa-file-import'></span>";
        }

        return icons;
    }
    passFailFormatterIcon(value) {
        for (var idx = 0; idx < this.passFailOptions.length; idx++) {
            if (value === this.passFailOptions[idx].code) {
                if (this.passFailOptions[idx].image && this.passFailOptions[idx].image !== "") {
                    return (
                        '<span><img src="' +
                        globalMatrix.matrixBaseUrl +
                        "/img/" +
                        this.passFailOptions[idx].image +
                        '" /></span>'
                    );
                } else {
                    return "<span>" + this.passFailOptions[idx].code + "</span>";
                }
            }
        }

        return "<span></span>";
    }
    passFailFormatter(row, cell, value, columnDef, dataContext) {
        var icon = this.passFailFormatterIcon(value);
        var text = "";
        for (var idx = 0; idx < this.passFailOptions.length; idx++) {
            if (value === this.passFailOptions[idx].code) {
                text =
                    "<span class='test_step_" +
                    this.passFailOptions[idx].render +
                    "'>" +
                    this.passFailOptions[idx].human +
                    "</span>";
            }
        }

        return "<table><tr><td style='width:30px'>" + icon + "</td><td>" + text + "</td></tr></table>";
    }
    rowCounterFormatter(row, cell, value, columnDef, dataContext) {
        var disp = "";
        if (this.data[row]._refi) {
            disp +=
                "<span class='fal fa-retweet retweet-main' data-ref='" +
                this.data[row]._refi +
                "' title='included " +
                this.data[row]._refi +
                " step " +
                this.data[row]._refl +
                "'></span>";
        }
        disp += row + 1;

        return "<span>" + (this.settings.parameter.showLineNumbers ? disp : "") + "</span>";
    }
    multiLineFormatter(row, cell, value, columnDef, dataContext) {
        return this.formatText(value, true, columnDef.readOnly);
    }
    multiLineFormatterRich(row, cell, value, columnDef, dataContext) {
        var clean = value
            ? this.settings.controlState == ControlState.HistoryView
                ? value
                : ml.SmartText.replaceTextFragments(value, true)
            : "";
        return "<span class='multiLineFormatter' >" + clean + "</span>";
    }
    multiLineFormatterInteger(row, cell, value, columnDef, dataContext) {
        return "<span class='multiLineFormatter' >" + value + "</span>";
    }
    multiLinePlainFormatter(row, cell, value, columnDef, dataContext) {
        var clean = "";
        if (value) {
            value = value.replace(/</g, "&lt;");
            clean =
                this.settings.controlState == ControlState.HistoryView
                    ? value
                    : ml.SmartText.replaceTextFragments(value, true);
        }
        return "<span class='multiLineFormatter' >" + clean + "</span>";
    }
    colorFormatter(row, cell, value, columnDef, dataContext) {
        return `<div style="background:${value};width:100%;height:18px;border:solid 1px grey;margin:1px;"></div>`;
    }
    selectIconFormatter(row, cell, value, columnDef, dataContext) {
        var display = "";
        if (Array.isArray(columnDef.options)) {
            columnDef.options.forEach(function (opt) {
                if (opt.id == value) {
                    display = opt.label;
                }
            });
        } else if (value !== "undefined" && columnDef.options[value] !== "undefined" && columnDef.options[value]) {
            display = columnDef.options[value];
        }
        return `<span><i class='${value}'></i></span>`;
    }

    selectFormatter(row, cell, value, columnDef, dataContext) {
        var display = "";
        if (typeof columnDef.options == "string") {
            let select = $("<select>" + columnDef.options + "</select>");
            display = $("option[value='" + value + "']", select).text();
        } else if (Array.isArray(columnDef.options)) {
            columnDef.options.forEach(function (opt) {
                if (opt.id == value) {
                    display = opt.label;
                }
            });
        } else if (value !== "undefined" && columnDef.options[value] !== "undefined" && columnDef.options[value]) {
            display = columnDef.options[value];
        }
        return this.formatText(display, false, columnDef.readOnly);
    }

    selectCellPopupSelectFormatter(row, cell, value, columnDef, dataContext) {
        if (value) {
            var display = ml.UI.SelectUserOrGroup.getGroupDisplayNameFromId(value);
            return this.formatText(display, false, columnDef.readOnly);
        }
        return "";
    }

    itemRefFormatter(row, cell, value, columnDef, dataContext) {
        var items = typeof value !== "undefined" ? value : "";
        var showTitle = columnDef.options && columnDef.options.showTitle && items !== "" ? "!" : "";
        var il = items.split(",");
        var ret = $("<span class='reflistedit'>");
        if (columnDef.options && columnDef.options.hideLink) {
            ret.removeClass("reflistedit");
        }
        il.forEach(function (il) {
            var d = $("<div>" + il + showTitle + "</div>");
            ret.append(d);
        });
        return $("<span>").append(ret).html();
    }

    dateFormatter(row, cell, value, columnDef, dataContext) {
        return ml.UI.DateTime.renderHumanDate(new Date(value), true);
    }

    formatText(value, rich, readOnly) {
        if (value) {
            return rich
                ? "<span class='multiLineFormatter' >" + value + "</span>"
                : "<span class='multiLineFormatter'>" + value + "</span>";
        }

        if (!readOnly && this.settings.parameter.cellAskEdit) {
            return "<span class='cellAskEdit'>" + this.settings.parameter.cellAskEdit + "</span>";
        }

        return "<span></span>";
    }

    // grid manipulation
    deleteRows() {
        // special treatment for IE: MATRIX-411
        $(".tooltip").remove();

        this.rememberScroll();
        var rows = this.grid.getSelectedRows();
        rows.sort(function (a, b) {
            return b - a;
        });
        var data = this.grid.getData();
        for (var i = 0; i < rows.length; i++) {
            data.splice(rows[i], 1);
        }
        this.grid.setData(data);
        this.grid.render();
        // unfortunately need to render whole thing
        this.updateRowHeights();
        this.scrollBack();

        this.gridChanged();
        this.grid.setSelectedRows(rows);
    }
    autoPopulate(row) {
        let that = this;
        this.settings.parameter.columns.forEach(function (column) {
            if (!row[column.field]) {
                if (column.editor == ColumnEditor.date_today || column.editor == ColumnEditor.today) {
                    row[column.field] = ml.UI.DateTime.renderCustomerHumanDate(new Date(), true);
                } else if (column.editor == ColumnEditor.user_self || column.editor == ColumnEditor.self) {
                    row[column.field] = matrixSession.getUser();
                } else if (column.editor == ColumnEditor.number) {
                    row[column.field] = 0;
                } else if (column.editor == ColumnEditor.current_version) {
                    row[column.field] =
                        that.settings.item && that.settings.item.history && that.settings.item.history.length
                            ? that.settings.item.history[0].version
                            : "0";
                }
            }
        });
    }
    insertAbove() {
        // special treatment for IE: MATRIX-411
        $(".tooltip").remove();
        this.rememberScroll();

        var rows = this.grid.getSelectedRows();

        var insertAbove = rows[0];
        if (
            this.settings.parameter.maxRows !== -1 &&
            this.grid.getDataLength() + rows.length > this.settings.parameter.maxRows
        ) {
            ml.UI.showError("Warning", "Table can have only " + this.settings.parameter.maxRows + " rows");
            return;
        }

        rows.sort(function (a, b) {
            return a - b;
        });
        for (var idx = 0; idx < rows.length; idx++) {
            var newRow = {};
            this.autoPopulate(newRow);
            this.grid.getData().splice(insertAbove, 0, newRow);
        }
        this.grid.invalidate();
        this.grid.updateRowCount();
        this.grid.render();

        // unfortunately need to render whole thing
        this.updateRowHeights();
        this.scrollBack();

        this.gridChanged();
    }

    importAbove(append) {
        let that = this;

        $(".tooltip").remove();
        this.rememberScroll();

        var dlg = $("#importTableDlg");
        dlg.hide();
        dlg.html("");
        dlg.addClass("dlg-no-scroll");
        dlg.removeClass("dlg-v-scroll");
        var colIdxs = [];
        var colNames = [];
        this.settings.parameter.columns.forEach(function (col, idx) {
            if (col.editor !== "none") {
                colNames.push(col.name);
                colIdxs.push(idx);
            }
        });
        dlg.append("<div>Copy / paste table into the form below.The columns must be separated by |, e.g.</div>");
        dlg.append("<div>|" + colNames.join("|") + "|</div>");
        dlg.append("<div>Note: escape | in a cell by \\|</div>");
        var textarea = $("<textarea style='width:90%;resize:none;top:80px;bottom:10px; position: absolute;'>");
        dlg.append(textarea);

        // if something is selected copy it into text area for copy paste
        if (this.grid.getSelectedRows().length > 0) {
            var coldefs = this.settings.parameter.columns;
            var gdata = this.grid.getData();
            var select = "";
            var selrows = this.grid.getSelectedRows().sort(function (a, b) {
                return a - b;
            });
            selrows.forEach(function (selrow) {
                if (gdata[selrow]) {
                    select += "|";
                    coldefs.forEach(function (coldef) {
                        var col = gdata[selrow][coldef.field];
                        select += col ? col.replace(new RegExp("\\|", "g"), "\\\\|") : "";
                        select += "|";
                    });
                    select += "\n";
                }
            });
            textarea.val(select);
        }

        dlg.dialog({
            autoOpen: true,
            title: "import / export table content",
            width: 600,
            height: 500,
            modal: true,
            resizeStop: function () {},
            open: function () {
                ml.UI.pushDialog(dlg);
            },
            close: function () {
                ml.UI.popDialog(dlg);
            },
            buttons: [
                {
                    text: "Ok",
                    class: "btnDoIt",
                    click: function () {
                        var tabletext = textarea.val();
                        if (tabletext.length > 0) {
                            tabletext = tabletext.split("\\|").join("escapedpipereplacement");

                            var cells = tabletext.split("|");
                            if (cells.length % (colIdxs.length + 1) !== 1) {
                                ml.UI.showError("Warning", "Incorrect number of cells");
                                return;
                            }
                            var rowCount = (cells.length - 1) / (colIdxs.length + 1);

                            var insertAbove = append ? that.grid.getDataLength() : that.grid.getSelectedRows()[0];
                            if (
                                that.settings.parameter.maxRows !== -1 &&
                                that.grid.getDataLength() + rowCount > that.settings.parameter.maxRows
                            ) {
                                ml.UI.showError(
                                    "Warning",
                                    "Table can have only " + that.settings.parameter.maxRows + " rows",
                                );
                                return;
                            }

                            var nextCell = 0;
                            for (var row = 0; row < rowCount; row++) {
                                var newRow = {};
                                nextCell++;
                                for (var col = 0; col < colIdxs.length; col++) {
                                    newRow[that.settings.parameter.columns[colIdxs[col]].field] = cells[
                                        nextCell++
                                    ].replace(new RegExp("escapedpipereplacement", "g"), "|");
                                }
                                that.autoPopulate(newRow);
                                that.grid.getData().splice(insertAbove++, 0, newRow);
                            }
                            that.grid.invalidate();
                            that.grid.updateRowCount();
                            that.grid.render();

                            // unfortunately need to render whole thing
                            that.updateRowHeights();
                            that.scrollBack();

                            that.gridChanged();
                            $(this).dialog("close");
                        }
                    },
                },
                {
                    text: "Cancel",
                    class: "btnCancelIt",
                    click: function () {
                        $(this).dialog("close");
                    },
                },
            ],
        });
    }
    updateRowHeights(fromRow?, toRow?) {
        if (this.settings.parameter.manualTableHeights) {
            return; // nothing to do
        }

        // calculate real rendered heights of cells and adjust heights accordingly

        // in some popups like history, this did not work, probably because the div node got moved

        if (!this._list.prop("class")) return; // avoid error log - can happen if user selects quickly multiple items with table because of delayed rendering of tooltip
        // get the unqiue classes of the control
        var classes = "." + this._list.prop("class").split(" ").join(".");
        // replace with moved
        this._list = $(classes);

        // prepare data
        var uhrCols = this.grid.getColumns();
        // handle nested tables
        var uhrRows = this._list.find(".grid-canvas").first().children(".slick-row");
        fromRow = fromRow ? fromRow : 0;
        toRow = toRow ? toRow : uhrRows.length;

        // prepare shadow copy
        var cellSizers = [];
        for (var cidx = 0; cidx < uhrCols.length; cidx++) {
            var func = uhrCols[cidx].formatter;

            // setup hidden render areas
            if (func) {
                const found = this.formattersRequiringSizers.find((f) => {
                    return f == func;
                });
                if (found) {
                    var cellSizer = $("<div class='controlContainer'>");
                    var width = Math.max(10, uhrCols[cidx].width - 10);
                    $("body").append(
                        cellSizer
                            .hide()
                            .width(width)
                            .css("left", "-" + width + "px"),
                    );
                    cellSizers.push({ colIdx: cidx, cellSizer: cellSizer });
                }
            }
        }

        // create shadow copy
        for (var uhrIdx = fromRow; uhrIdx < toRow; uhrIdx++) {
            var cells = uhrRows[uhrIdx].children;
            for (var cidx = 0; cidx < cellSizers.length; cidx++) {
                var idx = cellSizers[cidx].colIdx;
                var spacer = $("<div class='multiLineFormatter'>");
                cellSizers[cidx].cellSizer.append(spacer);
                if (cells[idx] && cells[idx].children[0]) {
                    // sometimes table cells are hidden
                    spacer.html(cells[idx].children[0].innerHTML);
                }
            }
        }

        for (var cidx = 0; cidx < cellSizers.length; cidx++) {
            cellSizers[cidx].cellSizer.show();
        }

        // hide the table (for performance)
        this._list.hide();

        // calculate max heights and set it to all new rows
        for (var uhrIdx = fromRow; uhrIdx < toRow; uhrIdx++) {
            var maxRowHeight = 25; // minimum...
            for (var cidx = 0; cidx < cellSizers.length; cidx++) {
                maxRowHeight = Math.max(
                    maxRowHeight,
                    cellSizers[cidx].cellSizer.children()[uhrIdx - fromRow].offsetHeight,
                );
            }

            var cells = uhrRows[uhrIdx].children;
            for (var cidx = 0; cidx < cellSizers.length; cidx++) {
                var idx = cellSizers[cidx].colIdx;
                if (cells[idx] && cells[idx].children[0]) {
                    $(cells[idx].children[0]).css("height", maxRowHeight + "px");
                }
            }

            for (var cidx = 0; cidx < cells.length; cidx++) {
                $(cells[cidx]).css("height", maxRowHeight + "px");
            }
            $(uhrRows[uhrIdx]).css("height", maxRowHeight + "px");
        }

        // calculate and set top

        var top = 0;
        if (fromRow > 0) {
            fromRow--;
            top = Number(uhrRows[fromRow].style.top.replace("px", ""));
        }
        for (var uhrIdx = fromRow; uhrIdx < uhrRows.length; uhrIdx++) {
            $(uhrRows[uhrIdx]).css("top", top + "px");
            top += $(uhrRows[uhrIdx]).height();
        }

        // set control height
        this._list.css("height", top + 50 + "px");
        $(".slick-viewport", this._list).css("height", "100%");

        // render hyperlinks
        if (this._root.highlightReferences && this.settings.controlState != ControlState.HistoryView) {
            this._root.highlightReferences();
            let searchFilter = ml.Search.getFilter();
            if (searchFilter) {
                // (re-)apply it
                this._root.highlight(searchFilter);
            }
            ml.Search.renderHighlight();
        }

        // show table again
        this._list.show();

        // remove $s (for print)
        for (var cidx = 0; cidx < cellSizers.length; cidx++) {
            cellSizers[cidx].cellSizer.remove();
        }

        if (this.settings.parameter.updateParent) this.settings.parameter.updateParent();
    }
    // misc $ functions
    gridChanged() {
        let that = this;
        // callback called when grid changes

        if (this.settings.parameter.autoUpdate) {
            let data = this.grid.getData();
            let before = JSON.stringify(data);
            let error = tableMath.execute(
                this.settings.parameter.autoUpdate,
                data,
                <ITableParams>this.settings.parameter,
            );
            if (error) {
                ml.UI.showError("Cannot execute formula", error);
            } else {
                if (JSON.stringify(data) != before) {
                    this.rememberScroll();
                    this.grid.setData(data);
                    this.grid.render();
                    this.updateRowHeights(0, 0);
                    this.scrollBack();
                }
            }
        }
        this._root.data("new", this.grid.getData()); // required for d&d

        if (this.settings.valueChanged) {
            // valueChanged will trigger a needs save event,
            // this will trigger a redraw (as a timeout) which should be ignored
            this.ignoreResize = true;
            window.clearTimeout(this.ignoreResizeReset);
            this.ignoreResizeReset = window.setTimeout(function () {
                that.ignoreResize = false;
            }, 1000); // the other timeout is 500 ms
            // trigger event
            this.settings.valueChanged.apply(null);
        }
    }
    rememberScroll() {
        // function to remember scroll position of page
        this.vpp = this.vp.scrollTop();
    }
    scrollBack() {
        // function to restore scroll position of page
        this.vp.scrollTop(this.vpp);
    }

    /*
        {
            "options":[
                {"id":"todo","label":"To Do","class":"todo","sId":1},
                {"id":"fail","label":"TRES KAPUTT","class":"failed","sId":2},
                {"id":"pass","label":"Passed","class":"passed","sId":3},
                {"id":"Paaassse","label":"Passses","class":"passed","sId":4}
            ],
            "groups":[
                {"value":"passed","label":"passed"},
                {"value":"failed","label":"failed"},
                {"value":"todo","label":"todo"}
            ]
        }
    */
    getSelectColOptions(options) {
        if (options && options.setting) {
            var dds = <ISelectColOptions>globalMatrix.ItemConfig.getSettingJSON(options.setting);
            if (dds && dds.groups && dds.groups.length) {
                // create all the groups the class of the group is it's id
                let select = $(
                    `<select>${dds.groups
                        .map((g) => {
                            return "<optgroup label='" + g.label + "' class='" + g.value + "'>";
                        })
                        .join("\n")}</select>`,
                );

                dds.options.forEach((opt) => {
                    if (opt.class == undefined) {
                        select.append(
                            $(`<option value="${opt.id}" ${opt.disabled ? "disabled" : ""} >${opt.label}</option>`),
                        );
                    } else {
                        $("." + opt.class, select).append(
                            $(`<option value="${opt.id}"  ${opt.disabled ? "disabled" : ""} >${opt.label}</option>`),
                        );
                    }
                });

                return select.html();
            }
            if (dds && dds.options) {
                var m = {};
                dds.options.forEach(function (ddo) {
                    m[ddo.id] = ddo.label;
                });
                return m;
            }
        }
        return options;
    }

    initControl() {
        let that = this;

        // We just want one copy of each formatter and editor. We have to use bind because
        // otherwise the "this" pointer in these functions will be undefined.
        const passFailFormatter = this.passFailFormatter.bind(this);
        const rowCounterFormatter = this.rowCounterFormatter.bind(this);
        const itemRefFormatter = this.itemRefFormatter.bind(this);
        const multiLineFormatter = this.multiLineFormatter.bind(this);
        const multiLinePlainFormatter = this.multiLinePlainFormatter.bind(this); // escape html
        const multiLineFormatterInteger = this.multiLineFormatterInteger.bind(this);
        const multiLineFormatterRich = this.multiLineFormatterRich.bind(this);
        const selectFormatter = this.selectFormatter.bind(this);
        const selectIconFormatter = this.selectIconFormatter.bind(this);
        const selectCellPopupSelectFormatter = this.selectCellPopupSelectFormatter.bind(this);
        const rowToolsFormatter = this.rowToolsFormatter.bind(this);
        const colorFormatter = this.colorFormatter.bind(this); // escape html
        const dateFormatter = this.dateFormatter.bind(this);

        // Editors are implemented as anonymous classes. They don't use bind, instead a factory
        // method returns the class configured with a parent pointer.
        const passFailEditor = this.PassFailEditor();
        const itemDesignEditor = this.ItemDesignEditor();
        const itemUpLinkEditor = this.ItemUpLinkEditor();
        const itemDownLinkEditor = this.ItemDownLinkEditor();
        const itemAnyLinkEditor = this.ItemAnyLinkEditor();
        const inplaceLongText = this.InplaceLongText();
        const commentlogEditor = this.CommentlogEditor();
        const slickEditorsDate = Slick.Editors.Date;
        const slickEditorsInteger = Slick.Editors.Integer;
        const selectCellEditor = this.SelectCellEditor();
        const selectCellPopupSelectEditor = this.SelectCellPopupSelectEditor();
        const textEditorSafe = this.TextEditorSafe();
        const colorEditor = this.ColorEditor();
        const itemECOEditor = this.ItemECOEditor();
        const itemECOCAPAEditor = this.ItemECOCAPAEditor();

        this.formattersRequiringSizers = [
            selectFormatter,
            multiLinePlainFormatter,
            itemRefFormatter,
            multiLineFormatter,
            multiLineFormatterRich,
            itemRefFormatter,
        ];

        if (this.settings.fieldHandler) {
            this.data = JSON.parse(this.settings.fieldHandler.getData());
        } else if (this.settings.dummyData) {
            for (var idx = 0; idx < 500; idx++) {
                var row = {};
                for (var column in this.settings.parameter.columns) {
                    if (this.settings.parameter.columns[column].editor === ColumnEditor.text) {
                        row[this.settings.parameter.columns[column].field] =
                            this.settings.parameter.columns[column].name + " " + idx;
                    }
                }
                this.data.push(row);
            }
        } else if (this.settings.parameter.initialContent) {
            this.data = ml.JSON.clone(this.settings.parameter.initialContent);
        }
        if (this.settings.parameter.fixRows > 0) {
            while (this.data.length < this.settings.parameter.fixRows) {
                var newRow = {};

                this.autoPopulate(newRow);
                this.data.push(newRow);
            }
            if (this.data.length > this.settings.parameter.fixRows) {
                this.data.splice(this.settings.parameter.fixRows, this.data.length - this.settings.parameter.fixRows);
            }
        }
        this._root.append(this.createHelp(this.settings));
        if (this.canImport) {
            var ci = $("<span class='fal fa-file-import' style='margin-left:10px; color:grey'>");
            this._root.append(ci);
            ci.click(function () {
                that.importAbove(true);
            }).tooltip({ container: "body", title: "export selection / import and append" });
        }
        this._root.append(this.ctrlContainer);
        this.ctrlContainer.append(this._list);
        this.vp = this._list.closest(".panel-body-v-scroll");
        // add column with line number
        var hasInclude = false;
        for (var idx = 0; idx < this.data.length && !hasInclude; idx++) {
            hasInclude = !!this.data[idx]._refi;
        }
        var lineNumberWidth =
            (hasInclude ? 50 : 40) + (this.data.length > 90 ? 10 : 0) + (this.data.length > 900 ? 10 : 0);
        if (this.settings.canEdit && ml.JSON.isTrue(this.settings.parameter.canBeModified)) {
            this.columns.push({
                id: "#",
                name: "",
                width: lineNumberWidth,
                behavior: "selectAndMove",
                selectable: false,
                resizable: false,
                cssClass: "cell-reorder dnd",
                formatter: rowCounterFormatter,
            });
        } else {
            this.columns.push({
                id: "#",
                name: "",
                width: lineNumberWidth,
                selectable: false,
                resizable: false,
                formatter: rowCounterFormatter,
            });
        }

        // add data columns
        if (!this.settings.parameter.columns) {
            this.settings.parameter.columns = [];
            ml.Logger.log("error", "missing definition of table columns for use/test case");
        }
        for (var idx = 0; idx < this.settings.parameter.columns.length; idx++) {
            var colDef = this.settings.parameter.columns[idx];
            var col = {
                headerCssClass: undefined,
                editor: undefined,
                options: undefined,
                editorParam: undefined,
                readOnly: undefined,

                id: colDef.field,
                name: colDef.name,
                field: colDef.field,
                width: colDef.relativeWidth ? colDef.relativeWidth : 350,
                cssClass: "cell-title" + (colDef.cssClass ? " " + colDef.cssClass : ""),
                formatter: multiLineFormatter, // use this as default
            };
            if (colDef.headerCssClass) {
                col.headerCssClass = colDef.headerCssClass;
            }
            // TODO: DRY it up
            if (
                this.settings.canEdit &&
                (!this.settings.parameter.readOnlyFields ||
                    this.settings.parameter.readOnlyFields.indexOf(colDef.field) == -1)
            ) {
                this.focsuable[idx + 1] = { focusable: true };
                if (colDef.editor === ColumnEditor.text) {
                    col.editor = inplaceLongText;
                    col.formatter = multiLineFormatterRich;
                } else if (colDef.editor === ColumnEditor.commentlog) {
                    col.editor = commentlogEditor;
                    col.formatter = multiLineFormatter;
                    col.options = colDef.options;
                } else if (colDef.editor === ColumnEditor.date || colDef.editor === ColumnEditor.date_today) {
                    col.editor = slickEditorsDate;
                    col.formatter = dateFormatter;
                } else if (colDef.editor === ColumnEditor.today) {
                    col.formatter = dateFormatter;
                } else if (colDef.editor === ColumnEditor.current_version) {
                } else if (colDef.editor === ColumnEditor.select) {
                    col.options = this.getSelectColOptions(colDef.options);
                    col.editor = selectCellEditor;
                    col.formatter = selectFormatter;
                } else if (colDef.editor === ColumnEditor.selectIcon) {
                    col.options = this.getSelectColOptions(colDef.options);
                    col.editor = selectCellEditor;
                    col.formatter = selectIconFormatter;
                } else if (colDef.editor === ColumnEditor.versionletter) {
                    col.options = {
                        A: "A",
                        B: "B",
                        C: "C",
                        D: "D",
                        E: "E",
                        F: "F",
                        G: "G",
                        H: "H",
                        I: "I",
                        J: "J",
                        K: "K",
                        L: "L",
                        M: "M",
                        N: "N",
                        O: "O",
                        P: "P",
                        Q: "Q",
                        R: "R",
                        S: "S",
                        T: "T",
                        U: "U",
                        V: "V",
                        W: "W",
                        X: "X",
                        Y: "Y",
                        Z: "Z",
                    };
                    col.editor = selectCellEditor;
                    col.formatter = selectFormatter;
                } else if (colDef.editor === ColumnEditor.signaturemeaning) {
                    col.options = {
                        Author: "Author",
                        Reviewer: "Reviewer",
                        Approver: "Approver",
                        "Written By": "Written By",
                        "Reviewed By": "Reviewed By",
                        "Approved By": "Approved By",
                    };
                    if (mDHF && mDHF.getSignatureMeanings()) {
                        col.options = mDHF.getSignatureMeanings();
                    }
                    col.editor = selectCellEditor;
                    col.formatter = selectFormatter;
                } else if (colDef.editor === ColumnEditor.user || colDef.editor === ColumnEditor.user_self) {
                    col.options = { select: "groupuser" };
                    col.editor = selectCellPopupSelectEditor;
                    col.formatter = selectCellPopupSelectFormatter;
                } else if (colDef.editor === ColumnEditor.self) {
                    // self means a user id, and this formatter has support for deleted usernames.
                    col.formatter = selectCellPopupSelectFormatter;
                } else if (colDef.editor === ColumnEditor.group) {
                    col.options = { select: "group" };
                    col.editor = selectCellPopupSelectEditor;
                    col.formatter = selectCellPopupSelectFormatter;
                } else if (colDef.editor === ColumnEditor.textline || colDef.editor === ColumnEditor.revision) {
                    col.editor = textEditorSafe;
                    col.formatter = multiLinePlainFormatter; // escape html
                } else if (colDef.editor === ColumnEditor.colorPicker) {
                    col.editor = colorEditor;
                    col.formatter = colorFormatter;
                } else if (colDef.editor === ColumnEditor.number) {
                    col.editor = slickEditorsInteger;
                    col.formatter = multiLineFormatterInteger;
                } else if (colDef.editor === ColumnEditor.result) {
                    col.formatter = passFailFormatter;
                    col.editor = passFailEditor;
                } else if (colDef.editor === ColumnEditor.design) {
                    col.formatter = itemRefFormatter;
                    col.editor = itemDesignEditor;
                    col.options = colDef.options;
                } else if (colDef.editor === ColumnEditor.uprules) {
                    col.formatter = itemRefFormatter;
                    col.editor = itemUpLinkEditor;
                    col.options = colDef.options;
                } else if (colDef.editor === ColumnEditor.downrules) {
                    col.formatter = itemRefFormatter;
                    col.editor = itemDownLinkEditor;
                    col.options = colDef.options;
                    // convert to string here as it's a special case that is not part of the enum
                } else if (colDef.editor && (<string>colDef.editor).indexOf("rules:") === 0) {
                    col.formatter = itemRefFormatter;
                    col.editor = itemAnyLinkEditor;
                    col.editorParam = colDef.editor.substring(6);
                    col.options = colDef.options;
                    // convert to string here as it's a special case that is not part of the enum
                } else if (colDef.editor && (<string>colDef.editor).indexOf("category") === 0) {
                    col.formatter = itemRefFormatter;
                    col.editor = itemAnyLinkEditor;
                    col.editorParam = colDef.options.categories;
                    col.options = colDef.options;
                } else if (colDef.editor === ColumnEditor.eco) {
                    col.formatter = itemRefFormatter;
                    col.editor = itemECOEditor;
                } else if (colDef.editor === ColumnEditor.ecocapa) {
                    col.formatter = itemRefFormatter;
                    col.editor = itemECOCAPAEditor;
                } else {
                    this.focsuable[idx + 1] = { focusable: this.settings.parameter.readonly_allowfocus };
                }
            } else {
                col.readOnly = true;
                this.focsuable[idx + 1] = { focusable: this.settings.parameter.readonly_allowfocus };
                if (colDef.editor === ColumnEditor.text) {
                    col.formatter = multiLineFormatterRich;
                } else if (colDef.editor === ColumnEditor.commentlog) {
                    col.formatter = multiLineFormatter;
                } else if (colDef.editor === ColumnEditor.eco) {
                    col.formatter = itemRefFormatter;
                } else if (colDef.editor === ColumnEditor.ecocapa) {
                    col.formatter = itemRefFormatter;
                } else if (colDef.editor === ColumnEditor.result) {
                    col.formatter = passFailFormatter;
                } else if (colDef.editor === ColumnEditor.select) {
                    col.options = this.getSelectColOptions(colDef.options);
                    col.editor = selectCellEditor;
                    col.formatter = selectFormatter;
                } else if (colDef.editor === ColumnEditor.signaturemeaning) {
                    col.options = {
                        Author: "Author",
                        Reviewer: "Reviewer",
                        Approver: "Approver",
                        "Written By": "Written By",
                        "Reviewed By": "Reviewed By",
                        "Approved By": "Approved By",
                    };
                    if (mDHF && mDHF.getSignatureMeanings()) {
                        col.options = mDHF.getSignatureMeanings();
                    }
                    col.editor = selectCellEditor;
                    col.formatter = selectFormatter;
                } else if (colDef.editor === ColumnEditor.design) {
                    col.formatter = itemRefFormatter;
                    col.options = colDef.options;
                } else if (colDef.editor === ColumnEditor.uprules) {
                    col.formatter = itemRefFormatter;
                    col.editor = itemUpLinkEditor;
                    col.options = colDef.options;
                } else if (colDef.editor === ColumnEditor.downrules) {
                    col.formatter = itemRefFormatter;
                    col.options = colDef.options;
                } else if (colDef.editor === ColumnEditor.group) {
                    col.options = { select: "group" };
                    col.formatter = selectCellPopupSelectFormatter;
                } else if (colDef.editor && (<string>colDef.editor).indexOf("rules:") === 0) {
                    col.formatter = itemRefFormatter;
                    col.options = colDef.options;
                } else if (colDef.editor && (<string>colDef.editor).indexOf("category") === 0) {
                    col.formatter = itemRefFormatter;
                    col.options = colDef.options;
                }
            }
            this.columns.push(col);
        }
        // add row add/delete columns
        if (
            this.settings.canEdit &&
            ml.JSON.isTrue(this.settings.parameter.canBeModified) &&
            this.settings.parameter.fixRows <= 0
        ) {
            this.columns.push({
                id: "tools",
                name: "",
                width: 60,
                resizable: false,
                cssClass: "cell-effort-driven",
                cannotTriggerInsert: true,
                formatter: rowToolsFormatter,
            });
            this.rowToolsColumn = this.columns.length - 1;
            this.focsuable[this.columns.length - 1] = { focusable: this.settings.parameter.readonly_allowfocus };
        }

        // configure and create grid
        this.data.getItemMetadata = function (row) {
            return {
                columns: that.focsuable,
            };
        };
        var grid_options = {
            enableColumnReorder: undefined,

            editable: this.settings.canEdit,
            enableAddRow:
                this.settings.canEdit &&
                ml.JSON.isTrue(this.settings.parameter.canBeModified) &&
                this.settings.parameter.fixRows <= 0,
            forceFitColumns: true,
            asyncEditorLoading: false,
            autoHeight: true,
            enableCellNavigation: true,
            autoEdit: true,
            enableTextSelectionOnCells: true,
        };

        if (this.settings.parameter.disableColumnReorder) {
            grid_options.enableColumnReorder = false;
        }
        this.grid = new Slick.Grid(this._list, this.data, this.columns, grid_options);

        // MATRIX-2027 the date picker control, steals the focus of the input cell underneath... so if the datepicker is still open don't do this
        // painful need to wait until datepicker is hidden....
        let waitForDatePickerClose = function () {
            if ($(".datepicker:visible").length) {
                window.setTimeout(function () {
                    waitForDatePickerClose();
                }, 100);
            } else {
                Slick.GlobalEditorLock.commitCurrentEdit();
                that.grid.resetActiveCell();
            }
        };

        $(".slick-viewport", this._list).on("blur", "input.editor-text", function (e) {
            var blurred = that.grid.getActiveCell();
            window.setTimeout(function () {
                var current = that.grid.getActiveCell();
                // if another editor was activated - no need to do an explicit commit
                if (
                    current &&
                    blurred.row == current.row &&
                    blurred.cell == current.cell &&
                    !that.editorActive &&
                    $(":focus").closest(".multiLineEditorContainer").length == 0
                ) {
                    // MATRIX-2027 the date picker control, steals the focus of the input cell underneath... so if the datepicker is still open don't do this
                    if ($(".datepicker:visible").length) {
                        waitForDatePickerClose();
                    } else {
                        Slick.GlobalEditorLock.commitCurrentEdit();
                        that.grid.resetActiveCell();
                    }
                }
            }, 100);
        });

        if (!this.settings.canEdit || !ml.JSON.isTrue(this.settings.parameter.canBeModified)) {
            $($(".grid-canvas", this._list)[0]).off("mousedown");
        }

        this.grid.onBeforeEditCell.subscribe(function (e, args) {
            if (
                !that.settings.parameter.limitEditRow ||
                (that.settings.parameter.limitEditRow === "last" && args.row === that.grid.getDataLength() - 1) ||
                (that.settings.parameter.limitEditRow === "first" && args.row === 0)
            ) {
                return true;
            } else {
                return false;
            }
        });

        // fix columns widths
        var availableWidth = 0;
        var lastWidth = 0;
        var cols = this.grid.getColumns();
        cols.forEach(function (col, cidx) {
            if (col.resizable) {
                var lastColumnWidth = Number(
                    globalMatrix.projectStorage.getItem("colWidth_" + that.settings.fieldId + "_" + cidx),
                );
                if (lastColumnWidth) {
                    availableWidth += col.width;
                    lastWidth += lastColumnWidth;
                }
            }
        });
        if (lastWidth && availableWidth) {
            cols.forEach(function (col, cidx) {
                if (col.resizable) {
                    var lastColumnWidth = Number(
                        globalMatrix.projectStorage.getItem("colWidth_" + that.settings.fieldId + "_" + cidx),
                    );
                    var newWidth = ((lastColumnWidth ? lastColumnWidth : 100) * availableWidth) / lastWidth;
                    col.width = newWidth;
                }
            });
        }
        this.grid.resizeCanvas();

        // set up double click behaviour (calling external function)
        if (this.settings.onDblClick) {
            this.grid.onDblClick.subscribe(function (e, args) {
                var cell = that.grid.getCellFromEvent(e);
                that.settings.onDblClick(cell.row, cell.cell, that.data[cell.row]);
            });
        }
        if (this.settings.onSelectCell) {
            that.grid.onClick.subscribe(function (e, args) {
                var cell = that.grid.getCellFromEvent(e);
                that.settings.onSelectCell(cell.row, cell.cell, that.data[cell.row]);
            });
        }

        // configure print / readonly view
        if (
            this.settings.controlState === ControlState.Print ||
            this.settings.controlState === ControlState.Tooltip ||
            this.settings.controlState === ControlState.HistoryView
        ) {
            this.settings.canEdit = false;
        }
        if (this.settings.controlState === ControlState.Print) {
            this._list.addClass("printNoBox");
            this._list.find(".slick-viewport").css("overflow-x", "hidden");
        }

        // attach event handlers
        this.grid.onSelectedRowsChanged.subscribe(function (e, args) {
            that.lastSelectedRows = args.rows;
            if (that.rowToolsColumn) {
                for (var idx = 0; idx < that.grid.getDataLength(); idx++) {
                    that.grid.updateCell(idx, that.rowToolsColumn);
                }
            }
            if (that.settings.parameter.create) {
                $(".fa-arrow-up", that._list)
                    .click(that.insertAbove.bind(that))
                    .tooltip({ container: "body", title: "insert above" });
                $(".fa-file-import", that._list)
                    .click(function () {
                        that.importAbove(false);
                    })
                    .tooltip({ container: "body", title: "export selection / import above" });
                $(".fa-retweet", that._list)
                    .click(function () {})
                    .tooltip({ container: "body" });
            }
            $(".fa-times", that._list)
                .click(that.deleteRows.bind(that))
                .tooltip({ container: "body", title: "delete row" });
        });

        this.grid.onRendered.subscribe(function (e, args) {
            if (
                that.settings.canEdit &&
                ml.JSON.isTrue(that.settings.parameter.canBeModified) &&
                that.settings.parameter.fixRows <= 0
            ) {
                var lastRow = $($(".slick-row", that._list)[$(".slick-row", that._list).length - 1]);
                that.settings.parameter.columns.forEach(function (column, cidx) {
                    var text = "";
                    var css = "autoCell";
                    if (column.editor === ColumnEditor.self) {
                        text = matrixSession.getUser();
                    } else if (column.editor === ColumnEditor.user_self) {
                        css = "autoCellEdit";
                        text = matrixSession.getUser();
                    } else if (column.editor === ColumnEditor.today) {
                        text = ml.UI.DateTime.renderHumanDate(new Date(), true);
                    } else if (column.editor === ColumnEditor.date_today) {
                        css = "autoCellEdit";
                        text = ml.UI.DateTime.renderHumanDate(new Date(), true);
                    } else if (column.editor === ColumnEditor.current_version) {
                        text =
                            that.settings.item && that.settings.item.history && that.settings.item.history.length
                                ? that.settings.item.history[0].version.toString()
                                : "0";
                    }
                    if (text) {
                        $(".r" + (cidx + 1), lastRow)
                            .html("")
                            .append($("<span class='" + css + "'>").html(text));
                    }
                });
            }
        });

        this.grid.setSelectionModel(new Slick.RowSelectionModel());

        var moveRowsPlugin = new Slick.RowMoveManager({
            cancelEditOnDrag: true,
        });

        moveRowsPlugin.onBeforeMoveRows.subscribe(function (e, data) {
            for (var i = 0; i < data.rows.length; i++) {
                // no point in moving before or after itself
                if (data.rows[i] === data.insertBefore || data.rows[i] === data.insertBefore - 1) {
                    e.stopPropagation();
                    return false;
                }
            }
            return true;
        });

        moveRowsPlugin.onMoveRows.subscribe(function (e, args) {
            that.rememberScroll();
            var extractedRows = [],
                left,
                right;
            var rows = args.rows;
            var insertBefore = args.insertBefore;
            left = that.data.slice(0, insertBefore);
            right = that.data.slice(insertBefore, that.data.length);
            rows.sort(function (a, b) {
                return a - b;
            });

            for (var i = 0; i < rows.length; i++) {
                extractedRows.push(that.data[rows[i]]);
            }

            rows.reverse();
            for (var i = 0; i < rows.length; i++) {
                var row = rows[i];
                if (row < insertBefore) {
                    left.splice(row, 1);
                } else {
                    right.splice(row - insertBefore, 1);
                }
            }

            that.data = left.concat(extractedRows.concat(right));
            var selectedRows = [];
            for (var i = 0; i < rows.length; i++) selectedRows.push(left.length + i);
            that.grid.resetActiveCell();
            that.grid.setData(that.data);
            that.grid.setSelectedRows(selectedRows);
            that.grid.render();
            that.gridChanged();

            that.updateRowHeights(0, 0);
            that.scrollBack();
        });

        this.grid.registerPlugin(moveRowsPlugin);

        this.grid.onDragInit.subscribe(function (e, dd) {
            // prevent the grid from cancelling drag'n'drop by default
            e.stopImmediatePropagation();
        });

        this.grid.onDragStart.subscribe(function (e, dd) {
            var cell = that.grid.getCellFromEvent(e);
            if (!cell) {
                return;
            }

            dd.row = cell.row;
            if (!that.data[dd.row]) {
                return;
            }

            if (Slick.GlobalEditorLock.isActive(undefined)) {
                return;
            }

            e.stopImmediatePropagation();
            dd.mode = "recycle";
            var selectedRows = that.grid.getSelectedRows();
            if (!selectedRows.length || $.inArray(dd.row, selectedRows) === -1) {
                selectedRows = [dd.row];
                that.grid.setSelectedRows(selectedRows);
            }

            dd.rows = selectedRows;
            dd.count = selectedRows.length;
            return "";
        });

        this.grid.onDrag.subscribe(function (e, dd) {
            if (dd.mode !== "recycle") {
                return;
            }
        });

        this.grid.onDragEnd.subscribe(function (e, dd) {
            if (dd.mode !== "recycle") {
                return;
            }
        });

        (<any>$).drop({ mode: "mouse" });
        this.grid.onAddNewRow.subscribe(function (e, args) {
            if (
                that.settings.parameter.maxRows !== -1 &&
                that.grid.getDataLength() >= that.settings.parameter.maxRows
            ) {
                ml.UI.showError("Warning", "Table can have only " + that.settings.parameter.maxRows + " rows");
                return;
            }
            that.rememberScroll();
            var item = {};
            $.extend(item, args.item);
            that.autoPopulate(item);
            that.data.push(item);
            that.grid.invalidateRows([that.data.length - 1]);
            that.grid.updateRowCount();
            that.grid.render();
            that.gridChanged();
            that.updateRowHeights(that.data.length - 1);
            that.scrollBack();
        });

        this.grid.onCellChange.subscribe(function (e, args) {
            that.gridChanged();
            if (args.row != "undefined") {
                that.rememberScroll();
                if (that.settings.parameter.onCellChanged) {
                    that.settings.parameter.onCellChanged(args);
                }

                ml.SmartText.showTooltips($(args.grid.getActiveCellNode()), false);
                that.updateRowHeights(args.row, args.row + 1);
                that.scrollBack();
            }
        });

        this.grid.onColumnsResized.subscribe(function (e, args) {
            var cols = that.grid.getColumns();
            if (that.settings.parameter.onColumnsResized) that.settings.parameter.onColumnsResized();
            if (that.settings.parameter.doNotRememberWidth) return;

            cols.forEach(function (col, cidx) {
                globalMatrix.projectStorage.setItem("colWidth_" + that.settings.fieldId + "_" + cidx, col.width);
            });
        });

        if (this.settings.controlState === ControlState.Print) {
            // TODO(modules): this conversion is suspect.
            this._root.resizeItem(<number>(<unknown>"18cm"), true);
        }

        this._list.keyup(function (event) {
            that.copyPaste(event);
        });
    }

    saveData() {
        // must be called after initControl.
        var original = ml.JSON.clone(this.grid.getData());
        this._root.data("original", original);
        this._root.data("new", this.grid.getData());
    }

    copyPaste(event) {
        let that = this;
        if (globalMatrix.globalCtrlDown) {
            var rows = this.grid.getSelectedRows();
            var activeCell = this.grid.getActiveCell();

            if (rows.length > 1 || (rows.length == 1 && activeCell && activeCell.cell == 0)) {
                if (event.keyCode == 67) {
                    // ctrl-c
                    var gdata = this.grid.getData();
                    var select = [];
                    var selrows = this.grid.getSelectedRows().sort(function (a, b) {
                        return a - b;
                    });
                    // check if table has a uid column
                    var uidColumn = "";
                    this.settings.parameter.columns.forEach(function (col) {
                        if (col.editor == ColumnEditor.uid) {
                            uidColumn = col.field;
                        }
                    });

                    selrows.forEach(function (selrow) {
                        var rowData = ml.JSON.clone(gdata[selrow]);

                        if (uidColumn) {
                            // remove the guid
                            rowData[uidColumn] = "";
                        }

                        select.push(rowData);
                    });

                    globalMatrix.serverStorage.setItem("copyPasteBufferTable", JSON.stringify(select), true);
                } else if (event.keyCode == 86 && globalMatrix.serverStorage.getItem("copyPasteBufferTable", true)) {
                    var newrows = JSON.parse(globalMatrix.serverStorage.getItem("copyPasteBufferTable", true));

                    var rowCount = newrows.length;

                    // make sure table rows can be added with copy paste only if row count can be modified
                    if (ml.JSON.isFalse(this.settings.parameter.canBeModified)) {
                        return;
                    }

                    // make sure table will not be not be bigger than allowed
                    if (
                        this.settings.parameter.maxRows !== -1 &&
                        this.grid.getDataLength() + rowCount > this.settings.parameter.maxRows
                    ) {
                        ml.UI.showError("Warning", "Table can have only " + this.settings.parameter.maxRows + " rows");
                        return;
                    }
                    // find place to insert
                    var insertAbove = rows[0];

                    newrows.forEach(function (row) {
                        // for each new row, build a row to insert
                        var newRow = {};
                        that.settings.parameter.columns.forEach(function (col, cidx) {
                            if (col.editor !== "none") {
                                // for each editable column: copy fields with same field id's
                                if (row[that.settings.parameter.columns[cidx].field]) {
                                    newRow[that.settings.parameter.columns[cidx].field] =
                                        row[that.settings.parameter.columns[cidx].field];
                                }
                            }
                        });
                        that.autoPopulate(newRow);
                        // insert
                        that.grid.getData().splice(insertAbove++, 0, newRow);
                    });

                    this.grid.invalidate();
                    this.grid.updateRowCount();
                    this.grid.render();

                    // unfortunately need to render whole thing
                    this.updateRowHeights();
                    this.scrollBack();

                    this.gridChanged();
                }
            }
        }
    }
}
