// A field handler base implementation for field types that ultimately are displayed
// by the table control.

import { IDropdownOption } from "../../../ProjectSettings";
import { FieldDescriptions } from "../FieldDescriptions";
import { BaseTableFieldHandler, ITableControlBaseParams } from "./BaseTableFieldHandler";
import { IFieldHandler } from "./IFieldHandler";

export { BaseValidatedTableFieldHandler };

class BaseValidatedTableFieldHandler extends BaseTableFieldHandler {
    constructor(configIn: ITableControlBaseParams) {
        super(configIn);
    }

    validate() {
        if (this.data === undefined) {
            // No data in the table is okay.
            return;
        }

        // Data should be an array.
        if (typeof this.data.length == "undefined") {
            throw new Error("data is not an array of rows");
        }

        for (let row of this.data) {
            // Each row has a property name for each column.
            for (const fieldId in row) {
                let colDetails = this.getColumnByField(fieldId);
                if (!colDetails) {
                    throw new Error(`Unable to find definition for column with field id ${fieldId}`);
                }
                // TODO: validate the type of the field data.
            }
        }
    }

    setDataAsArray(dataIn: any[], fixData = false) {
        if (this.data && fixData) {
            for (let row of this.data) {
                // Each row has a property name for each column.
                for (const colId in row) {
                    let colDetails = this.getColumnByField(colId);
                    if (!colDetails) {
                        delete row[colId];
                    }
                }
            }
        }
        this.data = dataIn;
        this.validate();
    }
}
