import { FieldDescriptions } from "../FieldDescriptions";
import { XRFieldTypeAnnotatedParamJson } from "../ItemConfiguration";
import { TestManagerConfiguration } from "../TestManagerConfiguration";
import { ITableControlBaseParams } from "./BaseTableFieldHandler";
import { BaseValidatedTableFieldHandler } from "./BaseValidatedTableFieldHandler";

export { TestStepsResultFieldHandler };

class TestStepsResultFieldHandler extends BaseValidatedTableFieldHandler {
    public static UpdateFieldConfig(options: XRFieldTypeAnnotatedParamJson, testConfig: TestManagerConfiguration) {
        // This field handler gets it's configuration options from the test manager.
        const rconfig = testConfig.getTestStepsResultsConfig();
        for (let name in rconfig) {
            options[name] = rconfig[name];
        }
    }

    constructor(options: ITableControlBaseParams) {
        super(options);
    }

    getFieldType(): string {
        return FieldDescriptions.Field_test_steps_result;
    }

    validate() {
        // TODO: validation for this kind of table is more difficult.
        // There is a property "passFailEditorConfig" on the options that needs to
        // be looked at.
    }
}
