import { IStringMap, ControlState, IReference, IItem, globalMatrix, app, matrixSession } from "../../../globals";
import { ICompanyTiny } from "../../businesslogic/index";
import { IPlugin, IProjectPageParam, plugins } from "../../businesslogic/index";
import { IFileUploadProgress } from "../../businesslogic/index";
import { Tasks } from "../../businesslogic/index";
import { FileTools } from "../../matrixlib/index";
import { HTMLCleaner } from "../../matrixlib/index";
import { ItemControl } from "../Components/ItemForm";
import { KeyboardShortcuts } from "../Components/KeyboardShortcuts";
import { SelectMode } from "../Components/ProjectViewDefines";
import { BaseControl, IBaseControlOptions } from "./BaseControl";
import { FileManagerImpl } from "./fileManager";
import { PrintProjectUIMods } from "./PrintProjectUIMods";
import { IRichTextControlOptions } from "./richText";
import { ml } from "./../../matrixlib";
import { ISmartTextConfigReplacement } from "../../../ProjectSettings";
import { ItemSelectionTools } from "../Tools/ItemSelectionView";
import { IUploadedFileInfo, IBlockingProgressUITask } from "../../matrixlib/MatrixLibInterfaces";
import { RichtextFieldHandler } from "../../businesslogic/FieldHandlers/RichtextFieldHandler";

enum EEditMode {
    readonly = 0, // user cannot edit
    edit = 1, // user is in edit mode
    canEdit = 2, // user can switch to edit
}

export function hackInAQueryParamToDisableCachingForSafariOnly(original: string): string {
    if (navigator.userAgent.indexOf("Safari") === -1 || navigator.userAgent.indexOf("Chrome") !== -1) {
        // Not Safari, return original
        return original;
    }

    const uncacheregex = /&uncache=(.*)$/;
    const imageRegex = /<img.*src="([^"]*)".*?>/gi;

    type Change = { original: string; replacement: string };
    const changes: Change[] = [];

    const uncache = "&uncache=" + new Date().getTime();
    let moreMatches = true;
    do {
        const imageTags = imageRegex.exec(original);
        if (imageTags) {
            const url = imageTags[1];
            let newUrl = url;
            if (uncacheregex.test(url)) {
                newUrl = url.replace(uncacheregex, uncache);
            } else {
                newUrl = url + uncache;
            }
            changes.push({ original: url, replacement: newUrl });
        } else {
            moreMatches = false;
        }
    } while (moreMatches);

    let newString = original;
    changes.forEach(({ original, replacement }) => {
        newString = newString.replace(original, replacement);
    });
    return newString;
}

export class RichText2 extends BaseControl<RichtextFieldHandler> {
    static editorInstanceCount: number = 0;
    static toolbarHeight: number = 70;

    private settings: IRichTextControlOptions;
    private selectorId: string;
    private dataOriginal: string;
    private dataChanged: string;
    private formDataOriginal: {};
    private formDataChanged: {};

    private lastValueChanged: number; // timer for delayed change event
    private purifyServer: number; // timer for delayed server purification test
    private isInEditMode: boolean;
    private duringInit: boolean;
    private delayedInit: number;
    private editingDrawIO: boolean;

    private editorBox: JQuery;
    private editor: any;
    private form: ItemControl;
    private tinyConf: ICompanyTiny;

    private lastUploadedFile: string;
    private failedImages: string[];
    private imgSrcMap: IStringMap = {};

    private doesRequireContent = false;

    private cachedContent: string = null;
    private wasDifferentBefore = false;

    constructor(control: JQuery, fieldHandler: RichtextFieldHandler) {
        super(control, fieldHandler);
    }

    init(options: IRichTextControlOptions, form?: ItemControl) {
        let that = this;

        this.failedImages = [];

        var defaultOptions = {
            controlState: ControlState.FormView, // read only rendering
            dummyData: false, // fill control with a dummy text (for form design...)
            valueChanged: function () {}, // callback to call if value changes
            canEdit: false, // whether data can be edited
            parameter: {
                height: 250, // height in pixel
                readonly: false, // can be set to overwrite the default readonly status
                showSmartText: true, // allow users to insert smart text
                tableMode: false, // in table mode no smart text can be added to table cells also from 1.11 table+figure captions are supported (in docs)
                docMode: false, // in doc mode headers will show up as heading in table of content of docs... also from 1.11 table+figure captions are supported (in docs)
                autoEdit: false, // whether the control should be directly in edit mode when shown
                wiki: true, // by default allow to enter list and tables in wiki style
                autoFocus: false, // whether the editor should get the focus when it is shown
            },
        };

        // figure out default / fixed height
        let resizable = true;
        if (options.parameter && options.parameter.height) {
            let str = "" + options.parameter.height;

            options.parameter.height = Number(str.replace("px", ""));
        }
        if (options.parameter && (!options.parameter.height || options.parameter.height < 0)) {
            options.parameter.height = options.parameter.height ? -options.parameter.height : 250; // default
        }

        if (options.parameter && options.parameter.requiresContent) {
            this.doesRequireContent = options.parameter.requiresContent;
        }

        // fill parameters with defaults
        this.settings = ml.JSON.mergeOptions(defaultOptions, options);

        // store other form elements (e.g. to find file attachment list)
        this.form = form;

        // have default values
        if (!this.settings.fieldValue && this.settings.parameter.initialContent && !this.settings.item) {
            this.settings.fieldValue = this.settings.parameter.initialContent;
        }

        // remember original data
        this.dataOriginal = typeof this.settings.fieldValue === "undefined" ? "" : this.settings.fieldValue;
        this.dataOriginal = hackInAQueryParamToDisableCachingForSafariOnly(this.dataOriginal);
        this.dataChanged = this.dataOriginal;

        // show field title, with an icon to edit
        this.settings.help = `<span class="rtFieldTitle">${this.settings.help}</span>`;
        let sectionHeading = super.createHelp(this.settings);
        this._root.append(sectionHeading);

        let css = this.settings.controlState === ControlState.Print ? "class='printBox'" : "baseControl";

        // container for editor and viewer
        let containerWidth = that.settings.parameter.widthViewer
            ? that.settings.parameter.widthViewer
            : that.settings.parameter.width
            ? that.settings.parameter.width
            : "18cm";
        let editView = $(
            `<div class='${css} textEditContainer' tabindex="0" style='max-width:${containerWidth}'>`,
        ).appendTo(this._root);

        // MATRIX-6247: entering edit mode on gaining focus via tab key, but not via click (editView.mousedown)
        // or after tab change ($(window).blur)
        if (this.settings.canEdit && !this.settings.parameter.autoEdit) {
            const skipFocusDataAttr = "skipFocus";
            editView.focus(() => {
                if (editView.data(skipFocusDataAttr) === "true") {
                    return;
                }

                that.showEditor();
                that.editor.focus();
            });
            editView.mousedown(() => {
                editView.data(skipFocusDataAttr, "true");
            });
            $(window).blur(() => editView.data(skipFocusDataAttr, "true"));
            editView.blur(() => editView.data(skipFocusDataAttr, "false"));
        }

        // add pencil for switching to edit mode
        let autoEditMode =
            (this.settings.controlState == ControlState.FormEdit && this.settings.parameter.autoEdit) ||
            (this.settings.controlState == ControlState.FormEdit &&
                this.settings.parameter.docMode &&
                !this.settings.fieldValue) ||
            (this.settings.controlState == ControlState.DialogEdit && this.settings.parameter.autoEdit) ||
            this.settings.controlState == ControlState.DialogCreate;

        let displayMode = this.settings.canEdit
            ? autoEditMode
                ? EEditMode.edit
                : EEditMode.canEdit
            : EEditMode.readonly;

        this.addToggleEditButton(displayMode);

        // show content for print/preview forms (no editor)
        if (this.dataOriginal.indexOf("_codemode_") != -1) {
            if (this.dataOriginal.indexOf("_nocodemode_") == -1) {
                this.renderInCodeMode();
                return;
            }
            this.dataOriginal = this.dataOriginal.replace(/_codemode_/g, "").replace(/_nocodemode_/g, "");
            this.dataChanged = this.dataOriginal;
        }

        // show the text (readonly), e.g. preview/history/print)
        let viewer = $(`<div>`).appendTo(editView);

        if (displayMode == EEditMode.edit) {
            // hide the viewer when the editor is visible
            viewer.hide();
        }

        // in the history we keep the macros as macros (why: less text to read/compare and macros might change over time, and would potentially be wrong in old versions)
        let text = this.dataOriginal;

        if (this.settings.controlState === ControlState.Print) {
            // we really want to sanetize, again? (need to check the "safe_for_jquery")
            viewer.html(DOMPurify.sanitize(text, { SAFE_FOR_JQUERY: true }));
        } else if (this.settings.controlState === ControlState.Review) {
            let displayText = DOMPurify.sanitize(text, { SAFE_FOR_JQUERY: true });
            viewer.html(ml.SmartText.replaceTextFragments(displayText, true));
            viewer.highlightReferences();
        } else {
            let projectStyles: any = globalMatrix.ItemConfig.getSettingJSON("richtext_style", {
                richtextCSS: "",
                richtextStyle: "",
            });
            let style = projectStyles.richtextStyle ? `<style>${projectStyles.richtextStyle}  </style>` : ``;

            viewer.addClass("shadow-root").attr("id", (<any>crypto).randomUUID());
            const shadowRoot = viewer[0];
            const shadowElement = shadowRoot.attachShadow({ mode: "open" });
            // should that be DOMPurify.sanitize(text, { SAFE_FOR_JQUERY: true })?
            shadowElement.innerHTML = style + `<div class='shadowedContent'></div>`;
            // add editor css
            const cssEditor = document.createElement("link");
            cssEditor.setAttribute("rel", "stylesheet");
            cssEditor.setAttribute("href", `${globalMatrix.matrixBaseUrl}/static/css/editor${app.getVersion()}.css`);
            shadowElement.appendChild(cssEditor);
            // do not resolve smart text and macros in history, as the version as of today would be shown (so maybe it was different in the past)
            this.renderInShadowRoot(text, this.settings.controlState != ControlState.HistoryView);
        }

        if (this.settings.canEdit && !this.settings.disableTinyMce) {
            // create a editor with an id
            RichText2.editorInstanceCount++;
            let ctrlContainer = $("<div>").addClass("editor-root").appendTo(editView);
            if (displayMode != EEditMode.edit) {
                // unless the editor should be active, right away, hide it
                ctrlContainer.hide();
            }

            this.selectorId = "rt2_" + RichText2.editorInstanceCount;

            this.editorBox = $('<div class="thisIsTiny" id="' + this.selectorId + '">')
                .appendTo(ctrlContainer)
                .html(text);

            // initialize the editor
            this.initEditor(resizable);
            this.fieldHandler.initData(this.dataOriginal);
        }

        let rtEmpty = $("<div class='rtEmpty'>Add text, images, tables and more.</div>");

        const placeholderStates = [
            ControlState.FormEdit,
            ControlState.FormView,
            ControlState.DialogCreate,
            ControlState.DialogEdit,
        ];

        if (placeholderStates.includes(this.settings.controlState)) {
            editView.append(rtEmpty);
        }

        if (this.dataOriginal == "" && !autoEditMode) {
            rtEmpty.show();
            $(".shadow-root", this._root).hide();
        } else {
            rtEmpty.hide();
        }
    }

    private async updateShadowRoot() {
        // get text with smart text applied
        let text = await this.getValueAsync();
        this.renderInShadowRoot(text, true);
        if (text == "") {
            $(".rtEmpty", this._root).show();
            $(".shadow-root", this._root).hide();
        } else {
            $(".rtEmpty", this._root).hide();
        }
    }
    private renderInShadowRoot(text: string, resolveSmart: boolean) {
        let that = this;

        const shadowRootContainer = $(".shadow-root", this._root);
        const shadowRoot = shadowRootContainer[0].shadowRoot;

        let shadowedContent = $(".shadowedContent", $(shadowRoot));

        if (shadowedContent.length == 0) {
            console.log("no shadow root created - not rendering content");
            return;
        }
        shadowedContent[0].innerHTML = resolveSmart ? ml.SmartText.replaceTextFragments(text, true) : text;

        if (resolveSmart) {
            ml.SmartText.showTooltips(shadowedContent, true);
            if (app.mainTreeLoaded) {
                shadowedContent.highlightReferences();
            } else if (app.waitForMainTree != undefined) {
                app.waitForMainTree(() => {
                    shadowedContent.highlightReferences();
                });
            }
        }

        // make all form elements work
        if (this.settings.canEdit) {
            $("input", shadowedContent).on("change", () => {
                that.onFormChanged(shadowedContent);
            });
            $("select", shadowedContent).on("change", () => {
                that.onFormChanged(shadowedContent);
            });
            $("textarea", shadowedContent).on("change", () => {
                that.onFormChanged(shadowedContent);
            });
            this.formDataOriginal = this.formDataChanged = that.getFormData(shadowedContent);
        } else {
            $("input", shadowedContent).attr("disabled", "true").attr("readonly", "true");
            $("select", shadowedContent).attr("disabled", "true");
            $("textarea", shadowedContent).attr("readonly", "true").attr("disabled", "true");
        }
    }

    private onFormChanged(shadowedContent: JQuery) {
        this.formDataChanged = this.getFormData(shadowedContent);
        let storedData = $("<div>").html(this.dataChanged);

        $("input[type='radio'],input[type='checkbox']", storedData).removeAttr("checked");

        $("input[type='radio']", storedData).each((idx, inp) => {
            let nv = this.formDataChanged[$(inp).attr("name")] ?? "";
            if ($(inp).attr("value") == nv) {
                $(inp).attr("checked", 1); // note .prop does not work
            }
        });

        $("input[type='checkbox']", storedData).each((idx, inp) => {
            let nv = this.formDataChanged[$(inp).attr("name")] ?? "";
            if (nv) {
                $(inp).attr("checked", 1);
            }
        });

        $("input[type='text'],input[type='date']", storedData).each((idx, inp) => {
            let nv = this.formDataChanged[$(inp).attr("name")] ?? "";
            $(inp).attr("value", nv);
        });

        $("select option:selected", storedData).removeAttr("selected");

        $("select", storedData).each((idx, inp) => {
            let nv = this.formDataChanged[$(inp).attr("name")] ?? "";
            $(`option[value='${nv}']`, inp).attr("selected", 1);
        });

        $("textarea", storedData).each((idx, textarea) => {
            let nv = this.formDataChanged[$(textarea).attr("name")] ?? "";
            $(textarea).text(nv);
        });

        this.dataChanged = storedData.html();
    }

    /** read data from a form as json */
    private getFormData(shadowedContent: JQuery) {
        let forms = $("form", shadowedContent);
        if (forms.length == 0) {
            // no form
            return {};
        }
        if (forms.length > 1) {
            console.log("more than one form. Everything but first will be ignored!");
        }
        // this is supported by all current browsers but requires ES2019  https://www.w3schools.com/jS/js_2019.asp#mark_from_entries
        const data = new FormData(<any>forms[0]);
        return (<any>Object).fromEntries((<any>data).entries());
    }

    /** allow to switch between the preview mode and the edit mode */
    private addToggleEditButton(mode: EEditMode) {
        let that = this;
        if (mode != EEditMode.readonly) {
            let pencilEdit = $("<span class='textEdit  textEditToggleEdit' title='Edit'>").click(
                (e: JQueryEventObject) => {
                    this.showEditor();
                    if (e.preventDefault) e.preventDefault();
                    if (e.stopPropagation) e.stopPropagation();
                },
            );
            let pencilPreview = $(
                "<span class='textPreview textEditToggle textEditTogglePreview' title='Close editor'>",
            ).click((e: JQueryEventObject) => {
                that.closeEditor();
                if (e.preventDefault) e.preventDefault();
                if (e.stopPropagation) e.stopPropagation();
            });

            $(".rtFieldTitle", this._root).append(pencilEdit);
            $(".textEditContainer", this._root).append(pencilPreview);

            if (mode == EEditMode.canEdit) {
                pencilPreview.hide();
            } else if (mode == EEditMode.edit) {
                pencilEdit.hide();
            }
            if (this.settings.parameter.autoEdit) {
                pencilPreview.hide();
                pencilEdit.hide();
            }
        }
    }

    private showEditor() {
        const pencilEdit = $(".textEditToggleEdit", this._root);
        const pencilPreview = $(".textEditTogglePreview", this._root);

        $(".shadow-root", this._root).hide();
        $(".editor-root", this._root).show();
        pencilEdit.hide();
        pencilPreview.show();
        this.enableEditor();
        // Hide the empty indicator
        $(".rtEmpty", this._root).hide();
    }

    // public interface
    async hasChangedAsync() {
        this.getValueAsync();
        this.wasDifferentBefore =
            this.editingDrawIO ||
            this.dataChanged != this.dataOriginal ||
            this.formDataChanged != this.formDataOriginal;
        return this.wasDifferentBefore;
    }

    async getValueAsync() {
        let internalValue = this.getValueAsyncInternal();

        return internalValue;
    }

    protected getValueAsyncInternal() {
        let that = this;

        if (this.isInEditMode) {
            this.dataChanged = this.getPurifiedContent();
            this.fieldHandler.setData(this.dataChanged);
        }

        // return the html content of the editorBox
        return this.fieldHandler.getData();
    }

    setValue(newVal: string) {
        // show it in the viewer
        this.renderInShadowRoot(newVal, true);

        // We may need to enable visibility of the shadowed content if a) the shadowed content
        // is hidden and b) the description string is visible, and c) we've got some non-empty text.
        const shadowRootContainer = $(".shadow-root", this._root);
        const shadowRoot = shadowRootContainer[0].shadowRoot;
        const shadowedContent = $(".shadowedContent", $(shadowRoot));
        if (shadowedContent.length > 0) {
            const rtEmpty = $(".rtEmpty", this._root);
            if (rtEmpty.is(":visible") && !shadowedContent.is(":visible") && newVal !== "") {
                rtEmpty.hide();
                $(".shadow-root", this._root).show();
            }
        }

        if (!this.editor) {
            // Store the desired value change to apply when init is done
            this.cachedContent = newVal;
            this.fieldHandler.setData(newVal);
            return;
        }
        this.fieldHandler.setData(newVal);
        let that = this;
        this.dataChanged = newVal;
        this.setContent(newVal);

        this.processPastedImages().done(function () {
            that.markBadImages();
        });
    }

    destroy() {
        $(".popover").remove();
        // only destroy once it has been created
        if (this.editor) {
            this.editor.destroy();
            this.editor = null;
        }
        // maybe it the destroy came while waiting for the init
        window.clearTimeout(this.delayedInit);
    }
    resizeItem() {}

    requiresContent() {
        return this.doesRequireContent;
    }

    highlightReferences() {
        if (!this.isInEditMode && this.editor) {
            $(this.editor.getBody()).highlightReferences();
        }
    }

    // *************************************
    // special html code mode
    // *************************************

    private renderInCodeMode() {
        let that = this;

        let readonly =
            !that.settings.canEdit ||
            this.settings.controlState === ControlState.Tooltip ||
            this.settings.controlState === ControlState.Print ||
            this.settings.controlState === ControlState.HistoryView;

        let code = this.dataChanged.replace(/_codemode_/g, "").replace(/&quot;/g, '\\"');
        let codeEditor = $(
            `<textarea style='width:100%; resize: vertical; height:${that.settings.parameter.height}px'>`,
        )
            .val(code)
            .appendTo(this._root);
        let mirror = CodeMirror.fromTextArea(codeEditor[0], {
            mode: "htmlmixed",
            tabSize: 3,
            readOnly: readonly,
        });
        mirror.setSize("100%", that.settings.parameter.height + "px");
        (<any>mirror).on("change", () => {
            that.dataChanged = "_codemode_" + mirror.getValue().replace(/\\"/g, "&quot;");
            that.onPostChange("");
        });
    }

    // *************************************
    // callbacks from editor
    // *************************************

    private cleanBlock(block: string, classes: string[]) {
        $.each(classes, function (idx, cl) {
            $(block).removeClass(cl);
        });
    }

    // setup editor functions
    private initEditor(resizable: boolean) {
        let that = this;

        let ui = matrixSession.getUISettings({});
        that.tinyConf = ui.tiny ? ui.tiny : <ICompanyTiny>{};

        // the menu
        let style_formats = that.tinyConf.style_formats
            ? that.tinyConf.style_formats
            : [
                  { title: "H1", format: "h1" },
                  { title: "H2", format: "h2" },
                  { title: "H3", format: "h3" },
                  { title: "H4", format: "h4" },
                  { title: "H5", format: "h5" },
                  { title: "H6", format: "h6" },
              ];

        // the implementation of headings (and other blocks)
        let apply_formats = that.tinyConf.apply_formats
            ? that.tinyConf.apply_formats
            : {
                  p: { block: "p", classes: "p", attributes: { style: "" }, exact: true },
                  unblock: { block: "div", attributes: { style: "display:inline" }, exact: true }, // "wrapper":true
                  pre: { block: "pre", exact: true },
                  quote: { block: "quote", exact: true },
                  h1: { block: "p", classes: "h1", attributes: { style: "" }, exact: true },
                  h2: { block: "p", classes: "h2", attributes: { style: "" }, exact: true },
                  h3: { block: "p", classes: "h3", attributes: { style: "" }, exact: true },
                  h4: { block: "p", classes: "h4", attributes: { style: "" }, exact: true },
                  h5: { block: "p", classes: "h5", attributes: { style: "" }, exact: true },
                  h6: { block: "p", classes: "h6", attributes: { style: "" }, exact: true },
              };

        let classes: string = "";
        $.each(apply_formats, function (key, val) {
            if (val.classes) classes = classes + (classes ? "," : "") + val.classes;
        });

        $.each(apply_formats, function (key, val) {
            val.onformat = (block: string) => {
                that.cleanBlock(block, classes.split(","));
            };
        });

        let block_formats = that.tinyConf.block_formats
            ? that.tinyConf.block_formats
            : "Paragraph=p;No Paragraph=unblock;Header 1=h1;Header 2=h2;Header 3=h3;Header 4=h4;Header 5=h5;Header 6=h6;Pre=pre;Quote=blockquote";

        let style_formats_doc = that.tinyConf.style_formats_doc
            ? that.tinyConf.style_formats_doc
            : [
                  { title: "H2", format: "h2" },
                  { title: "H3", format: "h3" },
                  { title: "H4", format: "h4" },
                  { title: "H5", format: "h5" },
                  { title: "H6", format: "h6" },
              ];

        let apply_formats_doc = that.tinyConf.apply_formats_doc
            ? that.tinyConf.apply_formats_doc
            : {
                  h2: { block: "h2", exact: true },
                  h3: { block: "h3", exact: true },
                  h4: { block: "h4", exact: true },
                  h5: { block: "h5", exact: true },
                  h6: { block: "h6", exact: true },
                  aligncenter: {
                      selector: "p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img",
                      classes: "center",
                      styles: { display: "block", margin: "0px auto", textAlign: "center" },
                  },
              };

        let block_formats_doc = that.tinyConf.block_formats_doc
            ? that.tinyConf.block_formats_doc
            : "Paragraph=p;Header 2=h2;Header 3=h3;Header 4=h4;Header 5=h5;Header 6=h6;Pre=pre;Quote=blockquote";

        let contextMenu: string | boolean = false;
        if (!matrixSession.getUISettings().tiny || !matrixSession.getUISettings().tiny.tinyHideMenu) {
            contextMenu =
                (this.settings.parameter.printMode ? PrintProjectUIMods.getMenu() : "") +
                "link image imagetools table useBrowserSpellcheck";
        }

        let matrixMenu =
            "References SmartPaste SmartText " +
            plugins
                .getTinyMenus(this.editor)
                .map((pmi) => pmi.id)
                .join(" ");
        // wait until dialog is really there...
        let tinySettings: tinymce.Settings = {
            readonly: 0,
            selector: "#" + that.selectorId,
            plugins: that.tinyConf.plugins
                ? that.tinyConf.plugins
                : [
                      "advlist",
                      "autolink",
                      "link",
                      "lists",
                      "code",
                      "charmap",
                      "anchor",
                      "image",
                      "searchreplace",
                      "fullscreen",
                      "insertdatetime",
                      "nonbreaking",
                      "table",
                      "directionality",
                      "image",
                      "autosave",
                      //'codesample', won't work when printing (as it relies on just in time javascript to render code)
                  ],
            autosave_ask_before_unload: false,
            autosave_interval: "10s", // default was 30
            branding: false,
            toolbar: that.tinyConf.toolbar
                ? that.tinyConf.toolbar
                : " restoredraft undo redo | blocks | removeformat bold italic | bullist numlist outdent indent | fullscreen closeEditor",
            menubar: that.tinyConf.menubar ? that.tinyConf.menubar : "edit view insert format table matrix",
            menu: <any>{
                matrix: { title: "Matrix", items: matrixMenu },
                insert: {
                    title: "Insert",
                    items: "image link AddDrawIO media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking toc | insertdatetime",
                },
                view: { title: "View", items: "code | fullscreen" },
            },
            contextmenu: contextMenu,
            setup: (editor) => that.onSetup(editor),

            width: that.settings.parameter.width ? that.settings.parameter.width : "18cm",
            height: RichText2.toolbarHeight + that.settings.parameter.height + "px",
            content_css:
                globalMatrix.matrixBaseUrl +
                "/static/css/editor" +
                app.getVersion() +
                ".css" +
                (that.tinyConf.css ? "," + globalMatrix.matrixBaseUrl + that.tinyConf.css : ""),
            // different header for items vs docs
            formats: that.settings.parameter.docMode ? apply_formats_doc : apply_formats,
            style_formats: that.settings.parameter.docMode ? style_formats_doc : style_formats,
            block_formats: that.settings.parameter.docMode ? block_formats_doc : block_formats,
            // make sure theres no AI to make url's relative
            relative_urls: false,
            remove_script_host: false,
            document_base_url: globalMatrix.matrixBaseUrl,
            // allow svg
            extended_valid_elements:
                (that.settings.parameter.printMode ? "h_depth_,h_depth1_," : "") +
                (that.tinyConf.extended_valid_elements
                    ? that.tinyConf.extended_valid_elements
                    : "svg[*],defs[*],pattern[*],desc[*],metadata[*],g[*],mask[*],path[*],line[*],marker[*],rect[*],circle[*],ellipse[*],polygon[*],polyline[*],linearGradient[*],radialGradient[*],stop[*],image[*],view[*],text[*],textPath[*],title[*],tspan[*],glyph[*],symbol[*],switch[*],use[*],data-dataurl"),
            custom_elements:   (that.settings.parameter.printMode ? "h_depth_,h_depth1_" : ""),
            resize: resizable,
            // other options
            lists_indent_on_tab: true, // allow indent list with tab (list plugin)
            // images
            paste_data_images: true, // want to handle images myself with below's handler though
            images_upload_handler: (blobInfo: any, progress: any) => that.images_upload_handler(blobInfo, progress),
            file_picker_callback: function (callback, value, meta: any) {
                if (meta.filetype === "file") {
                    callback("https://www.matrixreq.com", { text: "My text" });
                }
                if (meta.filetype == "image") {
                    var input = document.createElement("input");
                    input.setAttribute("type", "file");
                    input.setAttribute("accept", "image/*");

                    input.onchange = function () {
                        ml.File.UploadFileAsync(input.files[0]).done(function (uploads: IUploadedFileInfo[]) {
                            let imgPath =
                                globalMatrix.matrixRestUrl +
                                "/" +
                                matrixSession.getProject() +
                                "/file/" +
                                uploads[0].fileId;
                            callback(imgPath, { title: input.files[0].name });
                            that.lastUploadedFile = imgPath;
                        });
                    };
                    input.click();
                }
            },

            browser_spellcheck: true,
            // Disable the choice of link target windows
            target_list: false,
            default_link_target: "_blank",
            //MATRIX-4708 : PRINT Remove unsupported list styles
            advlist_number_styles: "default,lower-alpha,lower-roman,upper-alpha,upper-roman",
            advlist_bullet_styles: "disc",
        };
        if (this.settings.parameter.printMode) {
            tinySettings.menubar += " print";
            tinySettings.menu["print"] = {
                title: "Print Functions",
                items: "PrintMenuItems PrintMenuFields PrintMenuLabels PrintMenuMacros PrintMenuCSS",
            };
        }
        if (that.tinyConf.extraPlugins) {
            // Because this plugin in TinyMCE v5 was moved to the core of v6, we don't need it to be declared here as plugin
            const index: number = that.tinyConf.extraPlugins.indexOf("textpattern", 0);
            if (index > -1) {
                that.tinyConf.extraPlugins.splice(index, 1);
            }
            // add custom menus
            tinySettings.plugins =
                typeof tinySettings.plugins == "object"
                    ? tinySettings.plugins.concat(that.tinyConf.extraPlugins)
                    : tinySettings.plugins + "," + that.tinyConf.extraPlugins;
        }
        if (that.tinyConf.textpattern_patterns) {
            // either the old conf (Tiny v5)
            tinySettings.text_patterns = that.tinyConf.textpattern_patterns;
        } else if (that.tinyConf.text_patterns) {
            // or the new conf (Tiny v6)
            tinySettings.text_patterns = that.tinyConf.text_patterns;
        }
        if (that.tinyConf.menu) {
            // add custom menus
            for (let menu in that.tinyConf.menu) {
                tinySettings.menu[menu] = that.tinyConf.menu[menu];
            }
        }

        if (that.tinyConf.misc) {
            // add / overwrite any other property
            for (let misc in that.tinyConf.misc) {
                tinySettings[misc] = that.tinyConf.misc[misc];
            }
        }

        if (that.tinyConf.short_ended_elements) {
            tinySettings.short_ended_elements = that.tinyConf.short_ended_elements;
        }
        if (this.settings.parameter.printMode) {
            tinySettings.forced_root_block = ""; // don't put <p> around everything
            //MATRIX-7167: Add data-print attribute to all root blocks, so we remove them in print mode.
            tinySettings.forced_root_block_attrs = { "data-print": "true" };
        }
        // options which have been depreciated with tiny 6 (they might be in some 'private' configuration)
        delete (<any>tinySettings).force_br_newlines; // instead each block is in <p> and press shift return for <br>
        delete (<any>tinySettings).force_p_newlines;
        if (tinySettings.forced_root_block === "") {
            // there should be a root block which is a block element which we don't want here... so try a span (even though that can be wrong html I assume)
            tinySettings.forced_root_block = "div";
        }
        that.delayedInit = window.setTimeout(function () {
            that.duringInit = true;
            tinymce.init(tinySettings);

            // when autofocus is set, focus the editor and move the caret to the end of the text
            tinymce.activeEditor.on("LoadContent", (e) => {
                if (that.settings.parameter.autoFocus) {
                    tinymce.activeEditor.focus(false);
                    let body = tinymce.activeEditor.getBody();
                    tinymce.activeEditor.selection.select(tinymce.activeEditor.getBody(), true);
                    tinymce.activeEditor.selection.collapse(false);
                }
            });
            // reset during onInit that.duringInit = false;
        }, 1);
    }
    private onSetup(editor: any) {
        let that = this;
        this.editor = editor;

        this.editor.fieldParams = this.settings;

        if (!this.settings.parameter.tableMode && this.settings.parameter.showSmartText) {
            if (globalMatrix.serverStorage.getItem("copyBuffer")) {
                editor.ui.registry.addMenuItem("SmartPaste", {
                    text: "Paste Dashboard",
                    onAction: function () {
                        that.pasteBuffer();
                    },
                });
            }

            editor.ui.registry.addNestedMenuItem("SmartText", {
                text: "SmartText",
                getSubmenuItems: function () {
                    return [
                        {
                            type: "nestedmenuitem",
                            text: "Rich Text Macros",
                            getSubmenuItems: function () {
                                return [
                                    {
                                        type: "menuitem",
                                        text: "Insert",
                                        onAction: function () {
                                            ml.SmartText.selectEditCreateTag(0, 2, (tag: ISmartTextConfigReplacement) =>
                                                that.insertSmartMacro(tag),
                                            );
                                        },
                                    },
                                    {
                                        type: "menuitem",
                                        text: "Create",
                                        onAction: function () {
                                            ml.SmartText.selectEditCreateTag(1, 2, (tag: ISmartTextConfigReplacement) =>
                                                that.insertSmartMacro(tag),
                                            );
                                        },
                                    },
                                    {
                                        type: "menuitem",
                                        text: "Edit",
                                        onAction: function () {
                                            ml.SmartText.selectEditCreateTag(2, 2, (tag: ISmartTextConfigReplacement) =>
                                                that.insertSmartMacro(tag),
                                            );
                                        },
                                    },
                                ];
                            },
                        },
                        {
                            type: "nestedmenuitem",
                            text: "Plain Text Macros",
                            getSubmenuItems: function () {
                                return [
                                    {
                                        type: "menuitem",
                                        text: "Insert",
                                        onAction: function () {
                                            ml.SmartText.selectEditCreateTag(0, 1, (tag: ISmartTextConfigReplacement) =>
                                                that.insertSmartMacro(tag),
                                            );
                                        },
                                    },
                                    {
                                        type: "menuitem",
                                        text: "Create",
                                        onAction: function () {
                                            ml.SmartText.selectEditCreateTag(1, 1, (tag: ISmartTextConfigReplacement) =>
                                                that.insertSmartMacro(tag),
                                            );
                                        },
                                    },
                                    {
                                        type: "menuitem",
                                        text: "Edit",
                                        onAction: function () {
                                            ml.SmartText.selectEditCreateTag(2, 1, (tag: ISmartTextConfigReplacement) =>
                                                that.insertSmartMacro(tag),
                                            );
                                        },
                                    },
                                ];
                            },
                        },
                        {
                            type: "nestedmenuitem",
                            text: "Abbreviations",
                            getSubmenuItems: function () {
                                return [
                                    {
                                        type: "menuitem",
                                        text: "Insert",
                                        onAction: function () {
                                            ml.SmartText.selectEditCreateTag(0, 4, (tag: ISmartTextConfigReplacement) =>
                                                that.insertSmartMacro(tag),
                                            );
                                        },
                                    },
                                    {
                                        type: "menuitem",
                                        text: "Create",
                                        onAction: function () {
                                            ml.SmartText.selectEditCreateTag(1, 4, (tag: ISmartTextConfigReplacement) =>
                                                that.insertSmartMacro(tag),
                                            );
                                        },
                                    },
                                    {
                                        type: "menuitem",
                                        text: "Edit",
                                        onAction: function () {
                                            ml.SmartText.selectEditCreateTag(2, 4, (tag: ISmartTextConfigReplacement) =>
                                                that.insertSmartMacro(tag),
                                            );
                                        },
                                    },
                                ];
                            },
                        },
                        {
                            type: "nestedmenuitem",
                            text: "Insert / Edit Terms",
                            getSubmenuItems: function () {
                                return [
                                    {
                                        type: "menuitem",
                                        text: "Insert",
                                        onAction: function () {
                                            ml.SmartText.selectEditCreateTag(0, 3, (tag: ISmartTextConfigReplacement) =>
                                                that.insertSmartMacro(tag),
                                            );
                                        },
                                    },
                                    {
                                        type: "menuitem",
                                        text: "Create",
                                        onAction: function () {
                                            ml.SmartText.selectEditCreateTag(1, 3, (tag: ISmartTextConfigReplacement) =>
                                                that.insertSmartMacro(tag),
                                            );
                                        },
                                    },
                                    {
                                        type: "menuitem",
                                        text: "Edit",
                                        onAction: function () {
                                            ml.SmartText.selectEditCreateTag(2, 3, (tag: ISmartTextConfigReplacement) =>
                                                that.insertSmartMacro(tag),
                                            );
                                        },
                                    },
                                ];
                            },
                        },
                    ];
                },
            });

            let pluginMenuItems = plugins.getTinyMenus(editor);
            for (let pmi of pluginMenuItems) {
                editor.ui.registry.addNestedMenuItem(pmi.id, pmi.menuItem);
            }

            editor.ui.registry.addNestedMenuItem("References", {
                text: "Insert References To",
                getSubmenuItems: function () {
                    return [
                        {
                            type: "menuitem",
                            text: "Item(s) in this project",
                            onAction: function () {
                                that.insertReference();
                            },
                        },
                        {
                            type: "menuitem",
                            text: "Item(s) in another project",
                            onAction: function () {
                                that.insertCrossReference();
                            },
                        },
                    ];
                },
            });

            editor.ui.registry.addMenuItem("AddDrawIO", {
                text: "DrawIO Diagram...",
                icon: "image",
                onAction: function () {
                    that.addDrawIO();
                },
            });

            if (this.settings.parameter.printMode) {
                PrintProjectUIMods.addTinyMenus(that.editor, that.settings.valueChanged, that.settings.type);
            }
        }

        if (this.settings.canEdit) {
            editor.on("Change", () => that.onChange("undo"));
            editor.on("Keypress", () => that.onChange("keypress"));
            editor.on("mouseUp", (event: JQueryMouseEventObject) => that.onMouseUp(event));
            editor.on("PastePreProcess", (event: tinymce.Events.ContentEvent) => that.PastePreProcess(event));
            editor.on("PastePostProcess", () => that.PastePostProcess());
            editor.on("SetContent", () => that.AfterSetContent());
            editor.on("keyup", (event: JQueryKeyEventObject) => that.OnKeyUp(event));
            editor.on("keydown", (event: JQueryKeyEventObject) => that.OnKeyDown(event));
            editor.on("focus", (event: JQueryEventObject) => that.onFocus(event));
        }
        editor.on("ResizeEditor", () => that.onResizeEditor($(that.editor.getContainer()).css("height")));

        editor.on("init", () => that.onInit());

        editor.shortcuts.add("ctrl+s", "save", function () {
            KeyboardShortcuts.doSave();
        });

        // add hint for browser context menu
        editor.ui.registry.addMenuItem("useBrowserSpellcheck", {
            text: "Use `Ctrl+Right click` to access spellchecker",
            onAction: function () {
                editor.notificationManager.open({
                    text: "To access the spellchecker, hold the Control (Ctrl) key and right-click on the misspelt word.",
                    type: "info",
                    timeout: 5000,
                    closeButton: true,
                });
            },
        });
        editor.ui.registry.addContextMenu("useBrowserSpellcheck", {
            update: function (node) {
                return "useBrowserSpellcheck";
            },
        });

        if (this.cachedContent !== null) {
            this.setValue(this.cachedContent);
            this.cachedContent = null;
        }
    }

    private images_upload_handler(blobInfo: any, progress) {
        let that = this;
        let res = $.Deferred();

        new FileTools()
            .UploadFileAsync(blobInfo.blob())
            .done(function (uploaded) {
                let file = uploaded[0];
                let src = globalMatrix.matrixRestUrl + "/" + matrixSession.getProject() + "/file/" + file.fileId;
                res.resolve(src);
                window.setTimeout(function () {
                    that.markBadImages();
                    that.resizeNewImage(src);
                }, 100); // let it paint...
            })
            .fail(function (xhr) {
                res.reject("HTTP Error: " + xhr.status);
            });
        return res;
    }

    // after it's loaded
    private async onInit() {
        let that = this;

        this.isInEditMode = true;

        if (
            !this.disableDelayedShow &&
            (!this.settings.parameter.visibleOption || this.settings.parameter.visibleOption == this.settings.type)
        ) {
            this._root.show();
        }

        this.setupDragAndDrop();
        this.setMinHeight();
        this.markBadImages();

        this.dataOriginal = await this.getValueAsync();
        this.dataChanged = this.dataOriginal;

        $(".tox-statusbar", this._root)
            .prepend('<div class="tinyA4Marker tinyA4P">')
            .prepend('<div class="tinyA4Marker tinyA4L">');

        that.duringInit = false;
    }

    // called if content changes
    private onChange(reason: string) {
        let that = this;
        window.setTimeout(function () {
            that.onPostChange(reason);
        }, 1);
    }

    private async onPostChange(reason: string) {
        let that = this;
        if (
            this.duringInit ||
            (!this.wasDifferentBefore &&
                this.dataOriginal == (await this.getValueAsync()) &&
                JSON.stringify(this.formDataChanged) == JSON.stringify(this.formDataOriginal))
        ) {
            // ignore this to avoid bad events
            return;
        }
        clearTimeout(that.lastValueChanged);
        that.lastValueChanged = window.setTimeout(function () {
            if (that.settings.valueChanged) {
                that.settings.valueChanged.apply(null);
            }
        }, 333);
    }
    // called on first click or while tabbing into (readonly)
    private onFocus(event: JQueryEventObject) {
        // MATRIX-3788 focus should not activate the editor - instead require a click
        // this.enableEditor();
    }

    // fix  headings:
    // in normal items use <p class="h1"
    // in DOCs start with <h2
    private PastePreProcess(event: tinymce.Events.ContentEvent) {
        let that = this;

        let content = $("<div>").html(event.content);

        if (this.settings.parameter.docMode) {
            this.replaceWith(content, "h6", "<h7>");
            this.replaceWith(content, "h5", "<h6>");
            this.replaceWith(content, "h4", "<h5>");
            this.replaceWith(content, "h3", "<h4>");
            this.replaceWith(content, "h2", "<h3>");
            this.replaceWith(content, "h1", "<h2>");
        } else {
            this.replaceWith(content, "h6", "<p class='h6'>");
            this.replaceWith(content, "h5", "<p class='h5'>");
            this.replaceWith(content, "h4", "<p class='h4'>");
            this.replaceWith(content, "h3", "<p class='h3'>");
            this.replaceWith(content, "h2", "<p class='h2'>");
            this.replaceWith(content, "h1", "<p class='h1'>");
        }
        event.content = content.html();
    }

    private replaceWith(where: JQuery, what: string, withx: string) {
        $(what, where).each(function (idx, node) {
            $(node).replaceWith($(withx).html($(node).html()));
        });
    }

    // replace images with files
    private PastePostProcess() {
        let that = this;

        window.setTimeout(function () {
            that.processPastedImages();
        }, 1);
    }

    // get text from editor, clean it
    private getPurifiedContent() {
        tinymce.get(this.selectorId).save({ set_dirty: false });
        $("img", this.editorBox).removeClass("tinyErrorImage");

        // if this is legacy editor make sure the upgrade info still exists
        if (matrixSession.getUISettings().tinyUpgradeOption) {
            if (!this.editorBox.html()) {
                this.editorBox.html("<p>");
            }

            $(".2.2", this.editorBox).removeClass("2.2"); // avoid duplication of 2.2 tag
            this.editorBox.children().first().addClass("2.2"); // add it to first element
            this.editorBox.first().addClass("2.2"); // add it to first element
        }

        let contentCopy = $("<div>" + this.editorBox.html() + "</div>");

        // unwrap = get rid of search highlights
        $($(".macro", contentCopy)).contents().unwrap();
        $($(".highlight", contentCopy)).contents().unwrap();

        // get rid of nasty hacks
        if (this.tinyConf.dompurify) {
            return DOMPurify.sanitize(contentCopy.html()) + "";
        } else {
            return contentCopy.html();
        }
    }

    private OnKeyUp(event: JQueryKeyEventObject) {
        if (event && (event.key == "Backspace" || event.key == "Delete")) {
            this.onChange("delete key");
        }
        // MATRIX-3803 tabbing in editor should activate the editor
        this.enableEditor();
    }
    private OnKeyDown(event: JQueryKeyEventObject) {
        // MATRIX-3803 tabbing in editor should activate the editor
        this.enableEditor();

        if (event.keyCode == 9 && $(tinymce.activeEditor.selection.getNode()).closest("table").length == 0) {
            // tab pressed but NOT inside table
            if (event.shiftKey) {
                this.editor.execCommand("Outdent");
            } else {
                this.editor.execCommand("Indent");
            }

            event.preventDefault();
            return false;
        }

        return true;
    }

    private AfterSetContent() {
        let that = this;

        if (this.lastUploadedFile) {
            // the following checks if it is an image...
            this.resizeNewImage(this.lastUploadedFile);
            this.lastUploadedFile = "";
        }
        if (this.duringInit) {
            this.dataChanged = this.dataOriginal = this.getPurifiedContent();
        } else {
            this.onChange("set content");
        }
        $(".drawio", $(this.editor.getBody()))
            .prop("onclick", null)
            .off("click")
            .click(function (event: JQueryMouseEventObject) {
                that.openDrawIODrawing($(event.delegateTarget));
            });
        if (globalMatrix.ItemConfig != undefined) {
        }
        let config = globalMatrix.ItemConfig ? globalMatrix.ItemConfig.getFieldConfig(that.settings.fieldId) : null;

        if (config && !config.unsafeHtml && that.isInEditMode) {
            clearTimeout(that.purifyServer);
            that.purifyServer = window.setTimeout(async function () {
                let cleaner = new HTMLCleaner(await that.getValueAsync(), false);
                let problems = cleaner.checkServerCleaning();

                if (problems.length) {
                    let clean = cleaner.getClean(HTMLCleaner.CleanLevel.Server);
                    let errors = `<div style="max-height: 100px;overflow: auto;">${problems
                        .map((p) => `<p>${p}</p>`)
                        .join("")}</div>`;
                    ml.UI.showConfirm(
                        -1,
                        {
                            title: "<h2>invalid html detected</h2>" + errors,
                            ok: "auto clean",
                            nok: "manual cleaning",
                        },
                        () => {
                            // auto clean
                            that.setContent(clean);
                        },
                        () => {
                            tinymce.activeEditor.execCommand("mcecodeeditor");
                        },
                    );
                }
            }, 100);
        }
    }

    // called when user clicks in editor
    private onMouseUp(event: JQueryMouseEventObject) {
        if (this.isInEditMode && event.which != 1) {
            // otherwise hyperlinks are a pain ->every (right) click will open a new tab
            return;
        }

        if (event.target && (<any>event.target).href) {
            // MATRIX-3788 handle hyperlinks: go there and don't enable editor
            window.open((<any>event.target).href, "_blank");

            if (event.preventDefault) event.preventDefault();

            if (event.stopImmediatePropagation) event.stopImmediatePropagation();

            return false;
        }

        return true;
    }

    // called if user changes height of editor
    private onResizeEditor(heightPx: string) {
        let height = heightPx.replace("px", "");

        if (globalMatrix.globalShiftDown) {
            globalMatrix.projectStorage.setItem("eh" + this.settings.fieldId, height); // set default for field
            globalMatrix.projectStorage.setItem("eh" + this.settings.fieldId + "_" + this.settings.id, ""); // reset field
        } else {
            globalMatrix.projectStorage.setItem("eh" + this.settings.fieldId + "_" + this.settings.id, height); // set field
        }
    }
    // set height for drag and drop
    // relying on the JS because all those elements are within the iframe
    private setMinHeight() {
        const body = $(this.editor.getBody());
        const iframeHtml = body.closest("html");

        body.css("min-height", "100%");
        body.css("margin", "0");
        body.css("padding", "8px");
        body.css("box-sizing", "border-box");

        iframeHtml.css("height", "100%");
    }

    // *************************************
    // main function
    // *************************************

    // close the editor, resolve smart links and macros and hide toolbar
    public async closeEditor() {
        const pencilEdit = $(".textEditToggleEdit", this._root);
        const pencilPreview = $(".textEditTogglePreview", this._root);

        $(".editor-root", this._root).hide();
        // show the latest code
        this.updateShadowRoot();
        $(".shadow-root", this._root).show();
        pencilEdit.show();
        pencilPreview.hide();

        $(".tox-tinymce", this._root).height($(".tox-tinymce", this._root).height() - RichText2.toolbarHeight);

        // remember the current value
        await this.getValueAsync();
        this.isInEditMode = false;

        $(".tox-editor-header", this._root).addClass("tox-menubar-hide");
        $(".tox-menubar", this._root).hide();
        $(".tox-toolbar-overlord", this._root).hide();
        $(".tox-toolbar", this._root).hide();

        // load content with macros replaced
        $(this.editor.getBody()).html(ml.SmartText.replaceTextFragments(this.dataChanged, true));
        if (ml.Search.getFilter()) {
            $(this.editor.getBody()).unhighlight().highlight(ml.Search.getFilter());
        }
        ml.SmartText.showTooltips($(this.editor.getBody()), true);
        $(this.editor.getBody()).highlightReferences();
        this.markBadImages();
        // remove drawio editing
        $(".drawio", $(this.editor.getBody())).prop("onclick", null).off("click");
    }

    // back to editor
    private enableEditor() {
        let that = this;

        if (this.isInEditMode) {
            return;
        }
        this.isInEditMode = true;
        $(".tox-tinymce", this._root).height($(".tox-tinymce", this._root).height() + RichText2.toolbarHeight);

        // remember click/cursor position
        let range = <Range>this.editor.selection.getRng();
        let node = range.startContainer;
        let offset = range.startOffset;
        let smartTextMacro = "";

        let selector: string[];
        if (node.nodeName.toUpperCase() !== "BODY") {
            // check if it's in a smart replacement thingy, if so start with parent
            let macro = $(node).closest(".macro");
            if (macro.length == 1) {
                let smartReplace = macro.find(".smart-replace");
                if (smartReplace.length) {
                    // this is a macro of form _xxx_,
                    smartTextMacro = "_" + smartReplace.data("what") + "_";
                } else {
                    // this is a smart link
                    let smartLink = macro.find(".highLink");
                    smartTextMacro = smartLink.text().split(" ")[0];
                }
                // to do find actual number of macro in case same macro was used several times in one phrase
                offset = 0;
                node = macro[0].parentNode;
            } else if (node.nodeName == "#text") {
                // check if there's any other text nodes or macros before... if so go to parent and fix the offset
                let kidsOfParent = node.parentNode.childNodes;
                let kid = 0;
                while (kidsOfParent[kid] != node) {
                    if (kidsOfParent[kid].nodeName == "#text") {
                        offset += kidsOfParent[kid].textContent.length;
                    } else if ($(kidsOfParent[kid]).data("macro")) {
                        offset += $(kidsOfParent[kid]).data("macro").length;
                    }
                    kid++;
                }
            }
            selector = this.getSelector($(node));

            if (smartTextMacro) {
                selector.push("#text");
            }
        }

        $(".tox-editor-header", this._root).addClass("tox-menubar-hide");
        $(".tox-menubar", this._root).hide();
        $(".tox-toolbar-overlord", this._root).hide();
        $(".tox-toolbar", this._root).hide();

        // show toolbar
        $(".tox-editor-header", this._root).removeClass("tox-menubar-hide");
        $(".tox-menubar", this._root).show();
        $(".tox-toolbar-overlord", this._root).show();
        $(".tox-toolbar", this._root).show();

        if(this.settings.parameter.printMode) {
            $(this.dataChanged).data ("print", "true").html();
        }
        // load original content
        this.setContent(this.dataChanged);

        // restore click position
        this.toSelector($(this.editor.getBody()), selector, offset, smartTextMacro);

        if (ml.Search.getFilter()) {
            $(this.editor.getBody()).unhighlight().highlight(ml.Search.getFilter());
        }

        this.markBadImages();
        $(".drawio", $(this.editor.getBody()))
            .prop("onclick", null)
            .off("click")
            .click(function (event: JQueryMouseEventObject) {
                that.openDrawIODrawing($(event.delegateTarget));
            });
    }

    private setContent(html: string) {
        this.editor.focus();
        this.editor.setContent(html);
        this.editor.selection.setCursorLocation();
        this.editor.nodeChanged();
    }

    // navigate from node (the body) to a target node, there set cursor to the correct offset
    private toSelector(node: JQuery, path: string[], offset: number, smartTextMacro: string) {
        if (!path) {
            return;
        }

        let idx = 0;
        while (idx < path.length) {
            // if we reach a text node: it's the end
            if (path[idx] == "#text") {
                this.toOffset(node, smartTextMacro, offset);
                return;
            }
            // we try to find a specific node
            let nextNode = $(path[idx], node);
            if (!nextNode.length) {
                // something is wrong, let's stay here
                this.editor.selection.setCursorLocation(node[0], 0);
                return;
            }
            // next iteration
            node = nextNode;
            idx++;
        }
    }

    // set cursor in the node
    private toOffset(node: JQuery, smartTextMacro: string, offset: number) {
        let targetNode: HTMLElement | Node = node[0];
        if (node[0].childNodes && node[0].childNodes.length == 1) {
            targetNode = node[0].childNodes[0];
        }

        if (targetNode.nodeName == "#text") {
            let text = node[0].textContent;

            let position = 0;
            if (smartTextMacro) {
                // find position of the macro
                position = text.indexOf(smartTextMacro);
                position = position > 0 ? position : 0;
            } else {
                position = offset >= text.length ? text.length - 1 : offset;
            }

            this.editor.selection.setCursorLocation(targetNode, position);

            // node[0].scrollIntoView(); // this scrolls also stuff outside of iframe
            let scrollPos = node[0].offsetTop;
            if (scrollPos > 12 && scrollPos + 12 > this.editor.getBody().ownerDocument.documentElement.clientHeight) {
                // the node is not (fully ~ +12) visible we scroll
                this.editor.getBody().ownerDocument.documentElement.scrollTop = scrollPos - 12; // we let a bit of space above
            }

            return;
        }
        // there's something else what to do?
        this.editor.selection.setCursorLocation(node[0], 0);
    }

    // get the path to the cursor
    private getSelector(node: JQuery) {
        let path = [];

        if (node[0].nodeName == "#text") {
            path.push("#text");
            node = node.parent();
        }

        while (node.length) {
            let realNode = node[0];
            let name = realNode.localName;

            if (!name) break;

            name = name.toLowerCase();
            if (name == "body") break;

            let parent = node.parent();

            let sameTagSiblings = parent.children(name);
            if (sameTagSiblings.length > 1) {
                let allSiblings = parent.children();
                let index = allSiblings.index(realNode) + 1;
                name += ":nth-child(" + index + ")";
            }

            path.splice(0, 0, name);
            node = parent;
        }

        return path;
    }

    /*******************************************************
     * Drag and drop support
     *******************************************************/

    private setupDragAndDrop() {
        let that = this;

        this.editor.getBody().addEventListener("dragstart", (event: DragEvent) => that.onDragStart(event));
        this.editor.getBody().addEventListener("dragenter", (event: DragEvent) => that.onDragEnter(event));
        this.editor.getBody().addEventListener("dragover", (event: DragEvent) => that.onDragOver(event));
        this.editor.getBody().addEventListener("drop", (event: DragEvent) => that.onDrop(event));
        this.editor.getBody().addEventListener("dragleave", (event: DragEvent) => that.onDragLeave(event));
    }

    private onDragStart(event: DragEvent): void {
        event.dataTransfer.setData("matrix/sourceEditor", this.selectorId);
        event.dataTransfer.effectAllowed = "move";
    }

    private dragEnterCounter = 0;
    private onDragEnter(event: DragEvent): void {
        this.dragEnterCounter++;

        if (this.dragEnterCounter == 1) {
            if (this.settings.canEdit) {
                $(event.target).css("background-color", "#f3f3f3");
                event.dataTransfer.dropEffect = "copy";
                this.enableEditor();
            }
        }
    }
    private onDragLeave(event: DragEvent): void {
        this.dragEnterCounter--;

        if (!this.dragEnterCounter) {
            if (this.settings.canEdit) {
                $(event.target).css("background-color", "");
            }
        }
    }
    private onDragOver(event: DragEvent): void {
        if (this.settings.canEdit) {
            event.dataTransfer.dropEffect = "copy";
        }
    }

    private onDrop(event: DragEvent): boolean {
        this.dragEnterCounter = 0;

        // Check if this is an internal drag
        const sourceID = event.dataTransfer.getData("matrix/sourceEditor");
        if (sourceID === this.selectorId) {
            // This is an internal drag
            return;
        }

        if (event.preventDefault) event.preventDefault();
        if (event.stopPropagation) event.stopPropagation();

        if (!this.settings.canEdit) {
            return false;
        }

        $(event.target).css("background-color", "");

        // place the cursor: simulate a click in the browser
        let positionOfIframe = $("#" + this.selectorId + "_ifr").offset();

        let ev = new MouseEvent("click", {
            clientX: event.clientX + positionOfIframe.left,
            clientY: event.clientY + positionOfIframe.top,
        });

        // make sure editor is editable
        this.enableEditor();

        // collect drop data
        let types: string[] = [];
        $.each(event.dataTransfer.types, function (idx, dtype) {
            // special treatment for firefox (in normal browsers the array is a real array :-)
            types.push(dtype);
        });

        var html = types.indexOf("text/html");
        var text = types.indexOf("text/plain");
        var uri = types.indexOf("text/uri-list");
        var files = types.indexOf("Files");
        var image = -1;

        $.each(types, function (idx, type): any {
            if (type.indexOf("image/") === 0) {
                image = idx;
                return false;
            }
        });
        if (image !== -1) {
        } else if (uri !== -1 && Tasks.externalItemFromUrl(event.dataTransfer.getData("text/uri-list"))) {
            this.ddCreateLink(
                event.dataTransfer.getData("text/html"),
                event.dataTransfer.getData("text/uri-list"),
                event,
            );
        } else if (files !== -1) {
            this.ddUploadFiles(event.dataTransfer.files, event);
        } else if (html !== -1) {
            this.ddUploadHTML($(event.dataTransfer.getData("text/html")), event);
            this.processPastedImages();
        } else if (text !== -1) {
            this.editor.insertContent("<span>" + event.dataTransfer.getData("text/plain") + "</span>");
        }

        return false;
    }

    // someone dropped an URL which is an recognized as ticket
    private ddCreateLink(display: string, ticketUrl: string, event: Event) {
        display = display ? display : ticketUrl;
        // add ticket link to UI
        let ud = $("<div>");
        ud.append(display);
        let text = $("<div>").html(ud.text());
        this.editor.insertContent(text[0].outerHTML);

        // create issue link
        Tasks.createTaskFromUrl(this.settings.item.id, ticketUrl);
    }
    // someone dragged one or more generic files
    private ddUploadFiles(files: FileList, event: Event): void {
        let that = this;

        new FileTools()
            .UploadFilesAsync(files)
            .done(function (uploads: IUploadedFileInfo[]) {
                let ud = $("<div>");
                let ud_used = false;

                // normal drag and drop into a rich text field
                $.each(uploads, function (fi: number, u: IUploadedFileInfo) {
                    if (/\.(gif|jpg|jpeg|tiff|png)$/i.test(u.fileName.toLowerCase())) {
                        // show and scale the image
                        that.insertImage(
                            globalMatrix.matrixRestUrl + "/" + matrixSession.getProject() + "/file/" + u.fileId,
                            u.fileName,
                        );
                    } else if (uploads.length === 1 && /\.(docx)$/i.test(u.fileName.toLowerCase())) {
                        ml.UI.showConfirm(
                            9,
                            { title: "What to do with the word document?", ok: "Attach file", nok: "Convert to text" },
                            function () {
                                let link = $("<a class='highLink'>")
                                    .attr(
                                        "href",
                                        globalMatrix.matrixRestUrl +
                                            "/" +
                                            matrixSession.getProject() +
                                            "/file/" +
                                            u.fileId,
                                    )
                                    .attr("target", "_blank")
                                    .html(u.fileName);
                                ud.append(link);
                                ud.append($("<span>").html(fi === uploads.length - 1 ? " " : ", "));
                                that.editor.insertContent(ud[0].outerHTML);
                            },
                            function () {
                                ml.UI.confirmSpinningWait("converting document ...");

                                app.convertDocAsync(Number(u.fileId.split("?")[0]))
                                    .done(function (html: any) {
                                        ud.html(
                                            new HTMLCleaner(html.html, false).getClean(
                                                that.settings.parameter.docMode
                                                    ? HTMLCleaner.CleanLevel.StrictDoc
                                                    : HTMLCleaner.CleanLevel.Strict,
                                            ),
                                        );

                                        that.editor.insertContent(ud[0].innerHTML);
                                    })
                                    .always(function () {
                                        ml.UI.closeConfirmSpinningWait();
                                    });
                            },
                        );
                    } else {
                        ud_used = true;
                        var link = $("<a class='highLink'>")
                            .attr(
                                "href",
                                globalMatrix.matrixRestUrl + "/" + matrixSession.getProject() + "/file/" + u.fileId,
                            )
                            .attr("target", "_blank")
                            .html(u.fileName);
                        ud.append(link);
                        ud.append($("<span>").html(fi === uploads.length - 1 ? " " : ", "));
                    }
                });
                if (ud_used) {
                    that.editor.insertContent(ud[0].innerHTML);
                }
                if (that.form) {
                    var fields = that.form.getControls("fileManager");
                    $.each(fields, function (idx, fileManager) {
                        if ((<FileManagerImpl>fileManager.getController()).populateFromRichtext()) {
                            (<FileManagerImpl>fileManager.getController()).addLinks(uploads);
                        }
                    });
                }

                that.markBadImages();
            })
            .fail(function (uploads) {});
    }

    private insertImage(sUrl: string, filename: string) {
        let that = this;

        // create the image object and load it from server
        this.createImage(sUrl, filename)
            .then(function (image: JQuery) {
                // scale image
                let bestDimensions = that.getInitialImageSize(image);
                image.css({ display: "", width: bestDimensions.width, height: bestDimensions.height });
                // write html
                that.editor.insertContent(image[0].outerHTML);
                image.remove();
            })
            .fail(function () {});
    }
    private resizeNewImage(src: string) {
        let that = this;

        let imgs = $("img[src='" + src + "']", this.editor.getBody());
        $.each(imgs, function (idx, image) {
            let bestDimensions = that.getInitialImageSize($(image));
            $(image).css({ width: bestDimensions.width, height: bestDimensions.height });
        });
    }
    // load and display an image, return promise on after it's displayed
    private createImage(sUrl: string, filename: string) {
        return $.Deferred<JQuery>(function (deferred) {
            let img = $("<img>");
            img.one("load", function () {
                deferred.resolve(img);
            })
                .one("error abort", function () {
                    deferred.reject(img.detach());
                })
                .css({
                    display: "none",
                })
                .appendTo(document.body)
                .attr("src", sUrl)
                .attr("data-filename", filename);
        }).promise();
    }

    // get image size that fit into a4
    private getImageSize(image: JQuery, perc: number) {
        var height = image.height();
        var width = image.width();
        var heightMax = Math.min(800, height); // a bit less than 22cm
        var widthMax = Math.min(604, width); // 16cm
        var heightRatio = heightMax / height;
        var widthRatio = widthMax / width;
        var minRatio = Math.min(heightRatio, widthRatio);

        height = (height * minRatio * perc) / 100;
        width = (width * minRatio * perc) / 100;
        return { width: width, height: height };
    }

    private getInitialImageSize(image: JQuery) {
        return this.getImageSize(image, 100);
    }

    private ddUploadHTML(html: JQuery, event: Event): void {
        let that = this;
        $.each(html, function (idx, piece) {
            that.editor.insertContent(piece.outerHTML);
        });
    }

    /*******************************************
     * handle images pointing to alien servers
     *******************************************/

    private markBadImages(ignoreBlobs?: boolean) {
        const imagesInEditor = $("img", this.editor.getBody());

        const shadowRootContainer = $(".shadow-root", this._root);
        const shadowRoot = shadowRootContainer[0].shadowRoot;
        const imagesInPreview = $(shadowRoot).find("img");

        const images = imagesInEditor.add(imagesInPreview);

        images.removeClass("tinyErrorImage");
        this.removeBadImagesError();

        $.each(images, (iidx: number, img: HTMLImageElement) => {
            if (!ignoreBlobs || img.src.indexOf("blob") != 0) {
                this.checkBadImage(img);
            }
        });
    }

    private checkBadImage(img: HTMLImageElement) {
        // good image, nothing to do
        if (img.src.startsWith(globalMatrix.matrixBaseUrl) || img.src.startsWith("data:image")) {
            return;
        }

        if (!this.failedImages.includes(img.src)) {
            this.failedImages.push(img.src);
        }

        $(img).addClass("tinyErrorImage");
        this.renderBadImagesError();
    }

    private renderBadImagesError() {
        // error is already rendered
        if ($(".tinyError", this._root).length > 0) {
            return;
        }

        const msg = $("<div class='tinyError'>There are some bad images in the text</div>");
        $(".textEditContainer", this._root).append(msg);
        msg.append(
            "(<a style='color:white;text-decoration:underline' href='https://urlshort.matrixreq.com/d24/externalimg' target='_blank'> help!</a>)",
        );
        $("<span> (<span style='color:white;text-decoration:underline;cursor:pointer' >which?</span>)</span>")
            .appendTo(msg)
            .click(() => this.showBadImages());
    }

    private removeBadImagesError() {
        $(".tinyError", this._root).remove();
    }

    // make sure (new) images are hosted by matrix req
    private processPastedImages(): JQueryDeferred<{}> {
        let res = $.Deferred();
        let that = this;

        let needsImport: string[] = [];

        let imgs = $("img", $(this.editor.getBody()));
        $.each(imgs, function (iidx: number, img: HTMLImageElement) {
            // check image location
            if (
                img.src.indexOf(globalMatrix.matrixBaseUrl) !== 0 &&
                img.src.indexOf("data:image") !== 0 &&
                that.failedImages.indexOf(img.src) === -1
            ) {
                needsImport.push(img.src);
            }
        });

        if (needsImport.length > 0) {
            return this.uploadPastedImagesRec(needsImport, 0).always(function () {
                // apply changes
                that.editor.save();
                let imgs = $("img", that.editorBox);

                $.each(imgs, function (iidx: number, img: HTMLImageElement) {
                    if (that.imgSrcMap[img.src]) {
                        // scale image
                        let bestDimensions = that.getInitialImageSize($(img));
                        $(img).css({ display: "", width: bestDimensions.width, height: bestDimensions.height });
                        // fix path
                        img.src = that.imgSrcMap[img.src];
                    }
                });
                that.editor.load();
                that.markBadImages(true);

                res.resolve();
            });
        } else {
            res.resolve();
        }
        return res;
    }

    // ask server to upload external images (client cannot do ...)
    private uploadPastedImagesRec(needsImport: string[], next: number) {
        let that = this;
        let res = $.Deferred();

        if (next === needsImport.length) {
            // we are done with recursion
            ml.UI.BlockingProgress.SetProgress(0, 100);

            res.resolve();
            return res;
        }

        let src = needsImport[next];

        next = next + 1;

        if (this.failedImages.indexOf(src) !== -1) {
            // we can skip this one
            this.uploadPastedImagesRec(needsImport, next).always(function () {
                res.resolve();
            });
            return res;
        }

        // we need to try to import
        var tasks: IBlockingProgressUITask[] = [];
        tasks.push({ name: src });
        ml.UI.BlockingProgress.Init(tasks);

        if (src.indexOf("blob:http") == 0) {
            that.uploadPastedImagesRec(needsImport, next).always(function () {
                res.resolve();
            });
        } else {
            app.fetchFileAsync(src, function (p: IFileUploadProgress) {
                var done = p.position || p.loaded;
                var total = p.totalSize || p.total;

                ml.UI.BlockingProgress.SetProgress(0, (99 * done) / total);
            })
                .done(function (result) {
                    that.imgSrcMap[src] = `${globalMatrix.matrixBaseUrl}/rest/1/${matrixSession.getProject()}/file/${
                        result.fileId
                    }?key=${result.key}`;
                })
                .fail(function (error) {
                    ml.UI.BlockingProgress.SetProgressError(0, `Our servers cannot fetch the image from ${src}`);
                    ml.UI.showError(
                        "Our servers cannot fetch the image",
                        `Maybe there is some authentication required?`,
                    );
                })
                .always(function () {
                    that.uploadPastedImagesRec(needsImport, next).always(function () {
                        res.resolve();
                    });
                });
        }
        return res;
    }

    private showBadImages() {
        let html =
            "The following images cannot be retrieved from our server. So you will not see them in documents. You can try to download and import them from here:<br><br><ul style='text-align: left;'>";
        $.each(this.failedImages, function (idx, img) {
            let parts = img.split("/");
            let name = img.length < 70 ? img : img.substring(0, 40) + "....." + img.substr(img.length - 25);
            html += `<li><a download="${parts[parts.length - 1]}" href="${img}" title="${
                parts[parts.length - 1]
            }">${name}<a></li>`;
        });
        html += "</ul>";
        ml.UI.showAck(-2, html, "Try downloading these images");
    }

    /******************************************
     * Smart text tools
     *******************************************/

    // insert a simple reference
    private insertReference() {
        let that = this;

        let linkStyle = localStorage.getItem("linkStyle");
        if (!linkStyle) {
            linkStyle = "noTitle";
        }

        let selectOptions = jQuery(`<div class='selectReferenceOption'>
        <form action="">
            <input type="radio" name="title" value="noTitle" ${
                linkStyle == "noTitle" ? 'checked="checked"' : ""
            }> No title<br>
            <input type="radio" name="title" value="title" ${
                linkStyle == "title" ? 'checked="checked"' : ""
            }> Title behind link<br>
            <input type="radio" name="title" value="linktitle" ${
                linkStyle == "linktitle" ? 'checked="checked"' : ""
            }> Title as part of link
        </form>
        </div>`);

        // let user select the items
        let st = new ItemSelectionTools();
        st.showDialog({
            selectMode: SelectMode.independent,
            linkTypes: globalMatrix.ItemConfig.getCategories(true).map(function (cat) {
                return { type: cat };
            }),
            selectionChange: function (newSelection: IReference[]) {
                let linkStyle = jQuery("input[name=title]:checked", selectOptions).val();
                localStorage.setItem("linkStyle", linkStyle);
                if (newSelection.length > 0) {
                    let refTexts: string[] = [];
                    for (var idx = 0; idx < newSelection.length; idx++) {
                        refTexts.push(
                            newSelection[idx].to +
                                (linkStyle == "title" ? "!" : "") +
                                (linkStyle == "linktitle" ? "+" : ""),
                        );
                    }

                    that.editor.insertContent(refTexts.join(", "));
                }
            },
            getSelectedItems: async () => {
                return [];
            },
            selectOptions: selectOptions,
            height: 600,
        });
    }

    // insert a cross project reference
    private insertCrossReference() {
        let that = this;

        let st = new ItemSelectionTools();
        let projectShortLabel: string;

        let selectOptions = jQuery(`<div class='selectCrossReferenceOption'>
            <label for="refPrefix">Link display name (optional) - do not use spaces</label><input autocomplete="off" type="text" class="form-control" id="refPrefix" pattern="[a-zA-Z0-9-_]+" />
            </div>`);

        // let user select the items
        st.showCrossProjectDialog({
            selectMode: SelectMode.independent,
            linkTypes: [],
            selectionChange: function (newSelection: IReference[]) {
                let prefix = jQuery("#refPrefix", selectOptions).val();
                if (prefix) {
                    prefix = prefix.replace(/[^a-zA-Z0-9-_]/g, "_") + "|";
                }
                if (newSelection.length > 0) {
                    let refTexts: string[] = [];
                    for (var idx = 0; idx < newSelection.length; idx++) {
                        refTexts.push("#" + prefix + projectShortLabel + "/" + newSelection[idx].to + "#");
                    }

                    that.editor.insertContent(refTexts.join(", "));
                }
            },
            crossProjectInit: function (psl: string) {
                projectShortLabel = psl;
            },
            getSelectedItems: async () => {
                return [];
            },
            selectOptions: selectOptions,
            height: 600,
        });
    }

    // paste the content of the dashboard buffer
    private pasteBuffer() {
        let html = $(globalMatrix.serverStorage.getItem("copyBuffer"));
        this.editor.insertContent(html.html());
    }

    // insert a smart text macro
    private insertSmartMacro(tag: ISmartTextConfigReplacement) {
        if (tag) {
            this.editor.insertContent("_" + tag.what + "_");
        }
    }

    /****************************************** */
    /*  drawio functions */
    /****************************************** */

    private addDrawIO() {
        this.editor.insertContent(
            '<img class="drawio" style="max-width:100%;cursor:pointer;" src="' +
                globalMatrix.matrixBaseUrl +
                '/static/img/drawio.png" />',
        );
    }

    private openDrawIODrawing(image: JQuery) {
        let that = this;

        let overlay = $("<div class='drawio-overlay'>").appendTo("body");
        let loading = $("<div class='drawio-loading'>")
            .append(ml.UI.getSpinningWait("loading drawio..."))
            .appendTo(overlay);

        let iframe: JQuery;

        var close = function () {
            that.onClosedDrawIO();
            window.removeEventListener("message", receive);
            overlay.remove();
        };

        var receive = function (evt: any) {
            if (evt.data.length > 0) {
                var msg = JSON.parse(evt.data);

                if (msg.event == "init") {
                    let src = image.data("dataurl");
                    if (src) {
                        app.downloadInMemoryFromUrl(src).done(function (dataUrl) {
                            (<any>iframe[0]).contentWindow.postMessage(
                                JSON.stringify({ action: "load", xmlpng: dataUrl }),
                                "*",
                            );
                            that.onOpenedDrawIO();
                            loading.remove();
                        });
                    } else {
                        (<any>iframe[0]).contentWindow.postMessage(JSON.stringify({ action: "load", xmlpng: "" }), "*");
                        that.onOpenedDrawIO();
                        loading.remove();
                    }
                } else if (msg.event == "export") {
                    // msg.data is data URL -> convert to a file
                    let arr = msg.data.split(",");
                    let bstr = atob(arr[1]);
                    let n = bstr.length;
                    let u8arr = new Uint8Array(n);
                    while (n--) {
                        u8arr[n] = bstr.charCodeAt(n);
                    }
                    let imgData = new File([u8arr], "temp.png", { type: "png" });
                    let orgData = new File([msg.data], "org.png", { type: "png" });
                    // upload the file
                    new FileTools().UploadFilesAsync([imgData, orgData]).done(function (uploads: IUploadedFileInfo[]) {
                        // point  the image src to the uploaded file
                        let imgPath =
                            globalMatrix.matrixRestUrl +
                            "/" +
                            matrixSession.getProject() +
                            "/file/" +
                            uploads[0].fileId;
                        let orgPath = "file/" + uploads[1].fileId;
                        image
                            .attr("src", imgPath)
                            .attr("data-mce-src", imgPath)
                            .attr("data-dataurl", orgPath)
                            .data("dataurl", orgPath); // note: we need this and the line before :-( above for persistence at save, this for the next click before save
                        // enables save
                        that.editor.undoManager.add();
                    });
                    close();
                } else if (msg.event == "save") {
                    let scale = matrixSession.getUISettings().drawIOScale
                        ? matrixSession.getUISettings().drawIOScale
                        : 3;
                    let dp = { scale: scale, action: "export", format: "xmlpng", xml: msg.xml, spin: "Updating page" };
                    (<any>iframe[0]).contentWindow.postMessage(JSON.stringify(dp), "*");
                } else if (msg.event == "exit") {
                    close();
                }
            }
        };
        window.addEventListener("message", receive);

        let baseUrl = matrixSession.getUISettings({}).drawioURL
            ? matrixSession.getUISettings().drawioURL
            : "www.draw.io";
        iframe = $(
            "<iframe src='https://" + baseUrl + "/?embed=1&ui=atlas&spin=1&proto=json' style='width:100%;height:100%'>",
        ).appendTo(overlay);
    }

    private onOpenedDrawIO() {
        this.editingDrawIO = true;
        if (this.settings.valueChanged) {
            this.settings.valueChanged();
        }
    }
    private onClosedDrawIO() {
        this.editingDrawIO = false;
        if (this.settings.valueChanged) {
            this.settings.valueChanged();
        }
    }

    afterRendering() {
        $(".textEditToggleEdit", this._root).on("click", () => {
            if (!this._root.find(".baseControl").is(":visible")) {
                $(".cbimg").click();
            }
        });
        $(".showHideAdmin", this._root).change(() => {
            if (!$(".showHideAdmin", this._root).is(":checked")) {
                this.closeEditor();
            }
        });
    }
}

class RichText2Plugin implements IPlugin {
    static fieldType = "richtext2";

    constructor() {}

    private _item: IItem;

    public isDefault = true;

    initItem(item: IItem, jui: JQuery) {
        this._item = item;
    }

    initServerSettings() {}

    initProject() {}

    getProjectPagesAsync(): Promise<IProjectPageParam[]> {
        return new Promise((resolve, reject) => {
            resolve([]);
        });
    }
    updateMenu(ul: JQuery) {}

    supportsControl(fieldType: string): boolean {
        return fieldType === RichText2Plugin.fieldType;
    }
    createControl(ctrl: JQuery, options: IBaseControlOptions) {
        ctrl.richText2(options);
    }
}

let RichText2Instance = new RichText2Plugin();

export function initialize() {
    plugins.register(RichText2Instance);

    // https://www.tiny.cloud/docs/integrations/bootstrap/
    $(document).on("focusin", function (e) {
        if ($(e.target).closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root").length) {
            e.stopImmediatePropagation();
        }
    });

    // configure DOMPurify to leave/add targets _blank if something is a link
    DOMPurify.addHook("afterSanitizeAttributes", function (node: any) {
        // set all elements owning target to target=_blank
        if ("rel" in node && node.rel == "noopener") {
            node.target = "_blank";
        }
    });
}
